<template>
  <a-config-provider :locale="cureentLocale">
    <router-view />
  </a-config-provider>
</template>

<script setup>
  import { computed } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import enUS from 'ant-design-vue/es/locale/en_US';
  import { onScreenSizeChange } from '@/tool/screenSizeChange';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';
  // import UserAPI from '@/api/v1/users';
  const store = useStore();
  const locale = computed(() => store.state.locale.language);
  const i18n = useI18n();
  i18n.value = locale.value;
  const cureentLocale = computed(() => (locale.value == 'en' ? enUS : zhCN));
  dayjs.locale('zh-cn');

  const { screenWidth } = onScreenSizeChange();
  console.log(screenWidth);
  // UserAPI.logout();
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
</style>
