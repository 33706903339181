const SET_WIDTH = 'SET_WIDTH';
const SET_HEIGHT = 'SET_HEIGHT';
const SET_SIZE = 'SET_SIZE';

const state = {
  width: 0,
  height: 0,
};

const mutations = {
  [SET_WIDTH](state, payload) {
    state.width = payload;
  },
  [SET_HEIGHT](state, payload) {
    state.height = payload;
  },
  [SET_SIZE](state, payload) {
    Object.assign(state, payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
