import fileService from '../file-service';
import v1 from '@/api/v1/v1';

export default {
  uploadFile(fileName, file, url, params) {
    let formData = new FormData();
    formData.append('name', fileName);
    for (let i in params) {
      formData.append(i, params[i]);
    }
    if (file) {
      formData.append('file', file);
    }
    return fileService.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
  },

  getUploadInfo(hash) {
    let params = {
      uuid: hash,
    };
    return v1.post('/files/upload-request', params);
  },
};
