import { ref } from 'vue';

export function footerConfig() {
  const footers = ref([
    '微信号：yuanbenio',
    '邮箱：hi@yuanben.org',
    '地址：西安市碑林区二环南路西段69号10楼',
  ]);
  return {
    footers,
  };
}
