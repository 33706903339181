import Role from '@/model/role.js';
import format from 'date-fns/format';
import { zhCN } from 'date-fns/locale';
const modulesFiles = require.context('./modules', true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  Object.keys(value.default).forEach((itm) => {
    modules[`${moduleName}.${itm}`] = value.default[itm];
  });
  return modules;
}, {});
const filters = {
  second2Date: (sec) => {
    if (sec === undefined) {
      return '';
    }
    if (sec > 9999999999) {
      return format(sec, 'yyyy-MM-dd HH:mm:ss');
    } else {
      return format(sec * 1000, 'yyyy-MM-dd HH:mm:ss');
    }
  },
  second2YearMonthDay: (sec) => {
    if (sec === undefined) {
      return '';
    }
    if (sec > 9999999999) {
      return format(sec, 'yyyy-MM-dd');
    } else {
      return format(sec * 1000, 'yyyy-MM-dd');
    }
  },
  second2YearMonth: (sec) => {
    if (sec === undefined) {
      return '';
    }
    if (sec > 9999999999) {
      return format(sec, 'yyyy-MM');
    } else {
      return format(sec * 1000, 'yyyy-MM');
    }
  },
  second2ChineseYearMonth: (sec) => {
    if (sec > 9999999999) {
      return format(sec, 'yyyy年MM月dd日 E 中国标准时间 a hh:mm:ss', { locale: zhCN });
    } else {
      return format(sec * 1000, 'yyyy年MM月dd日 E 中国标准时间 a hh:mm:ss', { locale: zhCN });
    }
  },
  privacyPhoneNum: (num) => {
    if (num === undefined) {
      return '';
    }
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return num.replace(reg, '$1****$2');
  },
  lastFourNumInPhone: (num) => {
    if (num === undefined) {
      return '';
    }
    return num.substring(7, 11);
  },
  noContentText(text) {
    return text ? text : '暂无';
  },
  capitalize: function (val) {
    return (+val || 0).toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
  },
  userRoleName: (value) => Role.UserRoleName(value),
  privacyLicense: (num) => {
    if (num === undefined) {
      return '';
    }
    let reg = /^(\d{5})\d{7,}(\d{3})$/;
    return num.replace(reg, '$1****$2');
  },
};
Object.assign(filters, modules);
export default filters;
