import { ref } from 'vue';

export function footerConfig() {
  const footers = ref([
    {
      title: '我们的产品',
      children: [
        {
          text: 'Miks',
          link: 'https://wemiks.com/',
        },
        {
          text: '原本版权保护',
          link: 'https://www.yuanben.io/',
        },
        {
          text: '原本区块链',
          link: 'https://www.yuanbenlian.com/',
        },
        {
          text: 'Delta隐私计算开源平台',
          link: 'https://deltampc.com/',
        },
      ],
    },
    {
      title: '关于数产问',
      children: [
        {
          text: '首页',
        },
        {
          text: '解决方案',
        },
        {
          text: '技术服务',
        },
        {
          text: '隐私协议',
          page: 'privacy',
        },
      ],
    },
    {
      title: '联系我们',
      children: [
        {
          text: '微信号：yuanbenio',
        },
        {
          text: '邮箱：hi@yuanben.org',
        },
        {
          text: '地址：浦东新区泥城镇 云汉路',
        },
      ],
    },
  ]);

  return {
    footers,
  };
}
