<template>
  <div class="create-container">
    <div class="create-prepare">
      <div v-if="formLicenseError" class="error-box license-error-box">
        <a-alert :message="formLicenseError" type="error" show-icon />
      </div>
      <div class="license-tip">
        <a-typography-text strong>{{ $t('营业执照:') }}</a-typography-text>
        <a-typography-text>{{ $t('请上传彩色原件或加盖企业公章的复印件。') }}</a-typography-text>
      </div>
      <div style="height: 20px"></div>
      <a-space :size="58">
        <div class="upload-license-box license-box">
          <a-upload
            :file-list="licenseFiles"
            :max-count="1"
            :show-upload-list="false"
            accept=".jpeg, .jpg, .png, .bmp, .gif"
            @change="licenseSelected"
          >
            <div v-if="licenseImg.path" class="id-license">
              <img class="license-image" :src="licenseImg.path" />
            </div>
            <a-space v-else-if="selectedLicense.loading" direction="vertical" align="center">
              <LoadingOutlined />
            </a-space>
            <a-space v-else direction="vertical" align="center">
              <plus-outlined style="font-size: 22px" />
              <a-typography-text>{{ uploadText }}</a-typography-text>
            </a-space>
          </a-upload>
        </div>
        <div class="license-box">
          <a-image
            style="width: 100%; height: 100%"
            class="license-sample"
            :preview="false"
            :src="require('../images/license.png')"
          ></a-image>
        </div>
      </a-space>
    </div>
    <div v-if="licenseResult" class="legal-person-info">
      <a-descriptions :label-style="{ width: '200px' }" bordered>
        <a-descriptions-item :label="i18nText('企业名称:')" :span="3">
          {{ licenseResult.name }}
        </a-descriptions-item>
        <a-descriptions-item :label="i18nText('营业执照号:')" :span="3">
          {{ $filters.privacyLicense(licenseResult.license) }}
        </a-descriptions-item>
        <a-descriptions-item :label="i18nText('法定代表人:')" :span="3">
          {{ licenseResult.legal_person }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
    <div v-if="licenseResult" class="divider-bottom">
      <a-divider></a-divider>
      <div class="create-radio-outer">
        <div class="create-radio">
          <a-radio-group v-model:value="companyAuthMean">
            <a-radio class="auth-radio" :value="1" :disabled="!isLegalPerson">
              {{ $t('企业法人线上快速审核通道') }}
            </a-radio>
            <a-radio class="auth-radio" :value="2" :disabled="isLegalPerson">
              {{ $t('其他方式审核') }}
            </a-radio>
          </a-radio-group>
        </div>
      </div>
      <div style="height: 20px"></div>
      <a-space v-if="companyAuthMean == 2" direction="vertical" size="large">
        <a-space direction="vertical" size="middle">
          <a-typography-text strong>{{ $t('步骤1') }}</a-typography-text>
          <a-typography-text>
            {{ $t('请打印企业申请表, 打印后填写相关信息并签字盖章, 然后拍照或彩色扫描后上传') }}
          </a-typography-text>
          <a-button @click="downloadApplyTable">{{ $t('下载企业认证申请表') }}</a-button>
        </a-space>
        <a-space direction="vertical" :size="30">
          <a-typography-text strong>{{ $t('步骤2') }}</a-typography-text>
          <div>
            <a-upload
              v-model:file-list="applyTableFiles"
              :max-count="1"
              list-type="picture"
              @remove="removeApplyTable"
              :preview-file="previewImage"
              @change="applyTableSelected"
            >
              <a-button :loading="selectedApply.loading">
                <UploadOutlined />
                {{ $t('上传相关申请表') }}
              </a-button>
            </a-upload>
          </div>
          <div>
            <a-upload
              v-model::file-list="codeLicenseFiles"
              :max-count="1"
              list-type="picture"
              @remove="removeCodeLicense"
              :preview-file="previewImage"
              @change="codeLicenseSelected"
            >
              <a-button :loading="selectedCodeLicense.loading">
                <UploadOutlined />
                {{ $t('上传组织代码证（可选）') }}
              </a-button>
              <div slot="tip" class="el-upload__tip"></div>
            </a-upload>
          </div>
          <div class="agent-step-upload"></div>
        </a-space>
      </a-space>
      <div class="commit-company">
        <a-button
          type="primary"
          size="large"
          :disabled="commitCompanyDisable"
          @click="createCompany"
        >
          {{ $t('认证') }}
        </a-button>
      </div>
    </div>
    <div v-else class="white-blank"></div>
  </div>
</template>

<script setup>
  import { computed, reactive, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { keyPairUtil } from '@/library/crypto/keypair';
  import UploadFileAPI from '@/api/v1/upload-file';
  import CompanyAPI from '@/api/v1/company';
  import ErrorMessage from '@/model/errorMessage';
  import config from '/config';
  import { PlusOutlined, UploadOutlined, LoadingOutlined } from '@ant-design/icons-vue';
  import { i18nText } from '@/lang';

  const loading = ref(false);

  const selectedLicense = reactive({ loading: false, path: '', name: '', image: null, hash: '' });
  const licenseResult = ref(null);
  const licenseImg = reactive({ path: null });

  const selectedApply = reactive({ loading: false, table: null, name: '', hash: null });
  const selectedCodeLicense = reactive({ loading: false, license: null, name: '', hash: null });
  const agentForm = reactive({
    applyTableName: null,
    hash: null,
    codeLicenseName: null,
    codeLicenseHash: null,
  });
  // let validateApplyTable = async (_rule, value) => {
  //   if (!value) {
  //     return Promise.reject('请选择企业请求表');
  //   } else {
  //     return Promise.resolve();
  //   }
  // };
  // let agentRules = {
  //   applyTableName: [{ required: true, trigger: 'all', validator: validateApplyTable }],
  // };

  const companyAuthMean = ref(1);
  const formLicenseError = ref(null);
  const uploadText = computed(() => {
    if (licenseImg.path) {
      return '';
    }
    return '点击上传';
  });
  const store = useStore();
  const isLegalPerson = computed(() => licenseResult.value.is_legal_person);
  const commitCompanyDisable = computed(() => {
    if (!isLegalPerson.value) {
      if (agentForm.applyTableName) {
        return agentForm.applyTableName.length === 0;
      } else {
        return true;
      }
    }
    return false;
  });

  const licenseFiles = ref([]);
  const licenseSelected = async (file) => {
    if (file.file.size / 1024 / 1024 > 3) {
      formLicenseError.value = i18nText('文件过大，限制3M以内');
      return;
    }
    if (formLicenseError.value == i18nText('文件过大，限制3M以内')) {
      formLicenseError.value = null;
    }

    const flieArr = file.file.name.split('.');
    let suffix = flieArr[flieArr.length - 1];
    suffix = suffix.toLocaleLowerCase();
    let sha256 = await keyPairUtil.largesha256(file.file.originFileObj);
    let fileHash = `0x${sha256}`;
    fileHash = fileHash + '.' + suffix;

    uploadImagePrepare('license', fileHash, file.file);
    uploadImage('license');
  };
  const removeApplyTable = () => {
    selectedApply.table = null;
    selectedApply.name = '';
    selectedApply.hash = '';
    agentForm.applyTableName = null;
    agentForm.hash = null;
  };
  const removeCodeLicense = () => {
    selectedCodeLicense.license = null;
    selectedCodeLicense.name = '';
    selectedCodeLicense.hash = '';
    agentForm.codeLicenseName = null;
    agentForm.codeLicenseHash = null;
  };

  const applyTableFiles = ref([]);
  const applyTableSelected = async (file) => {
    if (file.fileList.length == 0) {
      return;
    }
    const flieArr = file.file.name.split('.');
    let suffix = flieArr[flieArr.length - 1];
    suffix = suffix.toLocaleLowerCase();
    let sha256 = await keyPairUtil.largesha256(file.file.originFileObj);
    let fileHash = `0x${sha256}`;
    fileHash = fileHash + '.' + suffix;
    let imageFile = applyTableFiles.value[0];
    if (imageFile) {
      imageFile.status = 'done';
    }
    file.file.status = 'done';
    uploadImagePrepare('applyTable', fileHash, file.file);
    uploadImage('applyTable');
  };

  const codeLicenseFiles = ref([]);
  const codeLicenseSelected = async (file) => {
    if (file.fileList.length == 0) {
      return;
    }
    if (file.file.size / 1024 / 1024 > 3) {
      return;
    }
    const flieArr = file.file.name.split('.');
    let suffix = flieArr[flieArr.length - 1];
    suffix = suffix.toLocaleLowerCase();
    let sha256 = await keyPairUtil.largesha256(file.file.originFileObj);
    let fileHash = `0x${sha256}`;
    fileHash = fileHash + '.' + suffix;
    let imageFile = codeLicenseFiles.value[0];
    if (imageFile) {
      imageFile.status = 'done';
    }
    file.file.status = 'done';
    uploadImagePrepare('codeLicense', fileHash, file.file);
    uploadImage('codeLicense');
  };

  const uploadImagePrepare = (path, fileHash, file) => {
    if (path === 'license') {
      selectedLicense.path = URL.createObjectURL(file.originFileObj);
      selectedLicense.image = file.originFileObj;
      selectedLicense.name = file.name;
      selectedLicense.hash = fileHash;
    } else if (path === 'applyTable') {
      selectedApply.table = file.originFileObj;
      selectedApply.name = file.name;
      selectedApply.hash = fileHash;
    } else if (path === 'codeLicense') {
      selectedCodeLicense.license = file.originFileObj;
      selectedCodeLicense.name = file.name;
      selectedCodeLicense.hash = fileHash;
    }
  };
  const uploadImage = (path) => {
    let imageObject = getImageHash(path);
    let imageHash = imageObject.hash;
    let ocrParams = imageObject.ocrParams;
    return UploadFileAPI.getUploadInfo(imageHash)
      .then((response) => {
        return response;
      })
      .then((res) => {
        let url = res.url;
        let params = res.body_params;
        return UploadFileAPI.uploadFile(ocrParams.name, ocrParams.image, url, params)
          .then(() => {
            if (path == 'license') {
              formLicenseError.value = null;
            }
            return true;
          })
          .catch(() => {
            if (path == 'license') {
              formLicenseError.value = i18nText('上传失败');
            }
          });
      })
      .then(() => {
        if (path === 'license') {
          return CompanyAPI.ocrLicense(imageHash);
        } else if (path === 'applyTable') {
          return null;
        } else if (path === 'codeLicense') {
          return null;
        }
      })
      .then((response) => {
        if (path == 'license') {
          formLicenseError.value = null;
        }
        handleUploadSuccess(path, response);
      })
      .catch((error) => {
        try {
          let errorMessage =
            error.response.data.data.message ||
            (error.response.data.message && error.response.data.data);
          let showMessage = ErrorMessage.getLocalMessage(errorMessage);
          if (showMessage) {
            if (path == 'license') {
              formLicenseError.value = showMessage;
            }
          } else {
            if (path == 'license') {
              formLicenseError.value = error.toString();
            }
          }
        } catch (e) {
          if (path == 'license') {
            formLicenseError.value = error.toString();
          }
        }
      })
      .finally(() => {
        handleUploadFinish(path);
      });
  };
  const getImageHash = (path) => {
    let image = {};
    if (path === 'license') {
      selectedLicense.loading = true;
      image.hash = selectedLicense.hash;
      image.ocrParams = { name: selectedLicense.name, image: selectedLicense.image };
    } else if (path === 'applyTable') {
      selectedApply.loading = true;
      image.hash = selectedApply.hash;
      image.ocrParams = { name: selectedApply.name, image: selectedApply.table };
    } else if (path === 'codeLicense') {
      selectedCodeLicense.loading = true;
      image.hash = selectedCodeLicense.hash;
      image.ocrParams = {
        name: selectedCodeLicense.name,
        image: selectedCodeLicense.license,
      };
    }
    return image;
  };
  const handleUploadSuccess = (path, response) => {
    if (path === 'license') {
      licenseResult.value = response;
      licenseImg.path = selectedLicense.path;
      companyAuthMean.value = isLegalPerson.value ? 1 : 2;
    } else if (path === 'applyTable') {
      agentForm.applyTableName = selectedApply.name;
      agentForm.hash = selectedApply.hash;
    } else if (path === 'codeLicense') {
      agentForm.codeLicenseName = selectedCodeLicense.name;
      agentForm.codeLicenseHash = selectedCodeLicense.hash;
    }
  };
  const handleUploadFinish = (path) => {
    if (path === 'license') {
      selectedLicense.loading = false;
    } else if (path === 'applyTable') {
      selectedApply.loading = false;
    } else if (path === 'codeLicense') {
      selectedCodeLicense.loading = false;
    }
  };

  const router = useRouter();
  const createCompany = () => {
    if (!licenseResult.value) {
      formLicenseError.value = i18nText('请先上传营业执照');
      return;
    }
    if (companyAuthMean.value == 1) {
      loading.value = true;
      CompanyAPI.createCompanyByLegalWithoutChangePhone()
        .then((response) => {
          store.commit('company/CREATE_COMPANY', response);
          formLicenseError.value = null;
          router.push({ name: 'company' });
        })
        .catch((error) => {
          try {
            let errorMessage =
              error.response.data.data.message ||
              (error.response.data.message && error.response.data.data);
            let showMessage = ErrorMessage.getLocalMessage(errorMessage);
            if (showMessage) {
              formLicenseError.value = showMessage;
            } else {
              formLicenseError.value = error.toString();
            }
          } catch (e) {
            formLicenseError.value = error.toString();
          }
        })
        .finally(() => {
          loading.value = false;
        });
    } else if (companyAuthMean.value == 2) {
      loading.value = true;
      CompanyAPI.createCompanyByTable(agentForm.hash, agentForm.codeLicenseHash)
        .then((response) => {
          store.commit('company/CREATE_COMPANY', response);
          router.push({ name: 'company' });
        })
        .catch((error) => {
          try {
            let errorMessage =
              error.response.data.data.message ||
              (error.response.data.message && error.response.data.data);
            let showMessage = ErrorMessage.getLocalMessage(errorMessage);
            if (showMessage) {
              formLicenseError.value = showMessage;
            } else {
              formLicenseError.value = error.toString();
            }
          } catch (e) {
            formLicenseError.value = error.toString();
          }
        })
        .finally(() => {
          loading.value = false;
        });
    }
  };
  const downloadApplyTable = () => {
    window.open(config.enterpriseApplyFileURL);
  };
  const previewImage = async () => {
    return null;
  };
</script>

<style scoped>
  .create-container {
    padding: 48px 0px 60px 0px;
  }

  .create-prepare {
    flex-grow: 1;
  }

  .license-error-box {
    margin-bottom: 24px;
  }

  .create-radio-outer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .create-radio {
    display: flex;
    flex-direction: column;
  }

  .auth-radio {
    margin: 5px;
    font-size: 16px;
    color: #909399;
  }

  .license-tip {
    font-size: 16px;
    color: #909399;
    text-align: center;
  }

  .image-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0px 20px;
    margin-top: 24px;
  }

  .id-license,
  .license-sample,
  .upload-license-button {
    width: 224px;
    height: 130px;
  }

  .license-sample,
  .id-license {
    border-radius: 2px;
  }
  .legal-person-info {
    margin-top: 20px;
  }

  .commit-company {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .agent-step-upload {
    display: flex;
    align-items: center;
  }
  .apply-table-input {
    margin-bottom: 7px;
  }

  .white-blank {
    width: 100%;
    height: 200px;
  }

  .license-box {
    display: flex;
    flex-direction: column;
    width: 246px;
    height: 153px;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    color: @primary-color;
    background: #fafafa;
  }

  .upload-license-box:hover {
    border: 1px solid #2b55bb;
  }
  .license-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
</style>
