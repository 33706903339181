<template>
  <a-row type="flex" justify="space-between" align="middle">
    <a-col>
      <router-link :to="{ name: companyConfidential || companyAdmin ? 'home' : 'asset' }">
        <img ref="logoImageRef" :src="logoImage" :style="{ width: logoWidth }" />
      </router-link>
    </a-col>
    <a-col>
      <a-space :size="rightGap" align="center">
        <a-input-search
          v-if="showSearch && searchAvailable"
          ref="searchInput"
          v-model:value="searchValue"
          style="margin-top: 15px; min-width: 220px"
          :placeholder="i18nText('搜索数字指纹或者DNA')"
          @search="search"
        >
          <template #suffix>
            <a-upload :max-count="1" :show-upload-list="false" @change="fileSelected">
              <a-tooltip :title="i18nText('计算文件数字指纹并搜索')">
                <file-two-tone two-tone-color="#2B55BB" />
              </a-tooltip>
            </a-upload>
          </template>
        </a-input-search>
        <a-button
          :href="`${$appGlobal.CHAIN_BROWSER_URL}${$appGlobal.CHAIN_BROWSER_HOME}`"
          target="_blank"
          type="link"
          class="chain-link"
        >
          {{ $t(blockBrowseText) }}
        </a-button>
        <router-link :to="{ name: 'verifyFile' }" target="_blank" class="chain-link">
          {{ $t(config.fileVerifyCenterText) }}
        </router-link>
        <template v-if="showUser">
          <router-link
            v-if="showLogin"
            :to="{ name: 'login', params: { active: 'login' } }"
            style="color: white"
          >
            {{ $t('登录 / 注册') }}
          </router-link>
          <a-dropdown v-else @click="handleCommand" @visibleChange="handleDropdownVisible">
            <a-typography-link style="color: white; margin-left: 10px">
              {{ navUserInfo }}
              <down-outlined />
            </a-typography-link>
            <template #overlay>
              <a-menu style="margin-top: 20px" @click="handleCommand">
                <a-menu-item key="0" style="height: 40px" v-if="settingVisible">
                  <template #icon>
                    <user-outlined />
                  </template>
                  {{ $t('个人中心') }}
                </a-menu-item>
                <a-menu-item key="1" style="height: 40px">
                  <template #icon>
                    <home-outlined />
                  </template>
                  {{ displayCompanyName }}
                </a-menu-item>
                <a-menu-item key="3" style="height: 40px" v-if="false">
                  <a-dropdown>
                    <template #overlay>
                      <a-menu @click="handleLocaleClick">
                        <a-menu-item key="zh">
                          <img class="locale-image" :src="require('@/assets/zh_flag.png')" />
                          简体中文
                        </a-menu-item>
                        <a-menu-item key="en">
                          <img class="locale-image" :src="require('@/assets/en_flag.png')" />
                          English
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button type="text" style="padding-left: 0px">
                      <span style="margin-right: 10px">
                        <img :src="localeImage" class="locale-image" />
                      </span>
                      {{ localeText }}
                      <RightOutlined />
                    </a-button>
                  </a-dropdown>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="2" style="height: 40px">
                  <template #icon>
                    <logout-outlined />
                  </template>
                  {{ $t('退出账户') }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-space>
    </a-col>
  </a-row>
</template>

<script setup>
  import {
    DownOutlined,
    RightOutlined,
    FileTwoTone,
    HomeOutlined,
    UserOutlined,
    LogoutOutlined,
  } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import config from '/config';
  import { computed, onMounted } from 'vue';
  import { USER_COMPANY_ROLE_ADMIN, USER_COMPANY_ROLE_CONFIDENTIAL } from '@/model/role';
  import UserAPI from '@/api/v1/users';
  import SearchAPI from '@/api/v1/search';
  import { keyPairUtil } from '@/library/crypto/keypair';
  import { ref } from 'vue';
  import CompanyAPI from '@/api/v1/company';
  import ErrorMessage from '@/model/errorMessage';
  import { message } from 'ant-design-vue';
  import { useI18n } from 'vue-i18n';
  import { i18nText } from '@/lang';

  defineProps({
    showLogin: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showUser: {
      type: Boolean,
      default: true,
    },
    settingVisible: {
      type: Boolean,
      default: true,
    },
  });
  const store = useStore();
  const router = useRouter();
  const logoImageRef = ref();
  const logoImage = require('@/assets/' + config.navbarLogoImageName);
  const logoWidth = ref('0px');
  const rightGap = computed(() => {
    if (store.state.screenSize.width > 1200) {
      return 30;
    } else if (store.state.screenSize.width > 980) {
      return 16;
    } else {
      return 0;
    }
  });
  const blockBrowseText = computed(() =>
    config.frontPageVersion == 'default' ? '区块链浏览器' : '资产网络',
  );
  const company = computed(() => store.state.company);
  // const displayCompanyName = computed(() => (company.value.name ? company.value.name : '未绑定'));
  const displayCompanyName = computed(() => company.value.name);
  const searchAvailable = computed(
    () => company.value.status !== 3 && company.value.status !== 6 && company.value.status !== 7,
  );

  const phoneNum = computed(() => store.state.user.phonenumber);
  const name = computed(() => store.state.user.name);
  const companyConfidential = computed(
    () => store.state.company.role === USER_COMPANY_ROLE_CONFIDENTIAL,
  );
  const companyAdmin = computed(() => store.state.company.role === USER_COMPANY_ROLE_ADMIN);
  const navUserInfo = computed(() => (name.value ? hiddenName.value : hiddenPhone.value));
  const hiddenName = computed(() => {
    let nameText = name.value;
    if (nameText) {
      if (nameText.length === 2) {
        return '*' + nameText.substring(1, 2); //截取name 字符串截取第一个字符，
      } else if (nameText.length === 3) {
        return '*' + nameText.substring(1, 3); //截取第一个和第三个字符
      } else if (nameText.length > 3) {
        return '**' + nameText.substring(2, nameText.length); //截取第一个和大于第4个字符
      }
    }
    return null;
  });
  const hiddenPhone = computed(() => {
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return phoneNum.value.replace(reg, '$1****$2');
  });

  const handleCommand = (item) => {
    switch (item.key) {
      case '0':
        router.push({ name: 'setting' });
        return;
      case '1':
        router.push({ name: 'company' });
        return;
      case '2':
        logout();
        return;
    }
  };
  const dropdownVisible = ref(false);
  const handleDropdownVisible = (visible) => {
    dropdownVisible.value = visible;
  };

  const i18n = useI18n();
  const localeImage = computed(() => {
    if (store.state.locale.language == 'en') {
      return require('@/assets/en_flag.png');
    } else {
      return require('@/assets/zh_flag.png');
    }
  });
  const localeText = computed(() => (store.state.locale.language == 'en' ? 'English' : '简体中文'));
  const handleLocaleClick = (e) => {
    store.commit('locale/SET_LANGUAGE', e);
    i18n.locale = e;
    window.location.reload();
    console.log('click', e);
  };
  const logout = () => {
    if (!confirm('是否确认退出？')) return;
    UserAPI.logout()
      .then(() => {
        window.confidentialNotified = false;
        store.commit('user/CLEAR_USER');
        store.commit('company/CLEAR_COMPANIES');
        router.push({ name: 'login', params: { active: 'login' } });
      })
      .catch((err) => {
        try {
          let showMessage = ErrorMessage.getMessageFromError(err);
          if (showMessage) {
            message.error(showMessage);
          } else {
            message.error(err.toString());
          }
        } catch (e) {
          message.error(e.toString());
        }
        window.confidentialNotified = false;
        router.push({ name: 'login', params: { active: 'login' } });
      });
  };

  const search = (keyword) => {
    keyword = keyword.trim();
    if (!keyword) {
      return;
    }
    SearchAPI.searchDip(keyword)
      .then((res) => {
        switch (res.entity_type) {
          case 0: // 未搜索到结果
            router.push({ name: 'empty' });
            return;
          case 1: // 数字知识产权
            router.push({ name: 'assetDetail', params: { asset_id: res.dip_id } });
            return;
          case 2: // 商业秘密
            router.push({
              name: 'project',
              params: { projectId: res.project_id, dip_id: res.dip_id },
            });
            return;
          case 3: // 超级软著
            router.push({
              name: 'commitDetail',
              params: { commit_id: res.dip_id, project_id: res.project_id },
            });
            return;
          case 4: // 企业应用
            router.push({
              name: 'applicationDetail',
              params: { application_id: res.dip_id },
            });
            router.push({
              name: 'applicationFileDetail',
              params: { application_id: res.project_id, file_id: res.dip_id },
            });
            return;
        }
      })
      .catch(() => {
        router.push({ name: 'empty' });
      });
  };
  const searchValue = ref('');
  const searchInput = ref();
  const fileSelected = async (file) => {
    if (file.file.status != 'uploading') {
      searchValue.value = await keyPairUtil.largesha256(file.file.originFileObj);
      searchInput.value.focus();
      search(searchValue.value);
    }
  };
  // const localeVisible = ref(false);
  // const locale = computed(() => store.state.locale.language);
  // const cureentLanguage = computed(() => (locale.value == 'enUS' ? 'en' : '中文'));
  // const i18n = useI18n();
  // const switchLanguage = () => {
  //   let language = locale.value == 'zhCN' ? 'enUS' : 'zhCN';
  //   let localeLanguage = locale.value == 'zhCN' ? 'en' : 'cn';
  //   i18n.value = localeLanguage;
  //   store.commit('locale/SET_LANGUAGE', language);
  // };
  onMounted(() => {
    CompanyAPI.getCompany()
      .then((response) => {
        store.commit('company/SET_COMPANY', response);
      })
      .catch(() => {});
    let image = new Image();
    image.src = logoImageRef.value.src;
    image.onload = () => {
      console.log(image.width);
      logoWidth.value = image.width / 2 + 'px';
    };
  });
</script>

<style lang="less" scoped>
  .chain-link {
    color: white;
  }
  .chain-link:hover {
    color: @text-selection-bg;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
</style>
