import v2 from './v2';

export default {
  login(phonenumber, password, code) {
    return v2.post('/users', {
      login_id: phonenumber,
      password: password,
      validation_code: code,
    });
  },
};
