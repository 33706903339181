<template>
  <a-space direction="vertical" style="width: 100%">
    <a-row
      type="flex"
      justify="space-between"
      align="middle"
      :gutter="20"
      style="height: 100px; flex-wrap: nowrap"
    >
      <a-col flex="22px"></a-col>
      <a-col flex="auto">
        <a-row type="flex" justify="space-between" align="middle" style="height: 100px">
          <a-col>
            <img
              ref="logoImageRef"
              :src="logoImage"
              @click="clickWebTitle"
              :style="{ width: logoWidth }"
            />
          </a-col>
          <a-col>
            <a-space :size="rightGap" align="center">
              <router-link
                v-if="config.serviceSystem"
                :to="{ name: '' }"
                class="chain-link nav-button"
                :style="{ color: color }"
              >
                {{ config.serviceSystem }}
              </router-link>
              <router-link
                v-if="config.corporationCompany"
                :to="{ name: 'verifyFile' }"
                target="_blank"
                class="chain-link nav-button"
                :style="{ color: color }"
              >
                {{ config.corporationCompany }}
              </router-link>
              <a
                :href="`${$appGlobal.CHAIN_BROWSER_URL}${$appGlobal.CHAIN_BROWSER_HOME}`"
                target="_blank"
                type="link"
                class="chain-link nav-button"
                :style="{ color: color }"
              >
                {{ blockBrowseText }}
              </a>
              <router-link
                :to="{ name: 'verifyFile' }"
                target="_blank"
                class="chain-link nav-button"
                :style="{ color: color }"
              >
                {{ config.fileVerifyCenterText }}
              </router-link>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="22px"></a-col>
    </a-row>
    <a-row justify="center">
      <a-col>
        <a-space :size="rightGap" align="center">
          <template v-if="showUser">
            <a-button
              v-if="showLogin && config.frontPageVersion == 'default'"
              @click="clickLogin"
              class="chain-link"
              style="height: 50px; border-radius: 12px; width: 132px"
              :style="{ color: loginColor, background: loginBgColor }"
            >
              登录/注册
            </a-button>
            <a-button
              v-else-if="showLogin"
              @click="clickLogin"
              class="chain-link xa-login-button"
              style="height: 50px; border-radius: 12px; width: 132px"
              :style="{ color: loginColor, background: loginBgColor }"
            >
              登录/注册
            </a-button>
            <a-dropdown v-else @click="handleCommand" @visibleChange="handleDropdownVisible">
              <a-typography-link style="color: white; margin-left: 10px">
                {{ navUserInfo }}
                <down-outlined />
              </a-typography-link>
              <template #overlay>
                <a-menu style="margin-top: 20px" @click="handleCommand">
                  <a-menu-item key="0" style="height: 40px">
                    <template #icon>
                      <user-outlined />
                    </template>
                    个人中心
                  </a-menu-item>
                  <a-menu-item key="1" style="height: 40px">
                    <template #icon>
                      <home-outlined />
                    </template>
                    {{ company.name }}
                  </a-menu-item>
                  <a-menu-divider />
                  <a-menu-item key="2" style="height: 40px">
                    <template #icon>
                      <logout-outlined />
                    </template>
                    退出账户
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-space>
      </a-col>
    </a-row>
  </a-space>
</template>

<script setup>
  import { DownOutlined, HomeOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import config from '/config';
  import { computed, onMounted, ref } from 'vue';
  import UserAPI from '@/api/v1/users';
  import CompanyAPI from '@/api/v1/company';
  import ErrorMessage from '@/model/errorMessage';
  import { message } from 'ant-design-vue';

  const props = defineProps({
    showLogin: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    showUser: {
      type: Boolean,
      default: true,
    },
    isBlack: {
      type: Boolean,
      default: false,
    },
  });
  const store = useStore();
  const router = useRouter();
  const logoImageRef = ref();
  const logoImage = computed(() => {
    if (config.frontPageVersion == 'xian') {
      return require('./images/xian-logo.png');
    } else if (config.navbarLogoImageName == 'hetu_navbar_logo.png') {
      return require('./images/hetu_logo.png');
    } else {
      return require('@/assets/' + config.navbarLogoImageName);
    }
  });
  const logoWidth = ref('0px');
  const color = computed(() => (props.isBlack ? 'black' : 'white'));
  const loginColor = computed(() => (props.isBlack ? 'white' : 'black'));
  const loginBgColor = computed(() => (props.isBlack ? '#456ff4' : '#e1eafc'));
  const rightGap = computed(() => {
    if (store.state.screenSize.width > 1200) {
      return 30;
    } else if (store.state.screenSize.width > 980) {
      return 16;
    } else {
      return 10;
    }
  });
  const blockBrowseText = computed(() =>
    config.frontPageVersion == 'default' ? '区块链浏览器' : '资产网络',
  );

  const phoneNum = computed(() => store.state.user.phonenumber);
  const name = computed(() => store.state.user.name);
  const company = computed(() => store.state.company);
  const navUserInfo = computed(() => (name.value ? hiddenName.value : hiddenPhone.value));
  const hiddenName = computed(() => {
    let nameText = name.value;
    if (nameText) {
      if (nameText.length === 2) {
        return '*' + nameText.substring(1, 2); //截取name 字符串截取第一个字符，
      } else if (nameText.length === 3) {
        return '*' + nameText.substring(1, 3); //截取第一个和第三个字符
      } else if (nameText.length > 3) {
        return '**' + nameText.substring(2, nameText.length); //截取第一个和大于第4个字符
      }
    }
    return null;
  });
  const hiddenPhone = computed(() => {
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return phoneNum.value.replace(reg, '$1****$2');
  });
  const clickWebTitle = () => {
    if (config.frontPageVersion !== 'xian') {
      if (store.state.user.phonenumber) {
        router.push({ name: config.defaultPage });
      } else {
        window.location.reload();
      }
    }
  };
  const clickLogin = () => {
    if (store.state.user.phonenumber) {
      router.push({ name: config.defaultPage });
    } else {
      router.push({ name: 'login', params: { active: 'login' } });
    }
  };
  const handleCommand = (item) => {
    switch (item.key) {
      case '0':
        router.push({ name: 'setting' });
        return;
      case '1':
        router.push({ name: 'company' });
        return;
      case '2':
        logout();
        return;
    }
  };
  const dropdownVisible = ref(false);
  const handleDropdownVisible = (visible) => {
    dropdownVisible.value = visible;
  };
  const logout = () => {
    if (!confirm('是否确认退出？')) return;
    UserAPI.logout()
      .then(() => {
        window.confidentialNotified = false;
        store.commit('user/CLEAR_USER');
        store.commit('company/CLEAR_COMPANIES');
        router.push({ name: 'login', params: { active: 'login' } });
      })
      .catch((err) => {
        try {
          let showMessage = ErrorMessage.getMessageFromError(err);
          if (showMessage) {
            message.error(showMessage);
          } else {
            message.error(err.toString());
          }
        } catch (e) {
          message.error(e.toString());
        }
        window.confidentialNotified = false;
        router.push({ name: 'login', params: { active: 'login' } });
      });
  };
  onMounted(() => {
    CompanyAPI.getCompany()
      .then((response) => {
        store.commit('company/SET_COMPANY', response);
      })
      .catch(() => {});
  });
  onMounted(() => {
    let image = new Image();
    image.src = logoImageRef.value.src;
    image.onload = () => {
      logoWidth.value = image.width / 2 + 'px';
    };
  });
</script>

<style lang="less" scoped>
  .chain-link {
    font-size: 18px;
  }
  .chain-link:hover {
    color: @text-selection-bg;
  }
  .xa-login-button:hover {
    background: white !important;
  }
  .nav-button:hover {
    color: #ffffff80 !important;
  }
</style>
