<template>
  <a-layout style="width: 100; height: 100%">
    <a-layout-header>
      <Navbar :show-login="false" :settingVisible="false" :showSearch="false" />
    </a-layout-header>
    <a-layout-content>
      <div class="bg-image" v-if="config.bgImageInReviewVisible">
        <img
          alt="login"
          style="width: 100%; height: 100%"
          :src="require('@/assets/login-bg.png')"
        />
      </div>
      <a-space class="content-container" align="center" direction="vertical" :size="40">
        <a-row justify="center">
          <a-card class="shadow-div" style="width: 520px">
            <a-result status="success">
              <template #icon>
                <smile-two-tone v-if="pending" two-tone-color="#2B55BB" style="font-size: 60px" />
                <frown-two-tone v-if="deny" two-tone-color="#2B55BB" style="font-size: 60px" />
              </template>
              <template #extra>
                <a-space direction="vertical" align="center">
                  <a-typography-title :level="5">
                    {{ resultTitle }}
                  </a-typography-title>
                  <a-typography-title :level="3" v-if="pending">
                    {{ company.name }}
                  </a-typography-title>
                  <a-typography-text v-if="!pending">
                    {{ resultSubTitle }}
                  </a-typography-text>
                  <div style="height: 40px"></div>
                  <a-typography-text type="secondary" v-if="pending">
                    {{ resultSubTitle }}
                  </a-typography-text>
                </a-space>
              </template>
            </a-result>
          </a-card>
        </a-row>
      </a-space>
    </a-layout-content>
  </a-layout>
</template>

<script>
  import UserModel from '@/model/user';
  import CompanyModel from '@/model/company';
  import config from '/config';
  import Navbar from '@/views/navbar.vue';
  export default {
    beforeRouteEnter(to, from, next) {
      UserModel.getMyUserInfo()
        .then((user) => {
          if (user !== null) {
            CompanyModel.getJoinedCompanies();
            CompanyModel.checkHasCompany()
              .then((company) => {
                if (company.member_confirmed_status !== 1 || company.name.length == 0) {
                  next();
                } else {
                  next({ name: config.defaultPage });
                }
              })
              .catch(() => {
                next();
              });
          } else {
            next({ name: 'login', params: { active: 'login' } });
          }
        })
        .catch(() => {
          next({ name: 'login', params: { active: 'login' } });
        });
    },
  };
</script>

<script setup>
  import { SmileTwoTone, FrownTwoTone } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { computed } from 'vue';
  import { i18nText } from '@/lang';
  const store = useStore();

  const company = computed(() => store.state.company);
  const pending = computed(() => {
    return company.value.member_confirmed_status === 2;
  });
  const deny = computed(() => {
    return company.value.member_confirmed_status === 3;
  });
  const statusText = computed(() => {
    if (pending.value) {
      return i18nText('管理员正在审核,请稍候...');
    }
    return '';
  });
  const resultTitle = computed(() =>
    pending.value ? i18nText('您已申请加入') : i18nText('管理员拒绝申请'),
  );
  const resultSubTitle = computed(() =>
    pending.value ? statusText.value : i18nText('请联系管理员后再次尝试加入'),
  );
</script>

<style scoped>
  .content-container {
    position: absolute;
    top: 104px;
    left: 0;
    right: 0;
    object-fit: cover;
    /*z-index: -2;*/
  }
  .shadow-div {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }
</style>
