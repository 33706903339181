import CompanyAPI from '@/api/v1/company';
import store from '@/store';

export default {
  checkHasCompany() {
    return CompanyAPI.getCompany()
      .then((response) => {
        store.commit('company/SET_COMPANY', response);
        return response;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  },

  getJoinedCompanies() {
    return CompanyAPI.getJoinedCompanies()
      .then((companies) => {
        store.commit('company/SET_COMPANIES', companies);
        return companies;
      })
      .catch((error) => {
        Promise.reject(error);
      });
  },
};
