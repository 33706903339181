import assetServer from './assetServer';
import fileService from '../file-service';
import config from '/config';

export default {
  verifyRequest(dna) {
    return assetServer.get('/public/zkp-proof-request' + `?zkp_register_dna=${dna}`, {
      responseType: 'blob',
    });
  },
  verify(proof_request_file_hash, proof_zip, fileName) {
    let formData = new FormData();
    formData.append('hash', proof_request_file_hash);
    formData.append('file', proof_zip, fileName);
    // /v1/public/zkp-proof-verify
    let endPoint = '/v1/public/zkp-proof-verify';
    if (config.verifyBaseAPI == 'https://api.auth.hndip.cn') {
      endPoint = '/v1/public/verify-proof';
    }
    return fileService.fileService.put(config.verifyBaseAPI + endPoint, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};
