let validationCode = {
  '手机号码:phone': 'Phone: {phone}',
  '验证码已发送到您的手机,10分钟内有效': 'The verification code has been sent to your mobile phone, please input it in 10 minutes.',
  '您正在使用手机短信验证': 'You are verifying your account using SMS',
  '获取验证码': 'Get code',
  '6位数字': '6 digits',
};

let sidebars = {
  '数字知识产权': 'Digital IP',
  '商业秘密保险箱': 'Vault',
  '企业中心': 'Enterprise',
  '个人中心': 'Profile',
  '企业应用': 'External Apps',
  '积分管理': 'Credits',
  '超级软著': 'Source codes',
};

let navbarTexts = {
  '区块链浏览器': 'Block Explorer',
  '文件验证中心': 'Verification Center',
  '搜索数字指纹或者DNA': 'Search digital fingerprint and DNA',
  '计算文件数字指纹并搜索': 'Calculate fingerprint and search',
  '登录/注册': 'Sign in/Sign up',
  '个人中心': 'Profile',
  '退出账户': 'Quit',
  '是否确认退出？': 'Do you really want to quit?',
};

let login = {
  '账户登录': 'Sign in',
  '手机号或密码错误': 'Phone number or password error',
  '用户名或密码错误': 'Username or password error',
  '请输入登录密码': 'Input your password',
  '登录': 'Sign in',
  '忘记密码': 'Forgot password',
  '账户注册': 'Sign up',
  '请输入密码': 'Input your password',
  '请再次输入密码': 'Repeat your password',
  '请输入姓名（可选）': 'Input your name(optional)',
  '我已阅读并同意': 'I have read and agreed to the',
  '用户协议': 'Terms of service',
  '注册用户': 'Sign up',
  '请输入正确的手机号码': 'Invalid mobile phone number',
  '两次密码输入不一致': 'Two passwords are different',
  '请先阅读并同意用户协议': 'Please read and agree the terms of service',
  '重置密码': 'Reset password',
};

let verifyFileTexts = {
  '文件验证中心': 'Verification Center',
  '此处选择原始文件': 'The original file',
  '选择': 'Choose',
  '数字产权证明': 'DIP Proof',
  '此处选择后缀名为“pdf”的产权证明文件': 'Choose the PDF file of the DIP Proof',
  '数字产权证明可证明数字文件（数据）的登记时间、数据完整性和数据登记者身份。通过数字产权验证的文件，证明原始文件从登记时刻起已经存在、内容完整未被篡改，并且由产权证明文件提供者进行登记。':
      'The DIP Proof is a proof of the existing time, the content integrity and the owner\'s identity of the file. When a given file passes the DIP Proof verification, we know that the file has been existing since the claimed time, the content remains the same, and the file is owned by the claimed person.',
  '验证成功': 'Verification passed',
  '验证失败': 'Verification failed',
  '该文件未通过验证，可能存在真实性风险！': 'Verification failed!',
  '文件数字指纹': 'Digital fingerprint',
  '数字财产DNA': 'Digital assets DNA',
  '区块链地址': 'Block address',
  '企业名称': 'Enterprise name',
  '产权证明文件有效期（截止日期之前）': 'Expires at',
  '返回': 'Back',
  '验证文件错误': 'Invalid DIP proof file',
  '错误提示:': 'Error message: ',
  '请选择原始文件': 'Choose the original file',
  '选择原始文件': 'Choose the original file',
  '请选择证明文件': 'Choose the DIP Proof file',
};

let auth = {
  '管理员正在审核,请稍候...': 'Review in progress. Please come back later...',
  '您已申请加入': 'You are applying to join',
  '管理员拒绝申请': 'Your application has been declined',
  '请联系管理员后再次尝试加入': 'Please contact the manager and try again later',
  '要求:': 'Requirements：',
  '请上传清晰的身份证原件正、反面照片，系统将自动识别证件信息。':
      'Please upload the clear photos of the front and back of your ID card. Your info will be recognized automatically.',
  '请使用与手机号一致的身份实名。': 'Please use the same identity as the one who owns the phone number',
  '图片的具体需求:': 'Requirements of the photo: ',
  '1、图片需要小于3M': '1. The file size must be less than 3M.',
  '2、图像格式:JPEG、JPG、PNG、BMP、GIF': '2. The format could be JPEG, JPG, PNG, BMP or GIF.',
  '身份证人像面': 'The avatar side of the ID card',
  '点击上传': 'Upload',
  '身份证国徽面': 'The emblem side of the ID card',
  '认证': 'Authenticate',
  '企业名称': 'Enterprise name',
  '返回上一步': 'Back',
  '加入企业': 'Join the enterprise',
  '请输入正确的企业名称': 'Please input the correct enterprise name',
  '营业执照:': 'Enterprise License: ',
  '请上传彩色原件或加盖企业公章的复印件。': 'Please upload a colored photo of the original license or a copy with the official seal on',
  '企业名称:': 'Enterprise name：',
  '营业执照号:': 'License number：',
  '法定代表人:': 'Legal representative：',
  '企业法人线上快速审核通道': 'Quick authentication process for the legal representative',
  '其他方式审核': 'Other authentication method',
  '步骤1': 'Step 1',
  '请打印企业申请表,打印后填写相关信息并签字盖章,然后拍照或彩色扫描后上传':
      'Please download and print the application form. Fill in the data. Sign and seal with the legal identities. Then upload a colored photo or scanned copy of it.',
  '下载企业认证申请表': 'Download the application form',
  '步骤2': 'Step 2',
  '上传相关申请表': 'Upload the application form',
  '上传组织代码证（可选）': 'Upload the Organization Code Certificate (Optional)',
  '文件过大，限制3M以内': 'The file size is larger than 3M.',
  '请先上传营业执照': 'Please upload the license first',
  '实名认证': 'Real name authentication',
  '加入/创建企业': 'Create/Join an enterprise',
  '您还需选择以下操作': 'Choose the next step',
  '创建企业': 'Create enterprise',
};

let errorMessage = {
  '您的积分不足，请先充值': 'Not enough credits. Please charge first.',
  '用户已存在': 'User already exists',
  '企业不存在，可能正在审核中': 'The enterprise does not exist. Might be still in verification.',
  '文件已存在, 请重新选择': '文件已存在, 请重新选择',
  '保险箱金钥已过期': '保险箱金钥已过期',
  '项目已存在，请修改项目名': '项目已存在，请修改项目名',
  '用户不存在': '用户不存在',
  '原始密码错误': '原始密码错误',
  '操作太频繁了，请稍后再试': '操作太频繁了，请稍后再试',
  'token已过期，请重新输入邮箱': 'token已过期，请重新输入邮箱',
  '数字财产ID不正确': '数字财产ID不正确',
  '原始文件与证明文件不匹配': '原始文件与证明文件不匹配',
  '证明文件不存在，可能已失效': '证明文件不存在，可能已失效',
  '证明文件格式错误': '证明文件格式错误',
  '实名认证失败，请核对后重新输入': '实名认证失败，请核对后重新输入',
  '手机验证码错误，请核对后重新输入': 'Incorrect verification code',
  '验证码已过期，请重新输入': 'The code is expired. Please resend the code.',
  '验证码请求过于频繁，请一分钟后再试': '验证码请求过于频繁，请一分钟后再试',
  '验证码未发送，请先发送验证码': '验证码未发送，请先发送验证码',
  '企业已存在': 'The enterprise already exists',
  '你已加入企业': 'You have joined the enterprise',
  '你的账号已被冻结': 'Your account is fronzen',
  '身份信息错误，请检查后重新输入': '身份信息错误，请检查后重新输入',
  '图像识别失败，可能是图片模糊导致': '图像识别失败，可能是图片模糊导致',
  '手机实名身份和身份证信息校验不一致': '手机实名身份和身份证信息校验不一致',
  '企业法人与营业执照信息不符': 'The legal representative is not the same as the shown on the license',
  '营业执照未上传': '营业执照未上传',
  '身份证已过期': 'The ID card expired',
  '图片识别失败，可能是图片模糊导致': 'The photo recognization has failed. Please try a clearer photo.',
  '身份证识别失败，可能是图片模糊导致': '身份证识别失败，可能是图片模糊导致',
  '身份证国徽面照片识别失败,请重新拍摄并上传': '身份证国徽面照片识别失败,请重新拍摄并上传',
  '身份证人像面照片识别失败,请重新拍摄并上传': '身份证人像面照片识别失败,请重新拍摄并上传',
  '营业执照识别失败,请重新拍摄并上传': '营业执照识别失败,请重新拍摄并上传',
  '无效验证码': 'Incorrect verification code',
  '必须上传正面和反面': '必须上传正面和反面',
  '填写的身份信息不正确': '填写的身份信息不正确',
  '身份证信息校验不通过，可能与手机号实名信息不符':
      '身份证信息校验不通过，可能与手机号实名信息不符',
  '您已加入企业': '您已加入企业',
  '法人信息与营业执照信息不符': '法人信息与营业执照信息不符',
  '应用已存在': '应用已存在',
  '产权证明证书解析错误': '产权证明证书解析错误',
  '产权证明验证失败，与原始内容不一致': '产权证明验证失败，与原始内容不一致',
  '超出限制长度，请重新输入': '超出限制长度，请重新输入',
  '内容太短，请重新输入': '内容太短，请重新输入',
  '用户未实名认证，请先完成实名认证': '用户未实名认证，请先完成实名认证',
};

let files = {
  '创意作品 （视听文学作品）': '创意作品 （视听文学作品）',
  '创意设计 （品牌徽标、图案、织物、建筑等）': '创意设计 （品牌徽标、图案、织物、建筑等）',
  '工业品外观设计 （技术原理图、详图、工艺流程等）':
      '工业品外观设计 （技术原理图、详图、工艺流程等）',
  '代码 （用于软件、应用程序、游戏等）': '代码 （用于软件、应用程序、游戏等）',
  '研究（实验室记录、报告等发现）': '研究（实验室记录、报告等发现）',
  '数据（AI建模、基因测序等）': '数据（AI建模、基因测序等）',
  '有数字签名的文件（合同、信件、证书等）': '有数字签名的文件（合同、信件、证书等）',
  '其它 （例如，损害资料或未签名文件）': '其它 （例如，损害资料或未签名文件）',
};

let enterprises = {
  '企业成员': 'Member',
  '超级管理员': 'Administrator',
  '管理员': 'Manager',
  '保密员': '保密员',
  '企业数字身份': 'Enterprise digital identity',
  '证书上链地址': 'Blockchain address of the certificate',
  '证书链': 'Certificate chain',
  '证书主体': 'Certificate entity',
  '详情': 'Details',
  '证书使用记录': 'Certificate usage records',
  '证书详情': 'Certificate details',
  '证书所有者': 'Certificate owner',
  '颁发者': 'Issuer',
  '签发日期': 'Issued at',
  '失效日期': 'Expires at',
  '公钥': 'Public key',
  '签署时间': 'Signing date',
  '签署类型': 'Signing type',
  '签署内容': 'Signing content',
  '操作人': 'Operator',
  '签署文件': 'Signing file',
  '详细信息': 'Details',
  '证书名': 'Certificate name',
  '签发时间': '签发时间',
  '证书DNA': 'Certificate DNA',
  '产权签署证书': 'DIP signing certificate',
  '企业根证书': 'Enterprise root certificate',
  '成员名单': 'Members list',
  '修改权限': 'Change roles',
  '冻结': 'Freeze',
  '解除冻结': '解除冻结',
  '冻结账号': 'Freeze the account',
  '是否冻结成员帐号?': 'Do you really want to freeze {name}\s account?',
  '是否解冻成员账号?': '是否解冻{name}成员账号?',
  '解冻账号': '解冻账号',
  '成员申请': 'Member applications',
  '已通过': 'Approved',
  '已拒绝': 'Declined',
  '通过': 'Approve',
  '不通过': 'Decline',
  '角色': 'Role',
  '手机号码': 'Phone number',
  '成员姓名': 'Name',
  '申请时间': 'Applied at',
  '企业用户只能看自己的数据': 'A member can only access his own data',
  '保密员可以看企业里全部数据': '保密员可以看企业里全部数据',
  '企业名称': 'Enterprise name',
  '营业执照号': 'License number',
  '数字财产信用分': '数字财产信用分',
  '认证中': 'Verifying',
  '审核未通过': 'Verification failed',
  '已认证': 'Verified',
  '暂无价值': 'Not enough data',
  '价值极高': 'Perfect',
  '价值良好': 'Good',
  '企业信息': 'Enterprise into',
  '数字身份': 'Digital identity',
  '成员管理': 'Member management',
  '密钥状态': 'Private key state',
  '已托管': 'Managed',
  '密钥使用历史': 'Private key usage history',
  '密钥取消托管后，您将无法通过平台的Web服务进行文件登记操作。点击确认后将进入审核流程，稍后会有专员与您联系。确认取消托管吗？':
      'If the private key management is cancelled, you will no longer be able to register files using the web interface. After the cancellation is confirmed, an agent will reach you for further operations. Are you sure to initiate the cancellation?',
  '取消托管': 'Cancel management',
  '审核中..': 'Verifying...',
  '日期': 'Date',
  '上传文件': 'Upload file',
  '文件类型': 'File type',
};

let application = {
  '创建一个新项目': 'Create a new project',
  '应用简介': 'Application description',
  '请输入应用名称': 'Input the application name',
  '请输入应用简介': 'Input the application description',
  '应用信息': 'Application info',
  '应用名称': 'Application name',
  '应用描述': 'Application description',
  '应用状态': 'Application status',
  '应用数据': 'Application data',
  '应用数据详情': '应用数据详情',
  '企业应用': 'External applications',
  '创建应用': 'Create',
  '最近更新': 'Updated at',
  '未连接': 'No connection',
  '连接正常': 'Connection success',
  '连接异常': 'Connection error',
  '数据中断': 'Connection lost',
  '上一次数据提交时间': 'Last upload time',
  '数据提交有误': 'Uploaded file error',
  '请求数据': 'Request data',
  '错误消息': 'Error message',
  '监控报警': 'Monitoring alarm',
  '启用短信报警': 'Enable SMS alarm.',
  '小时无数据后发送报警短信': 'hours without data will send an alarm SMS',
};

let assets = {
  '已登记的文件': 'Registered files',
  '登记文件': 'Register file',
  '登记者': 'Registrant',
};

let vault = {
  '文件修改历史': 'File modification history',
  '验证手机号后进入保险箱': 'The phone must be verified before viewing the details',
  '验证': 'Verify',
  '选择文件': 'Choose file',
  '数字财产名': 'Digital assets name',
  '还未选择文件': 'The file is not chosen',
  '数字指纹': 'Digital signature',
  '选择文件后自动进行计算': 'Calculated automatically after choosing the file',
  '登记类型': 'Digital asset type',
  '设置数字财产类型': 'Choose the digital asset type',
  '文件描述': 'File description',
  '请输入内容': 'Input the content',
  '登记文件': 'Register',
  '积分扣除提醒': 'Pay with credits',
  '您的账户中有:积分': 'Available credits in your account: {creditBalance}',
  '本次存证将扣除您账户中': 'Credits will be used to pay for the registration',
  '{consumeCredit}积分': '{consumeCredit} credits',
  '积分余额不足': 'Insufficient credits',
  '您的积分余额已不足': 'The credits in your account is not enought',
  '请联系系统管理员': 'Please contact your manager',
  '请选择文件': 'Choose file',
  '请输入文件名': 'Input the file name',
  '正在计算...': 'Calculating...',
  '商业秘密项目': 'Trade secret project',
  '创建一个新项目': 'Create a new project',
  '项目是与特定IP管理过程或活动相关的数据和文件的整合':
      'A project is a collection of the files used in a dedicated IP management process or activity',
  '存证完成': 'Registration completed',
  '文件被更新': 'File is updated',
  '无文件': 'Empty',
  '项目': 'Project',
  '未找到搜索结果!': 'No search result',
};

let proofs = {
  '生成中': 'Generating',
  '已生成': 'Generated',
  '登记新版本': 'Update',
  '下载文件': 'Download',
  '产权证明': 'DIP Proof',
  '登记信息': 'Registration info',
  '所有者': 'Owner',
  '区块信息': 'Block info',
  '数字指纹': 'Digital fingerprint',
  '企业数字公钥': 'Enterprise public key',
  '该数字财产已在互联网法院存证，如需详细内容，请与联系。':
      'The digital asset has been registered at the Courts of the Internet. For further information, please contact {webTitle}.',
  '签名信息': 'Signing info',
  '企业数字签名': 'Enterprise digital signature',
  '签署内容': 'Signing content',
  '企业隐私身份': 'Enterprise private identity',
  '下载': 'Download',
  '创建人': 'Creator',
  '状态': 'State',
  '失效': 'Expired',
  '有效': 'Valid',
  '生成产权证明': 'Generate',
  '产权证明有效期': 'Valid period',
  '生成': '生成',
  '请选择正确格式的文件': 'Incorrect file format',
};

let home = {
  '全网数据总量': 'Whole network',
  '商业秘密': 'Trade secrets',
  '项目数量': 'Projects number',
  '文件托管': 'Managed files',
  '超级软著': 'Source code projects',
  '应用数量': 'Apps number',
  '动态': 'Activities',
  '早安': 'Good morning',
  '下午好': 'Good afternoon',
  '晚上好': 'Good evening',
  '代码提交次数': '代码提交次数',
  '登记了数字知识产权财产': 'registered digital IP',
  '登记了商业秘密财产': 'registered trade secret',
  '  登记了超级软著,登记哈希为:': 'commited source codes. The commit hash is ',
  '创建了商业秘密项目': 'created trade secret project',
  '创建了超级软著项目': 'created source code project',
  ' 更新了商业秘密财产 ': ' updated the trade secret project ',
  '登记新文件': 'Add new file',
  '企业内登记的数据知识产权总量，包括全部类型的数字知识产权登记的总数。':
      'The total number of registrations inside the enterprise, including all the registration types',
  '企业内全部商业秘密项目中登记的文件总数量，包括已托管和未托管的全部文件。':
      'The number of the files registered in all of the trade secret projects, including the managed and unmanaged files',
  '企业内全部超级软著项目的代码提交次数。': 'The number of the commits in all of the source code projects',
  '通过API对接的企业内全部应用的数据提交次数。': 'The number of submittions in all of the external applications',
};

let copyRights = {
  '提交记录': 'Commit records',
  '编辑者': 'Commiter',
  '提交时间': 'Committed at',
  '提交说明': 'Commit logs',
  '存证信息': 'Registration info',
  '数字财产登记时间': 'Registration time',
  '企业数字公钥': 'Enterprise public key',
  '数字签名': 'Digital signature',
  '零知识证明': 'Zero knowledge proof',
  '项目类型': 'Project type',
  '项目简介': 'Project description',
  '请输入项目简介': 'Input the project description',
  '请输入项目名称': 'Input the project name',
  '超级软著项目': 'Source code project',
  '创建一个项目': 'Create a project',
  '项目名称长度限制为30字符以内': 'Project name should be less than 30 letters',
  '项目简介长度限制为200字符以内': 'Project description should be less than 200 letters',
};

let credits = {
  '积分使用历史': 'Credits history',
  '可用积分:': 'Available credits：',
  '人员筛选': 'Filter by person',
  '重置': 'Reset',
  '登记编号:': 'Registration number: ',
  '申请状态:已同意': 'Application status: agreed',
  '申请状态:处理中': 'Application status: processing',
  '申请状态:已拒绝': 'Application status: declined',
  '成员文件存证': 'File registration from member',
  '文件存证': 'File registration',
  '所属项目:': 'Belonging project: ',
  '登记人:': 'Registrant: ',
  '消耗积分': 'Used credits',
  '充值平台:支付宝': 'Charging platform: Alipay',
  '充值金额': 'Charging amount',
  '积分充值': 'Charging',
  '充值账号:': 'Charging account: ',
  '成员充值': 'Charge to member account',
  '用户名称:': 'Member name: ',
  '被充值账号:': 'Charging to account: ',
};

let settings = {
  '基本资料': 'Basic profile',
  '手机': 'Phone',
  '修改': 'Change',
  '修改手机号': 'Change the phone number',
  '新手机号': 'New phone',
  '请输入新的手机号': 'Input the new phone number',
  '你的手机号已成修改完成,当前绑定手机号是:': 'The phone number has been changed successfully. New phone number is:',
  '修改姓名': 'Change the name',
  '请输入用户姓名!': 'Input your name!',
  '验证旧手机': 'Verify the old phone number',
  '更改手机': '更改手机',
  '完成': 'Finish',
  '下一步': 'Next',
  '请输入发送至旧手机的验证码': 'Input the verification code sent to the old phone',
  '请输入正确的手机号码': 'Incorrect phone number',
  '号码与现在号码相同': 'The new phone number can not be the same as the old one',
  '请输入发送至新手机的验证码': 'Please input the verification code',
  '旧密码': 'Old password',
  '请输入旧密码': 'Input the old password',
  '新密码': 'New password',
  '请输入新密码': 'Input the new password',
  '确认新密码': 'Confirm the new password',
  '请再次输入新密码': 'Repeat the new password',
  '旧密码不正确': '旧密码不正确',
  '新密码不能小于7位': 'Password should be longer than 7 letters',
  '旧密码和新密码不能相同': 'New password should not be the same as the old one',
  '两次输入密码不一致': 'Two passwords are different',
  '个人中心': 'Profile',
  '个人信息': 'Personal data',
  '修改密码': 'Change password',
  '不能修改只读账户信息': 'You cannot edit your profile as it is managed by an Active Directory service.'
};

let globalTexts = {
  '确定': 'OK',
  '创建者': 'Owner',
  '文件名': 'File name',
  '类型': 'Type',
  '登记时间': 'Registered at',
  '登记时间:{ time }': 'Registered at: { time }',
  '点击查看': 'View details',
  '已复制': 'Copied',
  '创建时间': 'Created at',
  '创建': 'Create',
  '更新时间': 'Updated at',
  '数字财产DNA': 'Digital asset DNA',
  '过期时间': 'Expires at',
  '操作': '操作',
  '验证码': 'Verification code',
  '请输入手机验证码': 'Input verification code',
  '发送验证码': 'Send code',
  '取消': 'Cancel',
  '请输入正确的验证码': 'Incorrect verification code',
  '创建项目': 'Create project',
  '存证中': 'Registering',
  '请输入手机号': 'Please input the phone number',
  '请输入用户名': 'Please input username',
  '请输入用户名或手机号': "Username/Phone number",
  '密码必须包含数字和字母': 'Password must contain both letters and digits',
  '密码不正确': 'Incorrect password',
  '项目名称': 'Project name',
  '姓名': 'Name',
};

export default {
  ...validationCode,
  ...sidebars,
  ...navbarTexts,
  ...login,
  ...verifyFileTexts,
  ...auth,
  ...errorMessage,
  ...files,
  ...assets,
  ...vault,
  ...proofs,
  ...copyRights,
  ...credits,
  ...settings,
  ...enterprises,
  ...application,
  ...home,
  ...globalTexts,
  xxx: 'xxx',
};
