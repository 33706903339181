<template>
  <div class="mobile-page">
    <a-space direction="vertical" style="width: 100%; background: white" align="center" :size="30">
      <div style="height: 140px"></div>
      <img src="./images/yinhao.png" style="width: 68px; height: 42px" />
      <a-space direction="vertical" align="center">
        <a-typography-text style="font-size: 24px">成为合作伙伴，</a-typography-text>
        <a-typography-text style="font-size: 24px">探索全新领域，拓展服务边界</a-typography-text>
      </a-space>
      <div style="position: relative">
        <img src="./images/mobile-bg.png" style="width: 100%" />
        <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0">
          <a-space direction="vertical" align="center" style="width: 100%">
            <a-typography-title style="font-size: 40px">共同打造数字资产</a-typography-title>
            <a-typography-title style="font-size: 40px">全新生态</a-typography-title>
            <div style="height: 80px"></div>
            <img
              class="join-button"
              src="./images/join.png"
              style="width: 170px; height: 50px"
              @click="joinModalVisible = true"
            />
          </a-space>
          <join-modal v-if="joinModalVisible" @reset="joinModalVisible = false"></join-modal>
        </div>
        <div style="height: 100px"></div>
      </div>
    </a-space>
  </div>
</template>

<script setup>
  import JoinModal from './join-modal';
  import { ref } from 'vue';

  const joinModalVisible = ref(false);
</script>

<style scoped>
  .mobile-page {
    position: relative;
    overflow: hidden;
    background: #f7f7f7;
  }
</style>
