<template>
  <div class="front-page">
    <div class="blue-bg">
      <vue-particles
        style="width: 100%; height: 100vh"
        color="#dedede"
        :particle-size="8"
        :particles-number="100"
        :lines-width="5"
        :move-speed="4"
      ></vue-particles>
      <div style="position: absolute; width: 100%; top: 0; left: 0">
        <navbar class="navbar"></navbar>
        <mobile-navbar class="mobile-navbar"></mobile-navbar>
      </div>
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)">
        <div class="web-top-container" style="width: 100%; position: relative">
          <a-row class="number-container" justify="center" align="middle">
            <a-space direction="vertical" align="center" size="large">
              <a-typography-title style="text-align: center; color: white; font-size: 52px">
                数字资产生态已覆盖企业
              </a-typography-title>
              <a-space align="end">
                <div v-for="n in partnerTotal.length" :key="`web-${n}`" class="number-box">
                  {{ partnerTotal[n - 1] }}
                </div>
                <a-typography-text style="color: white; font-size: 30px">家</a-typography-text>
              </a-space>
            </a-space>
          </a-row>
        </div>
        <div class="mobile-top-container" style="width: 100%; position: relative">
          <a-row class="number-container" justify="center" align="middle">
            <a-space direction="vertical" align="center" size="large">
              <a-space direction="vertical" align="center">
                <a-typography-title
                  style="text-align: center; color: white; font-size: 40px; width: 340px"
                >
                  数字资产生态
                </a-typography-title>
                <a-typography-title
                  style="text-align: center; color: white; font-size: 40px; width: 340px"
                >
                  已覆盖企业
                </a-typography-title>
              </a-space>
              <a-space :size="4">
                <div v-for="n in partnerTotal.length" :key="`mobile-${n}`" class="number-box">
                  {{ partnerTotal[n - 1] }}
                </div>
                <div style="position: absolute; bottom: 0; right: 12px">
                  <a-typography-text style="color: white; font-size: 18px">家</a-typography-text>
                </div>
              </a-space>
            </a-space>
          </a-row>
        </div>
      </div>
    </div>
    <web class="web" />
    <middle class="middle" />
    <mobile class="mobile" />
    <web-footer class="web-footer" />
    <mobile-footer class="mobile-footer" />
  </div>
</template>

<script>
  export default {
    beforeRouteEnter(to, from, next) {
      if (config.corporationCompany) {
        next();
      } else {
        next({ name: from.name });
      }
    },
  };
</script>

<script setup>
  import Navbar from '@/views/index/front-navbar/web';
  import MobileNavbar from '@/views/index/front-navbar/mobile';
  import Web from '@/views/index/partner/web';
  import Mobile from '@/views/index/partner/mobile';
  import Middle from '@/views/index/partner/middleWidth';
  import WebFooter from '@/views/index/front-xian/front-footer/web';
  import MobileFooter from '@/views/index/front-xian/front-footer/mobile';
  import config from '/config';
  import PartnerAPI from '@/api/v2/partner';
  import { onMounted, ref } from 'vue';

  const partnerTotal = ref('');

  onMounted(() => {
    PartnerAPI.getTotal().then((res) => {
      partnerTotal.value = res.total.toString();
    });
  });
</script>

<style scoped>
  .front-page {
    position: relative;
  }
  .navbar {
    display: flex;
  }
  .mobile-navbar {
    display: none;
  }
  .web-top-container {
    display: block;
  }
  .mobile-top-container {
    display: none;
  }
  .web-footer {
    display: block;
  }
  .mobile-footer {
    display: none;
  }
  .blue-bg {
    background: linear-gradient(180deg, #534ff4 0%, #2723cf 100%);
    position: relative;
    height: 100vh;
  }
  .number-box {
    padding-top: 5px;
    width: 80px;
    height: 90px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 200px 0px rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 80px;
    font-weight: bold;
  }
  .number-container {
    height: calc(100% - 120px);
  }
  .web {
    display: block;
  }
  .middle {
    display: none;
  }
  .mobile {
    display: none;
  }
  @media only screen and (max-width: 1200px) {
    .navbar {
      display: none;
    }
    .mobile-navbar {
      display: flex;
    }
  }
  @media only screen and (max-width: 896px) {
    .web {
      display: none;
    }
    .middle {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 700px) {
    .number-box {
      padding-top: 3px;
      width: 64px;
      height: 72px;
      border-radius: 8px;
      font-size: 72px;
    }
    .web-top-container {
      display: none;
    }
    .mobile-top-container {
      display: block;
    }
    .web-footer {
      display: none;
    }
    .mobile-footer {
      display: block;
    }
    .web {
      display: none;
    }
    .middle {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
</style>
