const SET_ASSET_PAGE = 'SET_ASSET_PAGE';
const SET_VAULT_PAGE = 'SET_VAULT_PAGE';
const SET_COPYRIGHT_PAGE = 'SET_COPYRIGHT_PAGE';
const SET_COMPANY_PAGE = 'SET_COMPANY_PAGE';
const SET_APP_PAGE = 'SET_APP_PAGE';
const SET_SETTING_PAGE = 'SET_SETTING_PAGE';
const SET_HOME_PAGE = 'SET_HOME_PAGE';
const SET_PAGE_INDEX = 'SET_PAGE_INDEX';

const state = {
  sidebarActiveIndex: 2,
};

const mutations = {
  [SET_ASSET_PAGE](state) {
    state.sidebarActiveIndex = 1;
  },
  [SET_VAULT_PAGE](state) {
    state.sidebarActiveIndex = 2;
  },
  [SET_COPYRIGHT_PAGE](state) {
    state.sidebarActiveIndex = 4;
  },
  [SET_COMPANY_PAGE](state) {
    state.sidebarActiveIndex = 5;
  },
  [SET_APP_PAGE](state) {
    state.sidebarActiveIndex = 6;
  },
  [SET_SETTING_PAGE](state) {
    state.sidebarActiveIndex = 7;
  },
  [SET_HOME_PAGE](state) {
    state.sidebarActiveIndex = 99;
  },
  [SET_PAGE_INDEX](state, payload) {
    Object.assign(state, payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
