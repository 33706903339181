<template>
  <div style="width: 100%; height: 100%; padding: 50px; font-size: 14px">
    <div class="dark-bg-text" style="width: 100%">
      <h1>{{ webTitle }}隐私政策</h1>
      <p>
        上海因问科技有限公司（以下简称“我们”）作为{{ webTitle }}（以下简称：平台）的运营者，
        我们非常重视平台用户的隐私及个人信息保护，我们将严格遵守法律法规的规定，保护您的隐私和个人信息安全。
        我们制定《{{ webTitle }}隐私政策》（以下简称“本隐私政策”）旨在向您说明和提示， 您在使用{{
          webTitle
        }}的产品或服务时，我们如何收集、使用、保存、管理、共享和保护您的个人信息，
        以及您如何查询、访问、更新、删除和撤回授权这些信息的方式。
      </p>
      <p>
        基于上述理由，我们鼓励您在使用{{ webTitle }}的产品或（和）服务前仔细阅读本隐私政策，
        您应在充分理解并同意后使用我们的产品或（和）服务。如您使用或在我们更新本隐私政策后继续使用我们的产品或（和）服务，
        即视为您完全同意并接受本隐私政策（含更新版本），否则您应立即停止使用。
      </p>
      <p>本隐私政策会帮助您了解以下内容：</p>
      <p><strong>1、定义和适用范围</strong></p>
      <p><strong>2、我们如何收集和使用您的信息</strong></p>
      <p><strong>3、我们如何使用&nbsp;Cookie及同类技术</strong></p>
      <p><strong>4、我们如何共享、转让和公开披露您的个人信息</strong></p>
      <p><strong>5、我们会如何存储和保护您的个人信息</strong></p>
      <p><strong>6、您如何管理您的个人信息</strong></p>
      <p><strong>7、未成年人条款</strong></p>
      <p><strong>8、隐私政策的修订和通知</strong></p>
      <p><strong>9、联系我们</strong></p>
      <br />
      <p><strong>1、定义和适用范围</strong></p>
      <br />
      <p>1.1定义</p>
      <p>
        个人信息：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别自然人个人身份的各种信息，
        包括但不限于自然人的姓名、出生日期、身份证件号码、个人生物识别信息、住址、电话号码等。
      </p>
      <br />
      <p>1.2适用范围</p>
      <p>
        本政策适用于{{ webTitle }}向您提供的各项产品和服务，
        具体包括数字知识产权服务平台业务系统、数字知识产权登记插件(如：Word插件、Excel插件、AutoCAD插件)等。
        除本政策说明的相关信息收集使用活动外，本政策不适用于数字知识产权保护用户及其他第三方向您提供的其他服务，
        其他服务适用其向您另行说明的隐私政策等个人信息收集使用规则。
      </p>
      <br />
      <p><strong>2、我们如何收集和使用您的信息</strong></p>
      <br />
      <p>2.1帮助您成为我们的会员及账户管理</p>
      <p>
        我们基于{{ webTitle }}账户向您提供基础会员服务。为了注册{{
          webTitle
        }}账户您需要向我们提供您的手机号码、 拟使用的会员名和密码。
        如果您仅需使用浏览服务，您不需注册成为我们的会员及提供上述信息。
      </p>
      <p>
        对于需要通过{{
          webTitle
        }}账户才能使用的服务，我们可能会根据您提供的上述信息校验您的会员身份，确保我们是在为您本人提供服务。
      </p>
      <br />
      <p>2.2向您展示产品或服务信息</p>
      <p>
        为向您提供更契合您需求的服务信息，我们会收集和使用您在访问或使用{{
          webTitle
        }}网站时的搜素和上传记录以及其他取得您授权的信息，
        为满足您的多元需求，我们会引入多样化推荐技术，或向您推荐新的产品，或用于我们将来所开展的新业务中。
      </p>
      <br />
      <p>2.3为您提供上传功能</p>
      <p>
        在您使用{{ webTitle }}的过程中，您可通过我们提供的功能组件发布文件和文稿内容。
        在您使用上述功能的过程中，您可设置您上传内容的上传方式和上传类型
      </p>
      <br />
      <p>2.4为您提供安全保障</p>
      <p>
        为提高您使用我们提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，
        更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，
        更准确地识别违反法律法规或相关协议、规则的情况，我们可能使用或整合您的会员信息或取得您授权或依据法律共享的信息，
        来综合判断您账户存在的风险，进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
      </p>
      <br />
      <p>2.5收集和使用个人信息的其他规则</p>
      <p>
        2.5.1若您提供的信息中含有其他用户的个人信息，在向{{
          webTitle
        }}提供这些个人信息之前，您需确保您已经取得合法的授权。
      </p>
      <p>
        2.5.2若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。
      </p>
      <p>2.5.3征得授权同意的例外</p>
      <p>根据相关法律法规规定，我们在以下情况下收集、使用您的个人信息无需您的授权同意：</p>
      <p>(1)与我们履行法律、法规规定的义务相关的；</p>
      <p>(2)与国家安全、国防安全直接相关的；</p>
      <p>(3)与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>(4)与犯罪侦查、起诉、审判和判决执行等司法或行政执法直接相关的；</p>
      <p>(5)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p>(6)您自行向社会公众公开的个人信息；</p>
      <p>(7)从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p>(8)根据与您签订和履行相关协议或其他书面文件所必需的；</p>
      <p>
        (9)用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；
      </p>
      <p>(10)法律法规规定的其他情形。</p>
      <p>
        请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，
        或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善{{
          webTitle
        }}的内容和布局，
        为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），
        则此类处理后数据的使用无需另行向您通知并征得您的同意。
      </p>
      <br />
      <p>
        2.6如我们停止运营{{ webTitle }}的服务，我们将及时停止继续收集您个人信息的活动，
        将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。
        涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。
      </p>
      <br />
      <p><strong>3.我们如何使用&nbsp;Cookie及同类技术</strong></p>
      <br />
      <p>3.1Cookie的使用</p>
      <p>
        为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，
        我们会在您的计算机或移动设备上存储Cookie、Flash
        Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、
        站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于&nbsp;Cookie，网站能够存储您的偏好的商品数据。
      </p>
      <p>
        如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。
        有关详情，请参见&nbsp;AboutCookies.org。但如果您执行本条所述操作，在某些情况下可能会影响您安全访问我们的网站，
        且可能需要在您每一次访问我们的网站时更改用户设置。
      </p>
      <br />
      <p><strong>4、我们如何共享、转让和公开披露您的个人信息</strong></p>
      <br />
      <p>4.1共享</p>
      <p>
        4.1.1我们不会与数字知识产权保护服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：
      </p>
      <p>
        (1)在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      </p>
      <p>(2)在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
      <p>
        (3)与关联公司间共享：为便于我们基于{{ webTitle }}账户向您提供产品和服务，
        活动参与、识别会员账户异常，保护{{
          webTitle
        }}关联公司或其他用户或公众的人身财产安全免遭侵害，
        您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束，
        如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
      </p>
      <p>
        (4)随着我们业务的持续发展，当发生合并、收购、资产转让等交易导致向第三方共享您的个人信息时，
        我们将通过推送通知、公告等形式告知您相关情形，按照法律法规及不低于本政策所要求的标准继续保护或要求新的管理者继续保护您的个人信息。
      </p>
      <br />
      <p>4.2转让</p>
      <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>（1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
      <p>
        （2）在涉及合并、收购或破产清算时，&nbsp;如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
      </p>
      <br />
      <p>4.3公开披露</p>
      <p>
        我们仅会在以下情况下，且采取符合法律和业界标准的安全防护措施的前提下，才会公开披露您的个人信息。
      </p>
      <p>（1）获得您的明确同意；</p>
      <p>
        （2）基于法律法规、法律程序、诉讼或政府主管部门强制性要求。&nbsp;但我们保证，在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件。
      </p>
      <br />
      <p><strong>5、我们会如何存储和保护您的个人信息</strong></p>
      <br />
      <p>5.1信息的存储</p>
      <p>5.1.1存储信息的地点：我们遵守法律法规的规定，将境内收集的用户个人信息存储于境内。</p>
      <p>
        5.1.2存储信息的期限：一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息。但在下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：
      </p>
      <p>为遵守适用的法律法规等有关规定；</p>
      <p>（1）为遵守法院判决、裁定或其他法律程序的规定；</p>
      <p>（2）为遵守相关政府机关或法定授权组织的要求；</p>
      <p>（3）我们有理由确信需要遵守法律法规等有关规定；</p>
      <p>
        （4）为执行相关服务协议或本政策、维护社会公共利益，为保护们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。
      </p>
      <p>
        当我们的产品或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。
      </p>
      <br />
      <p>5.2信息的保护</p>
      <p>我们为您的信息提供相应的安全保障，以防止信息的丢失、不当使用、未经授权访问或披露。</p>
      <p>（1）我们严格遵守法律法规保护用户的通信秘密。</p>
      <p>（2）我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。</p>
      <p>（3）我们建立专门的管理制度、流程和组织确保信息安全。</p>
      <p>
        （4）若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
      </p>
      <br />
      <p><strong>6、您如何管理您的个人信息</strong></p>
      <br />
      <p>6.1个人信息的访问和更正</p>
      <p>6.1.1您可通过以下途径访问或更正您的个人信息：</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;账号信息：登录账号访问“个人中心”页面查看或修改账号基本信息；
      </p>
      <p>
        6.1.2为了提升用户体验和交易安全，您的部分个人信息无法提供访问或更正服务，对此您表示理解和同意。
      </p>
      <br />
      <p>6.2个人信息的删除</p>
      <p>
        6.2.1您可通过6.1.1条列明的方式删除您的部分个人信息，此外您还可通过下列情形向我们提出删除个人信息的请求：
      </p>
      <p>（1）我们处理个人信息的行为违反法律法规；</p>
      <p>（2）我们收集、使用您的个人信息，未征得您的同意；</p>
      <p>（3）我们处理个人信息的行为或方式严重违反了本隐私政策的约定；</p>
      <p>（4）我们停止运营和服务。</p>
      <br />
      <p>6.3授权的变更和撤回</p>
      <p>
        您可以随时通过信息删除、关闭设备相应功能、更改软件设置、注销账号或与我们联系来变更您授权我们收集、
        使用个人信息的范围或撤回您的授权。
        您变更授权范围或撤回授权后我们将无法为您提供相对应的服务，
        也不再处理相应的个人信息。但您的上述决定，并不会影响此前基于您的授权而进行的个人信息处理。
      </p>
      <br />
      <p><strong>7、未成年人条款</strong></p>
      <p>7.1我们非常重视对未成年人个人信息的保护。</p>
      <p>
        根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用{{ webTitle }}的服务前，
        应事先取得您的监护人的同意；若您是未成年人的监护人，当您在帮助未成年人完成产品或服务的注册、使用前，
        应当仔细阅读本政策，决定是否同意本政策并帮助未成年人进行注册、使用，以便未成年人能使用我们提供的产品或服务。
      </p>
      <br />
      <p>
        7.2我们将会严格依照法律法规的规定履行对未成年人信息的保护义务，对于经监护人同意而收集的未成年人信息，
        我们只会在法律法规允许的范围内或基于保护未成年人利益所必要目的而使用该等信息。
      </p>
      <br />
      <p>
        7.3如我们在事先未获得可证实的监护人同意的情况下收集了未成年人的个人信息，我们将在知晓后尽快予以删除。
      </p>
      <br />
      <p><strong>8、隐私政策的修订和通知</strong></p>
      <p>8.1我们可能适时修订本政策内容。</p>
      <p>
        （1）如该等变更会导致您在本政策项下权利的实质减少，我们将在变更生效前，通过不同渠道向您发送变更通知，包括但不限于网站公示、私信通知等方式；
      </p>
      <p>（2）在该种情况下，若您继续使用我们的服务，即表示接受我们对本政策相关条款所做的修改；</p>
      <p>（3）若您不同意修改后的隐私政策，您有权并应立即停止使用{{ webTitle }}的服务。</p>
      <br />
      <br />
      <p><strong>9、联系我们</strong></p>
      <p>
        如您对本政策或其他相关事宜有疑问，请通过邮箱hi@yuanben.org与我们联系，我们将在15天内回复您的请求。
      </p>
      <br />
    </div>
  </div>
</template>

<script setup>
  import config from '/config';
  import { computed } from 'vue';
  const webTitle = computed(() => config.webTitle);
</script>

<style scoped>
  p {
    table-layout: fixed;
    word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
  }
</style>
