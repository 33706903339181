import { ref } from 'vue';

export function frontConfig() {
  const mainTitle = ref('数字资产, 您的另外一半资产.');
  const mobileMainTitleHead = ref('数字资产');
  const mobileMainTitleTail = ref('您的另外一半资产');
  const subTitle = ref('从创造、使用、保护到融资全链条服务');
  const advantages = ref([
    {
      title: '碑林环大学创新产业带',
      description:
        '由碑林区与西安市科技局、驻区10所高校共同启动建设，旨在深化统筹科技资源改革，通过机制模式创新，推进大众创业、万众创新，促进科技成果转化和相关产业发展，建设产城融合的智力密集型园区。',
      image: require('./images/beilin.png'),
    },
    {
      title: '中国人保',
      description:
        '亚洲第一、国内最大的资产保险公司，世界500强企业上榜的中国公司中排名第23位，连续多年被国际权威评级机构穆迪公司授予国内最高信用评级（Aa3级）。已连续7年被评为“中国年度最佳雇主”十大企业。',
      image: require('./images/zhongguorenbao.png'),
    },
    {
      title: '因问科技',
      description:
        '企业数字资产管理技术与服务提供商，基于大数据、区块链、隐私计算等数字化技术和多年行业经验，帮助企业运营管理知识资产和数据资产，助力企业实现数字化转型，提升企业的数字价值。',
      image: require('./images/yinwenkeji.png'),
    },
  ]);

  const solutions = ref([
    {
      title: '科研院所及大学',
      description: '保护科技成果，促进科技成果转化',
      image: require('@/views/index/front/images/pic_3.png'),
    },
    {
      title: '产业园区',
      description: '构建创新生态，推动产业园区可持续发展',
      image: require('@/views/index/front/images/pic_2.png'),
    },
    {
      title: '核心企业',
      description: '保护核心资产，示范引领行业创新',
      image: require('@/views/index/front/images/pic_1.png'),
    },
    {
      title: '中小企业',
      description: '识别数字资产，提升企业估值',
      image: require('@/views/index/front/images/pic_4.png'),
    },
  ]);
  const insurances = ref([
    {
      image: require('@/views/index/front-xian/front/images/zichanpinggu.png'),
      title: '数字资产评估',
      description:
        '数字资产的价值受多种不同因素影响，包括技术因素、数据容量、数据价值密度、数据应用的商业模式和其他因素等， 这些因素随时间推移不断变化，导致数字资产价值具备易变性，' +
        '用传统方式在评估数字资产时虽有一定的适用性，但又存在种种缺陷。 中心基于大数据和神经网络模型， 对数字资产进行多维度建模，结合保险科技建立全新的数字资产评估体系。',
    },
    {
      image: require('@/views/index/front-xian/front/images/zichanbaoxian.png'),
      title: '数字资产保险',
      description:
        '运用数字科技，把不易识别，不好评估的无形资产量化为可保的的数字资产，把原来用传统方式评估，不好识别的风险，通过保险科技量化为可保风险。用数字保险赋能数字经济，充分发挥保险的社会稳定器和经济助推器作用',
    },
  ]);
  const assetTexts = ref([
    '数字资产是以数字形态存在的无形资产。随着数字经济的快速发展，无形资产在企业总资产中比重逐渐增加，标普500强公司无形资产占比从1975年的17%提升至近年来的90%。可以说，今天已经没有不存在数字资产的企业，只有看不见自己数字资产的企业。',
    '数字资产保险中心通过隐私计算、人工智能和区块链技术识别和保护企业的数字资产，促进数据的创造和流通，推动企业无形资产价值的全面释放，提升社会的创新能力。',
  ]);
  const needProtecteds = ref([
    {
      title: '创意作品设计',
      description: '视听作品、纺织品设计、软件代码、工业设计草图等',
      image: require('@/views/index/front/images/chuangyizuopinsheji.png'),
    },
    {
      title: '商业秘密和专门知识',
      description: '公式、工艺、流程、汇编/列表、业务计划、配方等。',
      image: require('@/views/index/front/images/shangyemimi.png'),
    },
    {
      title: '研究与数据',
      description: '数据集、研究结果、实验室记录等。',
      image: require('@/views/index/front/images/yanjiuyushuju.png'),
    },
    {
      title: '其他数字资产',
      description: '协议、合同、技术或数据参考文件的版本历史。',
      image: require('@/views/index/front/images/qita.png'),
    },
  ]);

  const foundationServices = ref({
    title: '数字资产算力中心',
    descriptions: '企业数字资产计算和管理基础设施',
    items: [
      {
        description: '分布式、大规模、低碳',
        image: require('./images/jizuo_3.png'),
      },
      {
        description: '高性能软硬一体架构',
        image: require('./images/jizuo_2.png'),
      },
      {
        description: '隐私计算,保护敏感数据资产',
        image: require('./images/jizuo_1.png'),
      },
      {
        description: '弹性算力、低成本、高效率',
        image: require('./images/jizuo_4.png'),
      },
    ],
  });

  const bottomTexts = ref([
    {
      title: '数字资产保险创新实验室',
      text: '实验室致力于推动企业识别并充分利用其在全面数字化的过程中所产生的新型数字资产。研究领域包括保险科技、人工智能在数字资产上的应用，研究方向包括数字资产认定技术，数字资产评估模型，以及数字资产保险模式创新等。',
    },
    {
      title: '数字资产安全实验室',
      text: '实验室围绕数字资产的全面应用，推动相关技术的发展和落地。研究领域包括隐私计算、区块链和零知识证明技术，研究方向包括隐私保护数据交易中的性能和安全问题，以及隐私计算场景下的数据审计问题等。',
    },
  ]);

  return {
    mainTitle,
    mobileMainTitleHead,
    mobileMainTitleTail,
    subTitle,
    advantages,
    solutions,
    insurances,
    assetTexts,
    needProtecteds,
    bottomTexts,
    foundationServices,
  };
}
