<template>
  <div>
    <img alt="login" class="bg-image" :src="require('@/assets/login-bg.png')" />
    <div class="blank"></div>
    <div class="reset-container">
      <a-page-header :title="i18nText('重置密码')" @back="() => router.go(-1)" />
      <a-form
        ref="resetFormRef"
        :model="resetForm"
        :rules="passRules"
        :wrapper-col="{ span: 24 }"
        @submit.prevent
        @finish="validateForm"
        @validate="handleValidate"
      >
        <a-form-item name="phonenumber">
          <a-input
            v-model:value="resetForm.phonenumber"
            :placeholder="i18nText('请输入手机号')"
            type="text"
            size="large"
            autocomplete="on"
            @keyup.enter="modifyLoginPass"
          ></a-input>
        </a-form-item>
        <a-form-item name="password">
          <a-input-password
            v-model:value="resetForm.password"
            size="large"
            :placeholder="i18nText('请输入新密码')"
            @keyup.enter="modifyLoginPass"
          ></a-input-password>
        </a-form-item>
        <a-form-item name="repeatPassword">
          <a-input-password
            v-model:value="resetForm.repeatPassword"
            size="large"
            :placeholder="i18nText('请再次输入新密码')"
            @keyup.enter="modifyLoginPass"
          ></a-input-password>
        </a-form-item>
        <a-form-item name="validation_code">
          <validation-code-input
            v-if="config.requirePhoneValidation"
            ref="validation_code"
            v-model:value="resetForm.validation_code"
            :placeholder="i18nText('请输入手机验证码')"
            name="validation_code"
            input-size="large"
            type="text"
            autocomplete="on"
            :validation-code-handler="requestValidationCode"
            :show-phonenumber="false"
            :phone-number="resetForm.phonenumber"
            :input-height="40"
            :input-border-radius="4"
          />
        </a-form-item>
        <a-form-item>
          <a-button class="change-button" type="primary" @click="modifyLoginPass">
            {{ $t('确认') }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
  import { reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import ValidationCodeAPI from '@/api/v1/validationCode';
  import ValidationCodeInput from '@/views/components/ant_code_validate.vue';
  import UserAPI from '@/api/v1/users';
  import ErrorMessage from '@/model/errorMessage';
  import { i18nText } from '@/lang';
  import config from '/config';

  const router = useRouter();

  // form
  const resetFormRef = ref();
  const resetForm = reactive({
    phonenumber: '',
    password: '',
    repeatPassword: '',
    repeatPasswordType: 'password',
    passwordType: 'password',
    validation_code: '',
  });

  let validatePassword = async (_rule, value) => {
    // 11111qqqqQ!
    let reg = new RegExp(/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[^A-Za-z0-9].*).{8,}$/);
    if (!reg.test(value)) {
      return Promise.reject(i18nText('要求：8位以上包含字符、大小写字母、数字'));
    } else {
      return Promise.resolve();
    }
  };

  let validateRepeatPassword = async (_rule, value) => {
    if (value !== resetForm.password) {
      return Promise.reject(i18nText('两次密码输入不一致'));
    } else {
      return Promise.resolve();
    }
  };

  let validatePhoneNumber = async (_rule, value) => {
    if (!/^\d{11}$/.test(value)) {
      return Promise.reject(i18nText('请输入正确的手机号码'));
    } else {
      return Promise.resolve();
    }
  };

  let validateCode = async (_rule, value) => {
    if (!value) {
      return Promise.reject(i18nText('请输入手机验证码'));
    } else {
      return Promise.resolve();
    }
  };

  const errorResponse = reactive({
    error: {},
  });

  let errorResponseValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(errorResponse.error);
    if (field == '') {
      return Promise.resolve();
    }
    let transMessage = ErrorMessage.getMessageFromError(errorResponse.error);
    if (_rule.field === field) {
      return Promise.reject(transMessage);
    }
    return Promise.resolve();
  };

  const passRules = {
    phonenumber: [
      { required: true, trigger: 'submit', validator: validatePhoneNumber },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
    password: [
      { required: true, trigger: 'submit', validator: validatePassword },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
    validation_code: config.requirePhoneValidation
      ? [
          { required: true, trigger: 'submit', validator: validateCode },
          { required: true, trigger: 'submit', validator: errorResponseValidate },
        ]
      : [],
    repeatPassword: [
      { required: true, trigger: 'submit', validator: validateRepeatPassword },
      { required: true, trigger: 'submit', validator: errorResponseValidate },
    ],
  };

  const handleValidate = () => {
    errorResponse.error = {};
  };

  const loading = ref(false);
  const validateForm = () => {
    loading.value = true;
    UserAPI.resetLoginPass(resetForm.phonenumber, resetForm.password, resetForm.validation_code)
      .then(() => {
        router.push({ name: 'login', params: { active: 'login' } });
      })
      .catch((error) => {
        errorResponse.error = error;
        resetFormRef.value.validate();
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const modifyLoginPass = () => {
    resetFormRef.value.validate().then(() => {
      validateForm();
    });
  };

  const requestValidationCode = async () => {
    try {
      await resetFormRef.value.validateFields('phonenumber');
      return ValidationCodeAPI.userValidationCode(11, resetForm.phonenumber)
        .then(() => {
          Promise.resolve();
        })
        .catch((err) => {
          errorResponse.error = err;
          resetFormRef.value.validateFields('validation_code');
          Promise.reject(err);
        });
    } catch (errorInfo) {
      return Promise.reject(errorInfo);
    }
  };
</script>

<style scoped>
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .blank {
    height: 104px;
  }

  .reset-container {
    width: 440px;
    background: white;

    margin: auto;
    padding: 20px 32px 90px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .change-button {
    width: 100%;
    height: 40px;
    font-size: 18px;
    border-radius: 4px;
    font-weight: 700;
  }
</style>
