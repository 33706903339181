<template>
  <div class="verify-page">
    <a-layout-header style="background-color: #143654">
      <navbar :show-search="false" :show-verify="false" :show-user="false" />
    </a-layout-header>
    <div class="header-title">{{ $t(config.fileVerifyCenterText) }}</div>
    <div class="content-container">
      <div v-if="!hasResult" class="step">
        <img alt="assets" class="bottom-image" :src="require('@/assets/sbg.png')" />
        <img alt="verify" class="verify-image" :src="require('@/assets/yan_icon.svg')" />
        <div class="step-verify">
          <a-form layout="vertical" :model="verifyForm" :rules="verifyRules" @finish="makeVerify">
            <a-form-item
              class="select-file-item"
              name="requestFileName"
              :label="i18nText('选择原始文件')"
            >
              <a-input-group compact>
                <a-input
                  v-model:value="verifyForm.requestFileName"
                  size="large"
                  :placeholder="i18nText('此处选择原始文件')"
                  disabled
                  style="
                    width: calc(100% - 60px);
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                  "
                >
                  {{ verifyForm.requestFileName }}
                </a-input>
                <a-upload :max-count="1" :show-upload-list="false" @change="requestFileSelected">
                  <a-button class="select-button">{{ $t('选择') }}</a-button>
                </a-upload>
              </a-input-group>
            </a-form-item>
            <a-form-item
              class="select-file-item"
              name="verifyFileName"
              :label="i18nText('数字产权证明')"
            >
              <a-input-group compact>
                <a-input
                  v-model:value="verifyForm.verifyFileName"
                  size="large"
                  :placeholder="i18nText('此处选择后缀名为“pdf”的产权证明文件')"
                  disabled
                  style="
                    width: calc(100% - 60px);
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                  "
                >
                  {{ verifyForm.verifyFileName }}
                </a-input>
                <a-upload :max-count="1" :show-upload-list="false" @change="verifyFileSelected">
                  <a-button class="select-button" size="large">{{ $t('选择') }}</a-button>
                </a-upload>
              </a-input-group>
            </a-form-item>
            <a-form-item :wrapper-col="{ offset: 0, span: 24 }">
              <a-button type="primary" html-type="submit" class="verify-button">
                {{ $t('验证') }}
              </a-button>
            </a-form-item>
          </a-form>
          <div class="bottom-text">
            {{
              $t(
                '数字产权证明可证明数字文件（数据）的登记时间、数据完整性和数据登记者身份。通过数字产权验证的文件，证明原始文件从登记时刻起已经存在、内容完整未被篡改，并且由产权证明文件提供者进行登记。',
              )
            }}
          </div>
        </div>
      </div>
      <div v-if="hasResult" class="verify-result">
        <img
          v-if="verifyResultError"
          class="result-image"
          :src="require('@/assets/verify_e.svg')"
        />
        <img v-else class="result-image" :src="require('@/assets/verify_p.svg')" />
        <div v-if="verifyResultData" class="result-status">{{ $t('验证成功') }}</div>
        <div v-if="verifyResultError" class="result-error-status">{{ $t('验证失败') }}</div>
        <div v-if="verifyResultError" class="result-error-text">
          {{ $t('该文件未通过验证，可能存在真实性风险！') }}
        </div>
        <div v-if="verifyResultError" class="result-error-text">{{ verifyError }}</div>
        <div class="result-blank"></div>
        <div v-if="verifyResultData" class="result-bg">
          <div class="result-title medium-secondary-text first-result-title">
            {{ $t('文件数字指纹') }}
          </div>
          <div class="result-text medium-secondary-text">{{ resultFileHash }}</div>
          <div class="result-title medium-secondary-text">{{ $t('数字财产DNA') }}</div>
          <div class="result-text medium-secondary-text">{{ verifyResultData.asset_dna }}</div>
          <div class="result-title medium-secondary-text">{{ $t('登记时间') }}</div>
          <div class="result-text medium-secondary-text">{{ verifyResultData.registered_at }}</div>
          <div class="result-title medium-secondary-text">{{ $t('区块链地址') }}</div>
          <div class="result-text medium-secondary-text">{{ verifyResultData.block_address }}</div>
          <div class="result-title medium-secondary-text">{{ $t('企业名称') }}</div>
          <div class="result-text medium-secondary-text">
            {{ verifyResultData.enterprise_name }}
          </div>
          <div class="result-title medium-secondary-text">
            {{ $t('产权证明文件有效期（截止日期之前）') }}
          </div>
          <div class="result-text medium-secondary-text">{{ verifyResultData.expired_at }}</div>
        </div>
        <a-button type="primary" class="result-back-button" @click="back">
          {{ $t('返回') }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import Navbar from '@/views/navbar';
  import { computed, onMounted, ref } from 'vue';
  import ErrorMessage from '@/model/errorMessage';
  import AssetVerify from '@/api/v1-verify/assetverify';
  import { ApiPromise } from '@/api/api-promise';
  import { keyPairUtil } from '@/library/crypto/keypair';
  import UserModel from '@/model/user';
  import { i18nText } from '@/lang';
  import config from '/config';

  const resultFileHash = computed(() => {
    return verifyResultData.value &&
      verifyResultData.value.file_hash &&
      verifyResultData.value.file_hash.length > 0
      ? verifyResultData.value.file_hash
      : i18nText('无法获取');
  });
  const verifyError = computed(() => {
    if (verifyResultError.value) {
      let errorMessage = verifyResultError.value.response.data.message;
      let showMessage = ErrorMessage.getLocalMessage(errorMessage);
      return i18nText('错误提示:') + (showMessage ? showMessage : i18nText('验证文件错误'));
    } else {
      return null;
    }
  });

  let validateRequestFile = async (_rule, value) => {
    if (!value) {
      return Promise.reject(i18nText('请选择原始文件'));
    } else {
      return Promise.resolve();
    }
  };
  let validateVerifyFile = async (_rule, value) => {
    if (!value) {
      return Promise.reject(i18nText('请选择证明文件'));
    } else {
      return Promise.resolve();
    }
  };

  const step = ref(0);
  const hasResult = ref(false);
  const verifyForm = ref({});
  const verifyRules = {
    requestFileName: [{ required: true, trigger: 'all', validator: validateRequestFile }],
    verifyFileName: [{ required: true, trigger: 'all', validator: validateVerifyFile }],
  };
  const verifyResultData = ref({});
  const verifyResultError = ref({});

  const reset = () => {
    step.value = 0;
    hasResult.value = false;
    verifyResultData.value = null;
    verifyResultError.value = null;
  };
  const makeVerify = () => {
    AssetVerify.verify(
      verifyForm.value.requestFileHash,
      verifyForm.value.verifyFile,
      verifyForm.value.verifyFileName,
    )
      .then((res) => {
        hasResult.value = true;
        verifyResultData.value = res;
        verifyResultError.value = null;
      })
      .catch((error) => {
        let status = error.response.status;
        if (status === 500) {
          hasResult.value = true;
          verifyResultData.value = null;
          verifyResultError.value = error;
          return;
        }
        ApiPromise.defaultOnReject(error);
      });
  };
  const back = () => {
    reset();
  };
  const verifyFileSelected = async (file) => {
    verifyForm.value.verifyFile = file.file.originFileObj;
    verifyForm.value.verifyFileName = file.file.name;
    verifyForm.value.verifyFileHash = i18nText('正在计算...');
    verifyForm.value.verifyFileHash = await keyPairUtil.largesha256(file.file.originFileObj);
  };
  const requestFileSelected = async (file) => {
    verifyForm.value.requestFile = file.file.originFileObj;
    verifyForm.value.requestFileName = file.file.name;
    verifyForm.value.requestFileHash = i18nText('正在计算...');
    verifyForm.value.requestFileHash = await keyPairUtil.largesha256(file.file.originFileObj);
  };

  onMounted(() => {
    UserModel.getMyUserInfo().catch(() => {});
  });
</script>

<style lang="less" scoped>
  .verify-page {
    background: #f5f8fa;
    height: 100%;
    overflow: auto;
    background: url('@/assets/bg.png') center top no-repeat;
    background-size: 100% auto;
  }

  .header-title {
    text-align: center;
    margin: 20px auto 40px;
    font-size: 28px;
    font-weight: 500;
    color: #f6f9fa;
  }

  .content-container {
    margin: 0 auto 30px;
    width: 500px;
    flex-grow: 0;
    background: #f6f9fa;
    box-shadow: 0 0 12px 1px rgba(23, 76, 105, 0.28);
    border-radius: 10px;
    border: 1px solid #00000000;
  }

  .step {
    position: relative;
    margin: 0 auto;
    padding-bottom: 44px;
  }

  .step-verify {
    width: 380px;
    margin: 0 auto;
    position: relative;
  }

  .bottom-image {
    position: absolute;
    bottom: 0;
    right: 0;
    object-fit: cover;
  }

  .verify-image {
    padding: 36px 0 20px 0;
    margin: 0 auto;
    width: 80px;
    display: block;
    object-fit: cover;
  }
  .verify-button {
    font-size: 18px;
    width: 100%;
    height: 44px;
    background-color: #143654;
    border-radius: 5px;
  }
  .select-button {
    font-size: 14px;
    width: 60px;
    height: 41px;
    background-color: #143654;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .bottom-text {
    margin-top: 30px;
    font-size: 14px;
    line-height: 24px;
    color: #606266;
    padding: 18px 28px 22px 28px;
    background: rgba(227, 240, 244, 0.57);
    border: 1px solid #174c69;
  }

  .verify-result {
    margin: 80px auto 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .result-blank {
      height: 55px;
    }

    .result-bg {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 52px;
      padding: 32px 46px;
    }
  }
  .result-image {
    object-fit: cover;
  }
  .result-status {
    color: #67c23a;
  }
  .result-error-status {
    text-align: center;
    margin: 15px 5px 15px 5px;
    font-size: 20px;
    font-weight: 600;
  }

  .result-error-text {
    color: #797979;
    text-align: center;
    margin: 4px 5px;
  }
  .result-title {
    font-weight: bold;
    margin: 26px 0 10px 0;
  }
  .result-text {
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }
  .first-result-title {
    margin-top: 0;
  }
  .medium-secondary-text {
    color: #909399;
    font-size: 16px;
  }
  .result-back-button {
    width: 310px;
    height: 44px;
    background-color: #143654;
    border-radius: 5px;
  }
</style>
