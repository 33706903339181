<script>
  import { SmileOutlined } from '@ant-design/icons-vue';
  import { defineComponent } from 'vue';
  export default defineComponent({
    components: {
      SmileOutlined,
    },
  });
</script>

<script setup>
  import { reactive, ref } from 'vue';
  import PartnerAPI from '@/api/v2/partner';

  const visible = ref(true);
  const loading = ref(false);
  const formRef = ref();
  const formState = reactive({
    name: '',
    enterprise_name: '',
    phone: '',
    corporation: '',
  });

  let validateEmptyString = async (_rule, value) => {
    if (!value || value.length === 0) {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  };

  const rules = {
    enterprise_name: [
      {
        required: true,
        validator: validateEmptyString,
        trigger: 'blur',
        message: '请输入企业名称',
      },
    ],
    name: [
      {
        required: true,
        validator: validateEmptyString,
        trigger: 'blur',
        message: '请输入联系人姓名',
      },
    ],
    phone: [
      {
        required: true,
        validator: validateEmptyString,
        trigger: 'blur',
        message: '请输入手机号码',
      },
    ],
    corporation: [
      {
        required: true,
        validator: validateEmptyString,
        trigger: 'blur',
        message: '请输入合作方式',
      },
    ],
  };

  const emit = defineEmits(['reset']);

  const submit = () => {
    loading.value = true;
    formRef.value
      .validate()
      .then(() => {
        PartnerAPI.submitContracts(
          formState.name,
          formState.phone,
          formState.enterprise_name,
          formState.corporation,
        ).then(() => {
          visible.value = false;
          resultVisible.value = true;
        });
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const resultVisible = ref(false);

  const closeModal = () => {
    resultVisible.value = false;
    emit('reset');
  };

  const onCancel = () => {
    emit('reset');
  };
</script>

<template>
  <a-modal
    v-model:visible="visible"
    title="企业信息"
    width="31%"
    wrap-class-name="full-modal"
    :confirm-loading="loading"
    @cancel="onCancel"
  >
    <template #footer>
      <a-button key="submit" type="primary" :loading="loading" @click="submit">提交</a-button>
    </template>
    <a-form
      ref="formRef"
      :model="formState"
      :label-col="{ style: { width: '100px' } }"
      :rules="rules"
    >
      <a-form-item name="enterprise_name" label="企业名称">
        <a-input v-model:value="formState.enterprise_name" placeholder="请输入企业名称"></a-input>
      </a-form-item>
      <a-form-item name="name" label="联系人姓名">
        <a-input v-model:value="formState.name" placeholder="请输入联系人姓名"></a-input>
      </a-form-item>
      <a-form-item name="phone" label="手机号码">
        <a-input v-model:value="formState.phone" placeholder="请输入手机号码"></a-input>
      </a-form-item>
      <a-form-item name="corporation" label="合作方式">
        <a-textarea
          v-model:value="formState.corporation"
          :rows="4"
          placeholder="请输入合作方式"
        ></a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="resultVisible"
    width="31%"
    wrap-class-name="full-modal"
    :footer="null"
    @cancel="onCancel"
  >
    <a-result title="已收到您的申请，后续会有专员与您联系，请耐心等待">
      <template #icon>
        <smile-outlined />
      </template>
      <template #extra>
        <a-button key="close" @click="closeModal">关闭</a-button>
      </template>
    </a-result>
  </a-modal>
</template>
