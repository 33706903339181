const SET_DIGITAL_ACTIVE = 'SET_DIGITAL_ACTIVE';
const SET_CHANGEPASS_ACTIVE = 'SET_CHANGEPASS_ACTIVE';
const SET_INDEX_ACTIVE = 'SET_INDEX_ACTIVE';

const state = {
  activeIndex: ['info'],
};

const mutations = {
  [SET_DIGITAL_ACTIVE](state) {
    state.activeIndex = ['info'];
  },
  [SET_CHANGEPASS_ACTIVE](state) {
    state.activeIndex = ['pass'];
  },
  [SET_INDEX_ACTIVE](state, payload) {
    Object.assign(state, payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
