import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { ref } from 'vue';

export function routeTabs(defaultActive) {
  const route = useRoute();
  const router = useRouter();
  const activeKey = ref(route.params.active ? route.params.active : defaultActive);
  const change = (active) => {
    router.push({ name: route.name, params: { active } });
  };

  onBeforeRouteUpdate(async (to) => {
    activeKey.value = to.params.active ? to.params.active : defaultActive;
  });

  return {
    change,
    activeKey,
  };
}
