<template>
  <div>
    <img alt="login" class="bg-image" :src="require('@/assets/login-bg.png')" />
    <div class="page-title" />
    <div class="container">
      <img ref="logoImageRef" :src="logoImage" :style="{ width: logoWidth }" />
      <div style="height: 30px"></div>
      <a-tabs v-model:activeKey="activateName" @change="change">
        <a-tab-pane key="login" :tab="i18nText('账户登录')">
          <a-form
            ref="loginFormRef"
            :model="loginForm"
            :rules="loginRules"
            :wrapper-col="{ span: 24 }"
            @submit.prevent
            @finish="onLogin"
            @validate="handleLoginValidate"
          >
            <a-form-item name="login_id">
              <a-input
                v-model:value="loginForm.login_id"
                :placeholder="loginAccountPlaceHolder"
                autocomplete="on"
                name="login_id"
                size="large"
                @keyup.enter="handleLoginValidate"
              ></a-input>
            </a-form-item>
            <a-form-item name="password">
              <a-input-password
                v-model:value="loginForm.password"
                :placeholder="i18nText('请输入登录密码')"
                name="password"
                size="large"
                @keyup.enter="handleLoginValidate"
              ></a-input-password>
            </a-form-item>
            <a-form-item name="validation_code">
              <ant-code-validate
                v-model:value="loginForm.validation_code"
                :placeholder="i18nText('请输入手机验证码')"
                type="text"
                input-size="large"
                :validation-code-handler="requestLoginValidationCode"
                :show-phonenumber="false"
                :phone-number="loginForm.phonenumber"
                :input-height="40"
                :input-border-radius="4"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                :loading="loading"
                :disabled="loginButtonDisabled"
                size="large"
                block
                html-type="submit"
              >
                {{ $t('登录') }}
              </a-button>
            </a-form-item>
            <div v-if="config.allowResetPassword" class="reset-password">
              <a-row justify="center">
                <a-col>
                  <a href="/reset-pass">{{ $t('忘记密码') }}</a>
                </a-col>
              </a-row>
              <a-row justify="space-between" style="display: none">
                <a-col>
                  <a href="/reset-pass">{{ $t('忘记密码') }}</a>
                </a-col>
                <a-col>
                  <a-dropdown>
                    <template #overlay>
                      <a-menu @click="handleLocaleClick">
                        <a-menu-item key="zh">
                          <img class="locale-image" :src="require('@/assets/zh_flag.png')" />
                          简体中文
                        </a-menu-item>
                        <a-menu-item key="en">
                          <img class="locale-image" :src="require('@/assets/en_flag.png')" />
                          English
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button type="text">
                      <span style="margin-right: 10px">
                        <img :src="localeImage" class="locale-image" />
                      </span>
                      {{ localeText }}
                      <UpOutlined />
                    </a-button>
                  </a-dropdown>
                </a-col>
              </a-row>
            </div>
          </a-form>
        </a-tab-pane>
        <a-tab-pane v-if="config.allowRegister" key="signup" :tab="i18nText('账户注册')">
          <a-form
            ref="registerFormRef"
            :model="registerForm"
            :rules="registerRules"
            autocomplete="off"
            :wrapper-col="{ span: 24 }"
            @validate="handleRegisterValidate"
          >
            <a-form-item name="phonenumber">
              <a-input
                id="register-phone"
                v-model:value="registerForm.phonenumber"
                size="large"
                name="phonenumber"
                :placeholder="i18nText('请输入手机号')"
              ></a-input>
            </a-form-item>
            <a-form-item class="login-form-item" name="password">
              <a-input-password
                id="register-password"
                v-model:value="registerForm.password"
                size="large"
                name="password"
                :placeholder="i18nText('请输入密码')"
              ></a-input-password>
            </a-form-item>
            <a-form-item name="repeatPassword">
              <a-input-password
                id="register-repeatpassword"
                v-model:value="registerForm.repeatPassword"
                size="large"
                name="repeatpassword"
                :placeholder="i18nText('请再次输入密码')"
              ></a-input-password>
            </a-form-item>
            <a-form-item v-if="usernameAvailable" name="name">
              <a-input
                id="register-name"
                v-model:value="registerForm.name"
                size="large"
                name="name"
                :placeholder="i18nText('请输入姓名（可选）')"
              ></a-input>
            </a-form-item>
            <a-form-item class="login-form-item" name="validation_code">
              <ant-code-validate
                v-if="config.requirePhoneValidation"
                v-model:value="registerForm.validation_code"
                :placeholder="i18nText('请输入手机验证码')"
                type="text"
                input-size="large"
                :validation-code-handler="requestValidationCode"
                :show-phonenumber="false"
                :phone-number="registerForm.phonenumber"
                :input-height="40"
                :input-border-radius="4"
              />
            </a-form-item>
            <a-form-item class="agree-terms" name="noticed" required>
              <a-checkbox v-model:checked="registerForm.noticed">
                {{ $t('我已阅读并同意') }}
                <router-link :to="{ name: 'terms' }" target="_blank">
                  {{ $t('用户协议') }}
                </router-link>
              </a-checkbox>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                :loading="loading"
                block
                size="large"
                html-type="submit"
                :disabled="registerButtonDisabled"
                @click.prevent="onRegister"
              >
                {{ $t('注册用户') }}
              </a-button>
            </a-form-item>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
  import UserModel from '@/model/user';
  import config from '/config';
  export default {
    beforeRouteEnter(to, from, next) {
      UserModel.getMyUserInfo()
        .then(() => {
          if (from.name) {
            next({ name: from.name });
          } else {
            next({ name: config.defaultPage });
          }
        })
        .catch(() => {
          next();
        });
    },
  };
</script>

<script setup>
  import { computed, reactive, ref, watch, onMounted } from 'vue';
  import UserAPI from '@/api/v1/users';
  import V2UserAPI from '@/api/v2/users';
  import ValidationCodeAPI from '@/api/v1/validationCode';
  import AntCodeValidate from '@/views/components/ant_code_validate.vue';
  import ErrorMessage from '@/model/errorMessage.js';
  import { useStore } from 'vuex';
  import { useRoute, useRouter } from 'vue-router';
  import { routeTabs } from '@/tool/routeTabs';
  import { i18nText } from '@/lang';
  import { useI18n } from 'vue-i18n';
  import { UpOutlined } from '@ant-design/icons-vue';
  import { Modal } from 'ant-design-vue';

  const store = useStore();
  const router = useRouter();

  const logoImageRef = ref();
  const logoImage = require('./images/' + config.logoImageName);
  const logoWidth = ref('0px');
  const loading = ref(false);
  const { change, activeKey: activateName } = routeTabs('login');
  watch(
    () => activateName.value,
    (newValue) => {
      console.log(newValue);
      setTimeout(() => {
        document.title = newValue == 'login' ? '登录' : '注册' + ' | ' + config.webTitle;
      }, 100);
    },
  );

  const loginFormRef = ref();
  const loginForm = reactive({
    login_id: '',
    password: '',
    validation_code: '',
  });
  const loginErrorResonse = reactive({
    error: {},
  });
  const loginAccountPlaceHolder =
    config.allowPhoneLogin && config.allowUserNameLogin
      ? i18nText('请输入用户名或手机号')
      : config.allowUserNameLogin
      ? i18nText('请输入用户名')
      : i18nText('请输入手机号');

  let validateLoginPhoneNumber = async (_rule, value) => {
    if (config.allowUserNameLogin) {
      if (!value || value === '') {
        return Promise.reject(i18nText('用户名或密码错误'));
      }
      return Promise.resolve();
    } else if (config.allowPhoneLogin) {
      let reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
      if (!/^\d{11}$/.test(value)) {
        return Promise.reject(i18nText('手机号或密码错误'));
      } else if (loginForm.password.length < 8) {
        return Promise.reject(i18nText('手机号或密码错误'));
      } else if (!reg.test(loginForm.password)) {
        return Promise.reject(i18nText('手机号或密码错误'));
      } else {
        return Promise.resolve();
      }
    }
    return Promise.resolve();
  };

  let validateSignUpPassword = async (_rule, value) => {
    let reg = new RegExp(/^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[^A-Za-z0-9].*).{8,}$/);
    if (!reg.test(value)) {
      return Promise.reject(i18nText('要求：8位以上包含字符、大小写字母、数字'));
    } else {
      return Promise.resolve();
    }
  };

  let validateLoginCode = async (_rule, value) => {
    let field = ErrorMessage.getMessageFieldNameFromError(loginErrorResonse.error);
    if (field == 'validation_code') {
      return Promise.resolve();
    }
    if (value === '') {
      return Promise.reject(i18nText('请输入手机验证码'));
    } else {
      return Promise.resolve();
    }
  };

  let loginCommonValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(loginErrorResonse.error);
    if (field == '') {
      return Promise.resolve();
    }
    if ((field == 'login_id' || field == 'password') && _rule.field == 'login_id') {
      let message = ErrorMessage.getServerMessageFromError(loginErrorResonse.error);
      if (field == 'login_id' && message == 'operating_too_frequently') {
        return Promise.reject(i18nText('多次密码错误，请十分钟后再尝试'));
      }
      if (field == 'login_id' && message == 'employee_locked') {
        return Promise.reject(i18nText('你的账号已被冻结'));
      }
      return Promise.reject(
        i18nText(!config.allowUserNameLogin ? '手机号或密码错误' : '账户或密码错误'),
      );
    }
    if (
      loginErrorResonse.error &&
      loginErrorResonse.error.response &&
      loginErrorResonse.error.response.data &&
      loginErrorResonse.error.response.data.field_name
    ) {
      let codeField = loginErrorResonse.error.response.data.field_name;
      if (codeField == 'validation_code' && _rule.field == codeField) {
        return Promise.reject(i18nText('验证码错误'));
      }
    }
    return Promise.resolve();
  };

  const loginRules = {
    login_id: [
      { required: true, validator: validateLoginPhoneNumber, trigger: 'submit' },
      { required: true, validator: loginCommonValidate, trigger: 'submit' },
    ],
    validation_code: [
      { required: true, validator: validateLoginCode, trigger: 'submit' },
      { required: true, validator: loginCommonValidate, trigger: 'submit' },
    ],
  };
  const loginButtonDisabled = computed(() => !loginForm.login_id || !loginForm.password);
  const handleLoginValidate = (...args) => {
    console.log(args);
    loginErrorResonse.error = {};
  };
  const requestLoginValidationCode = async () => {
    try {
      if (!/^\d{11}$/.test(loginForm.login_id)) {
        await loginFormRef.value.validateFields('login_id');
        return;
      }
      return ValidationCodeAPI.userValidationCode(13, loginForm.login_id)
        .then(() => {
          Promise.resolve();
        })
        .catch((err) => {
          loginErrorResonse.error = err;
          loginFormRef.value.validateFields('validation_code');
          Promise.reject(err);
        });
    } catch (errorInfo) {
      return Promise.reject(errorInfo);
    }
  };
  const route = useRoute();
  const onLogin = () => {
    loading.value = true;
    V2UserAPI.login(loginForm.login_id, loginForm.password, loginForm.validation_code)
      .then((response) => {
        localStorage.setItem('showConfidential', true);
        store.commit('user/SET_USER', response);
        localStorage.setItem('visibilitychange', 'changed');
        localStorage.removeItem('showConfidentialDialog');
        if (route.params.redirect) {
          // let redirect = decodeURIComponent(route.fullPath.split('redirect=')[1]);
          let redirect = route.params.redirect;
          location.href = redirect;
        } else {
          router.push({ name: config.defaultPage });
        }
      })
      .catch((error) => {
        let field = ErrorMessage.getMessageFieldNameFromError(error);
        let message = ErrorMessage.getServerMessageFromError(error);
        if (field == 'password' && message == 'invalid_password_format') {
          passWarning();
          return;
        }
        loginErrorResonse.error = error;
        loginFormRef.value.validate();
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const i18n = useI18n();
  const localeImage = computed(() => {
    if (store.state.locale.language == 'en') {
      return require('@/assets/en_flag.png');
    } else {
      return require('@/assets/zh_flag.png');
    }
  });
  const localeText = computed(() => (store.state.locale.language == 'en' ? 'English' : '简体中文'));
  const handleLocaleClick = (e) => {
    store.commit('locale/SET_LANGUAGE', e);
    i18n.locale = e;
    window.location.reload();
    console.log('click', e);
  };
  const registerFormRef = ref();
  const registerForm = reactive({
    name: '',
    phonenumber: '',
    password: '',
    repeatPassword: '',
    noticed: false,
    validation_code: '',
  });

  const registerErrorResonse = reactive({
    error: {},
  });
  const usernameAvailable = computed(() => config.usernameAvailableWhenSignup);
  let validateRegisterPhoneNumber = async (_rule, value) => {
    if (!/^\d{11}$/.test(value)) {
      return Promise.reject(i18nText('手机号或密码错误'));
    } else {
      return Promise.resolve();
    }
  };
  let validateRepeatPassword = async (_rule, value) => {
    if (value !== registerForm.password) {
      return Promise.reject(i18nText('两次密码输入不一致'));
    } else {
      return Promise.resolve();
    }
  };

  let validateCode = async (_rule, value) => {
    let field = ErrorMessage.getMessageFieldNameFromError(registerErrorResonse.error);
    if (field == 'validation_code') {
      return Promise.resolve();
    }
    if (value === '') {
      return Promise.reject(i18nText('请输入手机验证码'));
    } else {
      return Promise.resolve();
    }
  };

  let validateNotice = async (_rule, value) => {
    if (!value) {
      return Promise.reject(i18nText('请先阅读并同意用户协议'));
    } else {
      return Promise.resolve();
    }
  };
  let registerCommonValidate = async (_rule) => {
    let field = ErrorMessage.getMessageFieldNameFromError(registerErrorResonse.error);
    if (field == '') {
      return Promise.resolve();
    }
    let transMessage = ErrorMessage.getMessageFromError(registerErrorResonse.error);
    if (_rule.field == field) {
      return Promise.reject(transMessage);
    }
    return Promise.resolve();
  };

  const registerRules = {
    phonenumber: [
      { required: true, validator: validateRegisterPhoneNumber, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    password: [
      { required: true, validator: validateSignUpPassword, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    repeatPassword: [
      { required: true, validator: validateRepeatPassword, trigger: 'submit' },
      { required: true, validator: registerCommonValidate, trigger: 'submit' },
    ],
    validation_code: config.requirePhoneValidation
      ? [
          { required: true, validator: validateCode, trigger: 'submit' },
          { required: true, validator: registerCommonValidate, trigger: 'submit' },
        ]
      : [],
    noticed: [{ required: true, validator: validateNotice, trigger: 'submit' }],
  };
  const registerButtonDisabled = computed(
    () =>
      !(
        registerForm.phonenumber &&
        registerForm.password &&
        registerForm.repeatPassword &&
        (!config.requirePhoneValidation || registerForm.validation_code)
      ),
  );
  const handleRegisterValidate = (...args) => {
    console.log(args);
  };

  const requestValidationCode = async () => {
    registerErrorResonse.error = {};
    try {
      const values = await registerFormRef.value.validateFields('phonenumber');
      console.log('registerFormRef: ' + values);
      return ValidationCodeAPI.userValidationCode(1, registerForm.phonenumber)
        .then(() => {
          Promise.resolve();
        })
        .catch((err) => {
          registerErrorResonse.error = err;
          registerFormRef.value.validateFields('validation_code');
          Promise.reject(err);
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      return Promise.reject(errorInfo);
    }
  };

  const onRegister = () => {
    registerErrorResonse.error = {};
    registerFormRef.value.validate().then(() => {
      loading.value = true;
      UserAPI.register(
        registerForm.phonenumber,
        registerForm.password,
        registerForm.validation_code,
        registerForm.name,
      )
        .then((response) => {
          store.commit('user/SET_USER', response);
          router.push({ name: 'vault' });
          registerFormRef.value.resetFields();
        })
        .catch((error) => {
          registerErrorResonse.error = error;
          registerFormRef.value.validate();
        })
        .finally(() => {
          loading.value = false;
        });
    });
  };
  const passWarning = () => {
    Modal.warning({
      title: '密码强度提示',
      content: '您当前密码强度较弱，请先升级密码强度',
      okText: '升级',
      onOk() {
        goChangePass();
      },
    });
  };
  const goChangePass = () => {
    router.push({ name: 'resetPass' });
  };
  onMounted(() => {
    let image = new Image();
    image.src = logoImageRef.value.src;
    image.onload = () => {
      console.log(image.width);
      logoWidth.value = image.width / 2 + 'px';
    };
  });
</script>

<style lang="less" scoped>
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .page-title {
    padding: 22px 0 82px 46px;
    font-weight: 500;
    font-size: 20px;
    color: #262626;
  }

  .container {
    width: 440px;
    margin: auto;
    padding: 40px 32px 29px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .welcome {
    font-weight: 700;
    font-size: 24px;
    color: #262626;
    padding-bottom: 30px;
  }

  :deep(.ant-form-item) {
    margin-bottom: 16px;
  }

  .reset-password {
    text-align: center;
    margin-top: 136px;
  }
  a {
    color: @primary-color;
  }
  .locale-image {
    width: 16px;
    height: 16px;
    margin-bottom: 4px;
  }
</style>
