import { ref } from 'vue';

export function pageConfig() {
  const textDatas = ref([
    {
      direction: 0,
      image: require('./images/dengjizhongxin.png'),
      title: '数字产权登记中心',
      descriptions: [
        '嵌入企业知识资产生产流程中的低成本前置确权、过程确权,保护无法对外公开的商业秘密类数据',
        '创意(设计、3D模型、配方),阶段成果(创新过程中的交流讨论,中间产品,实验记录),算法、技术方案等',
        '通过隐私计算技术保证确权记录不泄露企业内部任何数据',
        '打通司法体系,保证确权记录的法律有效性',
      ],
    },
    {
      direction: 1,
      image: require('./images/fenxizhongxin.png'),
      title: '数据分析中心',
      descriptions: [
        '通过隐私计算技术，注重客户数据安全的保护。',
        '多角度利用企业数据，提供智能化和多角度的价值评估。',
        '借助平台，最大限度地提高数据的价值。',
      ],
    },
    {
      direction: 0,
      image: require('./images/tuoguanzhongxin.png'),
      title: '数据托管中心',
      descriptions: [
        '提供基于数字产权核心系统的密钥托管等定制化服务。',
        '提供高可靠，高安全的托管服务，助力企业有效利用资源。',
        '依托基座等强大的基础设施，保证企业用户使用的灵活性。',
      ],
    },
    {
      direction: 1,
      image: require('./images/renzhengzhongxin.png'),
      title: '数字身份认证中心',
      descriptions: [
        '支持数字身份标识、认证、访问控制，随时验证个人（企业）的法律实体和其数字身份之间的对应关系。',
        '通过数字身份认证，有效防止数据篡改与窃取等安全问题。',
        '提供日志，遵循企业习惯，保证每次访问应用可审计。',
      ],
    },
    {
      direction: 0,
      image: require('./images/yinsijisuan.png'),
      title: '区块链隐私计算网络',
      descriptions: [
        '嵌入企业知识资产生产流程中的低成本前置确权、过程确权,保护无法对外公开的商业秘密类数据',
        '创意(设计、3D模型、配方),阶段成果(创新过程中的交流讨论,中间产品,实验记录),算法、技术方案等',
        '通过隐私计算技术保证确权记录不泄露企业内部任何数据',
        '打通司法体系,保证确权记录的法律有效性',
      ],
    },
  ]);
  return {
    textDatas,
  };
}
