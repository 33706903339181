import v2 from './v2';

export default {
  getTotal() {
    return v2.get('/partners/total');
  },
  submitContracts(name, phone, enterprise, corporation) {
    let params = {
      corporation: corporation,
      name: name,
      contract_number: phone,
      enterprise_name: enterprise,
    };
    return v2.post('/partners/contracts', params);
  },
};
