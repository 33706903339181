<template>
  <div class="front-page">
    <web class="web"></web>
    <mobile class="mobile"></mobile>
  </div>
</template>

<script>
  import config from '/config';
  export default {
    beforeRouteEnter(to, from, next) {
      if (config.frontPageAvailable) {
        next();
      } else {
        next({ name: 'login', params: { active: 'login' } });
      }
    },
  };
</script>

<script setup>
  import mobile from './mobile';
  import web from './web';
</script>

<style scoped>
  .front-page {
    position: relative;
  }
  .web {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .web {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
</style>
