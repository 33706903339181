import v1 from './v1';

export default {
  createCompanyByLegal(phone, code) {
    return v1.post('/enterprises/legal-person', { phonenumber: phone, validation_code: code });
  },
  createCompanyByLegalWithoutChangePhone(phone) {
    return v1.post('/enterprises/legal-person', { phonenumber: phone });
  },
  createCompanyByTable(fileId) {
    return v1.post('/enterprises/agent', { apply_book_id: fileId });
  },
  joinCompany(name) {
    return v1.post('/enterprises/employees', { name: name });
  },
  dealJoinCompanyApply(employee_id, operation) {
    return v1.put('/enterprises/employees', { employee_id: employee_id, status: operation });
  },
  getCompany() {
    return v1.get('/enterprises');
  },
  getJoinedCompanies() {
    return v1.get('/enterprises/join-list');
  },
  changeTrustStatus(status) {
    return v1.put('/enterprises', { private_key_status: status });
  },
  getCompanyFiles(pageId, pageSize) {
    let params = { page_id: pageId, page_size: pageSize };
    return v1.get('/enterprises/employees/files', { params });
  },
  getCompanyEmployeeList(params) {
    return v1.get('/enterprises/employees', { params });
  },
  getCompanyApplyList(params) {
    return v1.get('/enterprises/employees', { params });
  },
  ocrLicense(imageId) {
    return v1.post('/enterprises/ocr/license', { image_id: imageId });
  },
  user_names(user_ids) {
    return v1.get(`/enterprises/employees/names`, { params: { user_ids: user_ids } });
  },
};
