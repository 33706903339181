<template>
  <div :style="cssProps">
    <a-space direction="vertical" style="width: 100%">
      <a-typography-text v-if="showPhonenumber">
        {{ $t('手机号码:phone', { phone: privacyPhoneNum(phoneNumber) }) }}
      </a-typography-text>
      <a-input-group>
        <a-row :gutter="8" style="flex-wrap: nowrap">
          <a-col flex="auto">
            <a-input
              v-bind="$attrs"
              id="validation-input"
              class="validation-input"
              :value="value"
              :size="inputSize"
              autocomplete="off"
              :placeholder="placeholderText"
              style="flex-grow: 1"
              @blur="onBlur"
              @input="handleInput"
            ></a-input>
          </a-col>
          <a-col :flex="codeButtonWidth">
            <a-button
              :size="inputSize"
              :class="{ disabled: tickRemain > 0 }"
              style="width: 100%"
              :disabled="tickRemain > 0"
              :loading="loading"
              @click="onRequestValidation"
            >
              {{ buttonText }}
            </a-button>
          </a-col>
        </a-row>
      </a-input-group>
      <a-typography-text v-if="tickRemain > 0" type="secondary">
        {{ $t('验证码已发送到您的手机,10分钟内有效') }}
      </a-typography-text>
    </a-space>
  </div>
</template>

<script setup>
  import { onUnmounted, computed, ref } from 'vue';
  import filters from '@/filters';
  import { i18nText } from '@/lang';
  const props = defineProps({
    showPhonenumber: {
      type: Boolean,
      default: true,
    },
    value: String,
    infoText: {
      type: String,
      default: i18nText('您正在使用手机短信验证'),
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    getCodeText: {
      type: String,
      default: i18nText('获取验证码'),
    },
    validationCodeHandler: {
      type: Function,
      required: true,
    },
    inputHeight: {
      type: Number,
      default: 32,
    },
    inputBorderRadius: {
      type: Number,
      default: 2,
    },
    inputSize: {
      type: String,
      default: 'default',
    },
  });
  const emit = defineEmits(['input', 'blur']);
  const tickRemain = ref(0);

  const buttonText = computed(() => (tickRemain.value <= 0 ? props.getCodeText : tickRemain.value));
  const codeButtonWidth = computed(() => (tickRemain.value <= 0 ? '110px' : '110px'));
  // const percentage = computed(() => Math.round(((60 - tickRemain.value) / 60) * 100));
  const placeholderText = i18nText('6位数字');
  const cssProps = computed(() => {
    return {
      inputHeight: props.inputHeight + 'px',
      inputBorderRadius: props.inputBorderRadius + 'px',
    };
  });

  const privacyPhoneNum = (number) => {
    return filters.privacyPhoneNum(number);
  };
  const timePaused = ref(true);
  const onTick = () => {
    if (!timePaused.value) {
      if (tickRemain.value > 0) {
        tickRemain.value--;
      } else {
        timePaused.value = true;
      }
    }
  };

  const loading = ref(false);
  const tickInterval = setInterval(onTick, 1000);
  const onRequestValidation = () => {
    loading.value = true;
    props
      .validationCodeHandler(props.phoneNumber)
      .then(() => {
        tickRemain.value = 60;
        timePaused.value = false;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        loading.value = false;
      });
  };
  const handleInput = (e) => {
    emit('input', e.target.value);
  };
  const onBlur = () => {
    emit('blur');
  };
  onUnmounted(() => {
    clearInterval(tickInterval);
  });
</script>

<style scoped></style>
