<template>
  <div class="front-page">
    <div class="image-container">
      <a-row justify="center">
        <img src="./images/xiushi_3.png" style="width: 272px; height: 155px" />
      </a-row>
    </div>
    <div class="line-image-container">
      <img src="./images/xiushi_4.png" style="width: 319px; height: 68px" />
    </div>
    <navbar :is-black="true"></navbar>
    <div style="height: 40px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row justify="space-between" align="middle" :gutter="120">
          <a-col :span="12">
            <a-space direction="vertical" :size="20">
              <a-typography-title style="font-size: 64px">政府机构</a-typography-title>
              <a-typography-text style="font-size: 24px">
                了解企业内部需求、帮助政府机构建立一个知识产权综合性服务平台
              </a-typography-text>
            </a-space>
          </a-col>
          <a-col :span="12">
            <img
              src="./images/building.png"
              style="width: 100%; border: 30px solid #eaeaea; border-radius: 24px"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 95px"></div>
    <div class="gray-container">
      <div class="gray-bg"></div>
      <div style="height: 95px"></div>
      <a-row justify="center">
        <a-typography-title style="font-size: 52px">四中心一网络</a-typography-title>
      </a-row>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1038px">
          <img src="./images/sizhongxin.png" style="width: 100%; object-fit: cover" />
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
      <div style="height: 115px"></div>
      <div class="yellow-line-container">
        <div class="yellow-line-box">
          <img src="./images/yellowLine.png" style="width: 100%" />
        </div>
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
          <a-col flex="1 0 10px"></a-col>
          <a-col flex="0 1 980px">
            <a-row :gutter="20">
              <a-col :span="12">
                <a-card
                  style="background: #343d57; border-radius: 24px; width: 100%; height: 400px"
                >
                  <a-space direction="vertical">
                    <img src="./images/yinhao.png" style="width: 68px; height: 42px" />
                    <a-typography-text style="color: white; font-size: 24px">
                      为了能更好的为辖区内企业提供服务,上级领导提出以企业需求为导向,建立一个知识产权综合性服务平台.
                      并且作为监管机构,需求实时获取对企业内部数据的统计,以准确评估企业创新能力.
                    </a-typography-text>
                  </a-space>
                </a-card>
              </a-col>
              <a-col :span="12">
                <div style="height: 60px"></div>
                <a-card
                  style="background: #eaeaea; border-radius: 24px; width: 100%; height: 400px"
                >
                  <a-space direction="vertical" align="end">
                    <a-typography-text style="font-size: 24px">
                      基于对行业及制度层面的理解,以及结合调研企业真实的内部需求,平台从数据隐私安全性、数据实时交互性、
                      维权效率及成本性价比等多重角度考虑出发,为机构量身定制了“四中心一网络的解决方案
                    </a-typography-text>
                    <img
                      src="../front/images/yinhao.png"
                      style="
                        width: 68px;
                        height: 42px;
                        transform: rotate(180deg);
                        transform-origin: center;
                      "
                    />
                  </a-space>
                </a-card>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="1 0 10px"></a-col>
        </a-row>
        <div style="height: 52px"></div>
      </div>
      <div style="height: 220px"></div>
    </div>
    <div class="blue-container">
      <div class="blue-box">
        <img src="./images/blueBg.png" style="width: 100%; height: 100%; object-fit: fill" />
      </div>
      <a-row justify="center" align="middle" style="height: 100%">
        <a-typography-text style="color: white">
          四中心一网络&nbsp;&nbsp;四中心一网络&nbsp;&nbsp;四中心一网络&nbsp;&nbsp;
          四中心一网络&nbsp;&nbsp;四中心一网络&nbsp;&nbsp;四中心一网络&nbsp;&nbsp;
        </a-typography-text>
      </a-row>
    </div>
    <div style="height: 105px"></div>
    <a-row justify="center">
      <a-typography-title style="font-size: 52px">标题</a-typography-title>
    </a-row>
    <div style="width: 100%" v-for="(item, index) in textDatas" :key="index">
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1180px">
          <a-row align="middle" :gutter="120">
            <a-col :span="12" v-if="item.direction == 0">
              <img :src="item.image" style="width: 100%; object-fit: cover" />
            </a-col>
            <a-col :span="12">
              <a-space direction="vertical" :size="12">
                <a-typography-title>{{ item.title }}</a-typography-title>
                <div style="height: 24px"></div>
                <a-space align="start" v-for="(text, index) in item.descriptions" :key="index">
                  <check-outlined />
                  <a-typography-text style="font-size: 20px">{{ text }}</a-typography-text>
                </a-space>
              </a-space>
            </a-col>
            <a-col :span="12" v-if="item.direction == 1">
              <img :src="item.image" style="width: 100%; object-fit: cover" />
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
      <div style="height: 150px"></div>
    </div>
    <div class="gray-container">
      <div class="gray-bg"></div>
      <div style="height: 114px"></div>
      <a-row justify="center" align="middle" style="height: 100%">
        <a-typography-title style="font-size: 52px">成功案例</a-typography-title>
      </a-row>
      <div style="height: 31px"></div>
      <a-row justify="center" align="middle" style="height: 100%">
        <a-space direction="vertical" align="center">
          <a-typography-text style="font-size: 24px">
            平台已成功落地,为多个政府、院校、企业
          </a-typography-text>
          <a-typography-text style="font-size: 24px">联手构建存证平台.专注打磨</a-typography-text>
          <a-typography-text style="font-size: 24px">可靠的行业解决方案</a-typography-text>
        </a-space>
      </a-row>
      <div style="height: 22px"></div>
      <a-row justify="center" align="middle" style="height: 100%">
        <img src="./images/xius.png" style="width: 55px; height: 33x" />
      </a-row>
      <div style="height: 26px"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1120px">
          <a-row align="middle" :gutter="35">
            <a-col :span="7">
              <a-space direction="vertical" :size="130">
                <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
                  <a-col flex="20px"></a-col>
                  <a-col flex="1 1 auto">
                    <a-card style="background: #fccc0a; border-radius: 12px">
                      <img src="./images/logo.png" style="width: 100%; object-fit: cover" />
                    </a-card>
                  </a-col>
                </a-row>
                <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
                  <a-col flex="1 1 auto">
                    <a-card style="background: #fccc0a; border-radius: 12px">
                      <img src="./images/logo.png" style="width: 100%; object-fit: cover" />
                    </a-card>
                  </a-col>
                  <a-col flex="20px"></a-col>
                </a-row>
              </a-space>
            </a-col>
            <a-col :span="9">
              <div style="height: 30px"></div>
              <div class="gradient-box">
                <div class="gradient-bg">
                  <img
                    src="./images/gradientBg.png"
                    style="width: 100%; height: 100%; object-fit: fill"
                  />
                </div>
                <a-card style="background: transparent">
                  <a-space direction="vertical">
                    <a-row justify="center">
                      <img src="./images/logo.png" style="width: 60%" />
                    </a-row>
                    <a-divider style="background: white" />
                    <a-typography-text style="color: white; font-size: 20px">
                      中国(湖南)自由贸易实验区知识产权服务中心隶属于长沙市经开区市场监督管局(知识产权局),
                      中心整合国家、省、市知识产权公共资源,打造集知识产权创造、运用、保护、管理、服务为一体的“一站式”服务平台.
                    </a-typography-text>
                  </a-space>
                </a-card>
              </div>
            </a-col>
            <a-col :span="7">
              <a-space direction="vertical" :size="60">
                <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
                  <a-col flex="1 1 auto">
                    <a-card style="background: #343d57; border-radius: 12px">
                      <img src="./images/logo.png" style="width: 100%; object-fit: cover" />
                    </a-card>
                  </a-col>
                  <a-col flex="20px"></a-col>
                </a-row>
                <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
                  <a-col flex="20px"></a-col>
                  <a-col flex="1 1 auto">
                    <a-card style="background: #fccc0a; border-radius: 12px">
                      <img src="./images/logo.png" style="width: 100%; object-fit: cover" />
                    </a-card>
                  </a-col>
                </a-row>
              </a-space>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
      <div style="height: 180px"></div>
    </div>
    <front-footer></front-footer>
  </div>
</template>

<script>
  import config from '/config';
  export default {
    beforeRouteEnter(to, from, next) {
      if (config.frontPageAvailable) {
        next();
      } else {
        next({ name: 'login', params: { active: 'login' } });
      }
    },
  };
</script>

<script setup>
  import { CheckOutlined } from '@ant-design/icons-vue';
  import { pageConfig } from './pageConfig';
  import frontFooter from '../front-footer/web';
  import Navbar from '../front-navbar/web';
  const { textDatas } = pageConfig();
</script>

<style scoped>
  .front-page {
    position: relative;
    overflow: hidden;
  }
  .image-container {
    position: absolute;
    top: 140px;
    left: 0;
    right: 0;
    height: 160px;
    z-index: 9;
    background: transparent;
  }
  .line-image-container {
    position: absolute;
    top: 400px;
    left: 0;
    width: 319px;
    height: 68px;
    background: transparent;
  }
  .gray-container {
    position: relative;
  }
  .gray-bg {
    background: #fafafa;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .yellow-line-container {
    position: relative;
  }
  .yellow-line-box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 268px;
    z-index: -1;
  }
  .blue-container {
    position: relative;
    width: 100%;
    height: 79px;
  }
  .blue-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .gradient-box {
    position: relative;
  }
  .gradient-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
