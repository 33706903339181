import v1 from './v1';

export default {
  //new

  // for register and reset pass
  //1-用户注册 2-进入商业秘密数字保险箱 3-文件登记 4-绑定新的手机 5-前验证旧的手机号 6-生成证明文件校验码 7-信息披露页面进入校验码 8-生成披露校验码 9-披露信息查看校验码 10-邮件验证码 11-忘记密码
  userValidationCode(type, phonenumber) {
    return v1.get('/validation/public-code', { params: { phonenumber: phonenumber, type: type } });
  },

  // for safe verify
  operationValidateCode(type) {
    return v1.get('/validation/private-code', { params: { type: type } });
  },

  validateOldPhoneCode(code) {
    return v1.post('/users/authorization/old-phone-code', { validation_code: code });
  },
};
