<template>
  <div class="front-page">
    <div class="header-blue-bg">
      <middle-navbar></middle-navbar>
      <div style="height: 60px"></div>
      <div style="position: absolute; left: 0px; top: 187px">
        <img src="@/views/index/front/images/xiushi_1.png" style="width: 130px; height: 73px" />
      </div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="44px"></a-col>
        <a-col flex="auto">
          <a-row justify="center" style="width: 100%">
            <a-space direction="vertical" align="center">
              <a-space direction="vertical" :size="4">
                <a-typography-title style="text-align: center; color: white; font-size: 52px">
                  {{ mobileMainTitleHead }}
                </a-typography-title>
                <a-typography-title style="text-align: center; color: white; font-size: 52px">
                  {{ mobileMainTitleTail }}
                </a-typography-title>
              </a-space>
              <a-typography-text style="text-align: center; color: white; font-size: 36px">
                {{ subTitle }}
              </a-typography-text>
            </a-space>
          </a-row>
        </a-col>
        <a-col flex="44px"></a-col>
      </a-row>
      <div style="height: 35px"></div>
    </div>
    <div class="image-container">
      <div class="blue-bg"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="6px"></a-col>
        <a-col flex="0 1 auto">
          <img
            src="@/views/index/front-xian/front/images/title_pic.png"
            style="width: 100%; object-fit: cover"
          />
        </a-col>
        <a-col flex="6px"></a-col>
      </a-row>
    </div>
    <div style="height: 40px"></div>
    <a-row justify="center">
      <img src="@/views/index/front/images/xiushi_3.png" style="width: 60px; height: 13px" />
    </a-row>
    <div style="height: 60px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="24px"></a-col>
      <a-col flex="1 1 auto">
        <a-space direction="vertical" :size="24" style="width: 100%">
          <a-card
            v-for="(item, index) in advantages"
            :key="index"
            style="width: 100%; background: #f5f4f5; border-radius: 32px"
          >
            <a-space direction="vertical" style="width: 100%" :size="4">
              <a-row justify="start" align="middle" :gutter="10">
                <a-col>
                  <img :src="item.image" style="width: 66px; height: 66px" />
                </a-col>
                <a-col>
                  <a-typography-title :level="4">{{ item.title }}</a-typography-title>
                </a-col>
              </a-row>
              <a-typography-text style="font-size: 18px; text-align: center">
                {{ item.description }}
              </a-typography-text>
              <div style="height: 26px"></div>
            </a-space>
          </a-card>
        </a-space>
      </a-col>
      <a-col flex="24px"></a-col>
    </a-row>
    <div style="height: 46px"></div>
    <a-space direction="vertical" :size="66">
      <a-row type="flex" justify="space-around" style="flex-wrap: nowrap">
        <a-col flex="65px"></a-col>
        <a-col flex="auto">
          <a-space direction="vertical" :size="22">
            <img
              src="@/views/index/front/images/shuzizhishicaichan_1.png"
              style="width: 41px; height: 38px"
            />
            <a-typography-title>数字资产</a-typography-title>
            <a-typography-text
              style="font-size: 20px"
              v-for="(text, index) in assetTexts"
              :key="index"
            >
              {{ text }}
            </a-typography-text>
          </a-space>
        </a-col>
        <a-col flex="65px"></a-col>
      </a-row>
      <a-row justify="center">
        <img
          src="@/views/index/front/images/shuzizhishicaichan.png"
          style="width: 50%; object-fit: cover"
        />
      </a-row>
    </a-space>
    <div style="height: 118px"></div>
    <a-row type="flex" style="background: #f8f7fe; border-radius: 32px; flex-wrap: nowrap">
      <a-col flex="24px"></a-col>
      <a-col flex="auto">
        <a-row justify="space-between" :gutter="40">
          <a-col :span="12">
            <a-space style="width: 100%" direction="vertical" :size="0">
              <div style="height: 86px"></div>
              <a-space style="width: 100%" direction="vertical" :size="30">
                <a-typography-title>{{ insurances[0].title }}</a-typography-title>
                <a-typography-text style="font-size: 20px">
                  {{ insurances[0].description }}
                </a-typography-text>
              </a-space>
              <div style="height: 94px"></div>
            </a-space>
          </a-col>
          <a-col :span="12">
            <a-space style="width: 100%" align="end" direction="vertical">
              <div style="height: 100px"></div>
              <div
                style="
                  padding: 48px 34px;
                  background: white;
                  border-radius: 16px;
                  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
                "
              >
                <img :src="insurances[0].image" style="width: 100%" />
              </div>
              <div style="height: 68px"></div>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="24px"></a-col>
    </a-row>
    <div style="height: 30px"></div>
    <a-row type="flex" style="background: #f8f7fe; border-radius: 32px; flex-wrap: nowrap">
      <a-col flex="24px"></a-col>
      <a-col flex="auto">
        <a-row justify="space-between" :gutter="40">
          <a-col :span="12">
            <a-space style="width: 100%" direction="vertical" :size="0">
              <div style="height: 86px"></div>
              <a-space style="width: 100%" direction="vertical" :size="30">
                <a-typography-title>{{ insurances[1].title }}</a-typography-title>
                <a-typography-text style="font-size: 20px">
                  {{ insurances[1].description }}
                </a-typography-text>
              </a-space>
              <div style="height: 94px"></div>
            </a-space>
          </a-col>
          <a-col :span="12">
            <a-space style="width: 100%" align="end" direction="vertical">
              <div style="height: 100px"></div>
              <div
                style="
                  padding: 48px 34px;
                  background: white;
                  border-radius: 16px;
                  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
                "
              >
                <img :src="insurances[1].image" style="width: 100%" />
              </div>
              <div style="height: 68px"></div>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="24px"></a-col>
    </a-row>
    <div style="height: 56px"></div>
    <div style="background: #fafafa">
      <a-space direction="vertical" style="width: 100%" :size="36">
        <div style="height: 66px"></div>
        <a-row justify="space-around" align="middle">
          <a-col>
            <a-space direction="vertical" align="center" :size="0">
              <a-typography-title>哪些资产需要被保护?</a-typography-title>
            </a-space>
          </a-col>
        </a-row>
        <div style="height: 20px"></div>
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
          <a-col flex="48px"></a-col>
          <a-col flex="auto">
            <a-row justify="center" :gutter="40">
              <a-col :span="12" v-for="(item, index) in needProtecteds" :key="index">
                <a-space direction="vertical">
                  <div style="height: 40px" v-if="index % 2 !== 0"></div>
                  <img :src="item.image" style="width: 100%" />
                  <div style="height: 20px"></div>
                  <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                  <a-typography-text style="font-size: 20px">
                    {{ item.description }}
                  </a-typography-text>
                </a-space>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="48px"></a-col>
        </a-row>
        <div style="height: 40px"></div>
      </a-space>
    </div>
    <div style="height: 72px"></div>
    <a-space direction="vertical" style="width: 100%" :size="15">
      <a-row justify="center">
        <a-typography-title>解决方案</a-typography-title>
      </a-row>
      <div style="height: 7px"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="60px"></a-col>
        <a-col flex="auto">
          <a-row :gutter="20">
            <a-col :span="12" v-for="(item, index) in solutions" :key="index">
              <a-space direction="vertical">
                <a-card
                  style="
                    border-radius: 24px;
                    border: 2px solid #315aff;
                    box-shadow: 0px 3px 0px #315aff;
                  "
                  @click="clickGovernment"
                >
                  <a-space direction="vertical" style="background: #f5f5f5; border-radius: 22px">
                    <div style="height: 26px"></div>
                    <a-row type="flex" style="flex-wrap: nowrap">
                      <a-col flex="16px"></a-col>
                      <a-col flex="0 1 auto">
                        <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                        <a-row style="height: 54px">
                          <a-typography-text style="font-size: 16px">
                            {{ item.description }}
                          </a-typography-text>
                        </a-row>
                      </a-col>
                      <a-col flex="16px"></a-col>
                    </a-row>
                    <img :src="item.image" style="width: 100%" />
                  </a-space>
                </a-card>
                <div style="height: 20px"></div>
              </a-space>
            </a-col>
          </a-row>
          <div style="height: 40px"></div>
        </a-col>
        <a-col flex="60px"></a-col>
      </a-row>
    </a-space>
    <div style="height: 48px"></div>
    <a-row justify="center">
      <img src="./images/xiushi_1.png" style="width: 170px; height: 75px" />
    </a-row>
    <div style="height: 76px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="64px"></a-col>
      <a-col flex="auto">
        <a-row>
          <a-space direction="vertical" style="width: 100%" align="center">
            <a-row justify="center" style="height: 360px; width: 100%">
              <a-col flex="auto">
                <img src="./images/foundation.png" class="foundation-png" />
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <div style="position: relative">
                  <a-space style="width: 100%">
                    <img class="foundation-bg" src="./images/foundation_bg.png" />
                  </a-space>
                  <div class="sevice-text-container">
                    <a-row type="flex">
                      <a-col flex="80px"></a-col>
                      <a-col flex="auto">
                        <a-space direction="vertical" :size="12">
                          <div style="height: 24px"></div>
                          <img src="./images/yinhao.png" style="width: 60px; height: 36px" />
                          <div style="height: 12px"></div>
                          <a-typography-title style="color: white">
                            {{ foundationServices.title }}
                          </a-typography-title>
                          <a-typography-title :level="3" style="color: white">
                            {{ foundationServices.descriptions }}
                          </a-typography-title>
                          <div style="height: 4px"></div>
                          <a-space
                            :size="12"
                            v-for="(item, index) in foundationServices.items"
                            :key="index"
                            align="center"
                          >
                            <img :src="item.image" style="width: 32px; height: 32px" />
                            <a-typography-text style="font-size: 18px; color: white">
                              {{ item.description }}
                            </a-typography-text>
                          </a-space>
                        </a-space>
                      </a-col>
                      <a-col flex="80px"></a-col>
                    </a-row>
                  </div>
                  <div class="arrow-decration">
                    <img
                      src="./images/mobile_foundation_arrow.png"
                      style="width: 46px; height: 49px"
                    />
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-space>
        </a-row>
      </a-col>
      <a-col flex="64px"></a-col>
    </a-row>
    <div style="height: 80px"></div>
    <front-footer></front-footer>
  </div>
</template>

<script setup>
  // import { useRouter } from 'vue-router';
  import MiddleNavbar from '@/views/index/front-navbar/middle';
  import frontFooter from '@/views/index/front-xian/front-footer/web';
  import { frontConfig } from './frontConfig';
  const {
    mobileMainTitleHead,
    mobileMainTitleTail,
    subTitle,
    advantages,
    solutions,
    insurances,
    assetTexts,
    needProtecteds,
    foundationServices,
  } = frontConfig();
  // const router = useRouter();
  // const clickGovernment = () => {
  //   router.push({ name: 'frontGovernment' });
  // };
</script>

<style scoped>
  .front-page {
    position: relative;
    overflow: hidden;
  }
  .header-blue-bg {
    background: linear-gradient(180deg, #4e45f4 0%, #4b58f3 100%);
  }
  .image-container {
    position: relative;
  }
  .blue-bg {
    background: linear-gradient(180deg, #4b58f3 0%, #476ff1 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vw * 0.45);
    z-index: -1;
  }
  .sevice-text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .arrow-decration {
    position: absolute;
    top: -24px;
    bottom: 0;
    left: 0px;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .foundation-bg {
    width: 580px;
    object-fit: fill;
  }
  .foundation-png {
    width: 540px;
    object-fit: fill;
  }
</style>
