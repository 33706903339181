import { i18nText } from '@/lang';

const USER_ROLE = {
  0: i18nText('企业成员'),
  1: i18nText('超级管理员'),
  2: i18nText('管理员'),
  3: i18nText('保密员'),
  4: i18nText('企业成员'),
};
export const USER_COMPANY_ROLE_CONFIDENTIAL = 3;
export const USER_COMPANY_ROLE_ADMIN = 1;
export default {
  UserRoleName(value) {
    return USER_ROLE[value];
  },
};
