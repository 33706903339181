import { createI18n } from 'vue-i18n';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import enUS from 'ant-design-vue/es/locale/en_US';
import jaJP from 'ant-design-vue/es/locale/ja_JP';
import zh from './zh.js';
import en from './en.js';
import ja from './ja.js';

const messages = {
  en: {
    ...en,
    ...enUS,
  },
  zh: {
    ...zh,
    ...zhCN,
  },
  ja: {
    ...ja,
    ...jaJP,
  },
};

export function getLanguage() {
  let locale = window.localStorage.getItem('locale');
  return locale ? locale : 'zh';
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLanguage(),
  fallbackLocale: getLanguage(),
  messages,
});

export function i18nText(val) {
  try {
    const { t } = i18n.global;
    return t(val);
  } catch (e) {
    console.log(e);
  }
}

export default i18n;
