<template>
  <div class="front-page">
    <div class="header-blue-bg">
      <navbar></navbar>
      <div style="height: 60px"></div>
      <a-row justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="130px">
          <a-space direction="vertical">
            <div style="height: 70px"></div>
            <img src="@/views/index/front/images/xiushi_1.png" style="width: 130px; height: 73px" />
          </a-space>
        </a-col>
        <a-col flex="0 1 850px">
          <a-row justify="center" style="width: 100%">
            <a-typography-title style="text-align: center; color: white; font-size: 52px">
              {{ mainTitle }}
            </a-typography-title>
          </a-row>
          <a-row justify="center" style="width: 100%">
            <a-typography-text style="text-align: center; color: white; font-size: 36px">
              {{ subTitle }}
            </a-typography-text>
          </a-row>
        </a-col>
        <a-col flex="130px"></a-col>
      </a-row>
      <div style="height: 35px"></div>
    </div>
    <div class="image-container">
      <div class="blue-bg"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1180px">
          <img src="./images/title_pic.png" style="width: 100%; object-fit: cover" />
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
    </div>
    <div style="height: 36px"></div>
    <a-row justify="center">
      <img src="@/views/index/front/images/xiushi_3.png" style="width: 60px; height: 13px" />
    </a-row>
    <div style="height: 154px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row :gutter="26">
          <a-col
            v-for="(item, index) in advantages"
            :key="index"
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
          >
            <a-space
              direction="vertical"
              style="background: #f5f5f5; border-radius: 22px; height: 100%"
            >
              <div style="height: 60px"></div>
              <a-row type="flex" style="flex-wrap: nowrap">
                <a-col flex="40px"></a-col>
                <a-col flex="0 1 auto">
                  <img :src="item.image" />
                  <div style="height: 24px"></div>
                  <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                  <div style="height: 10px"></div>
                  <a-row style="height: 200px">
                    <a-typography-text style="font-size: 20px">
                      {{ item.description }}
                    </a-typography-text>
                  </a-row>
                </a-col>
                <a-col flex="40px"></a-col>
              </a-row>
              <div style="height: 140px"></div>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 150px"></div>
    <a-row type="flex" justify="space-around" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <a-row justify="space-between" :gutter="120">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="11">
            <a-space direction="vertical" :size="22">
              <img
                src="@/views/index/front/images/shuzizhishicaichan_1.png"
                style="width: 41px; height: 38px"
              />
              <a-typography-title>数字资产</a-typography-title>
              <a-typography-text
                style="font-size: 20px"
                v-for="(text, index) in assetTexts"
                :key="index"
              >
                {{ text }}
              </a-typography-text>
            </a-space>
          </a-col>
          <a-col
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 12, offset: 0 }"
            :md="{ span: 12, offset: 0 }"
            :lg="{ span: 12, offset: 0 }"
            :xl="{ span: 12, offset: 1 }"
          >
            <img
              src="@/views/index/front/images/shuzizhishicaichan.png"
              style="width: 100%; object-fit: cover"
            />
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 136px"></div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px" style="background: #f1f7fe; border-radius: 32px">
        <a-row type="flex" style="flex-wrap: nowrap">
          <a-col flex="100px"></a-col>
          <a-col flex="0 1 auto">
            <a-row justify="space-between" :gutter="20">
              <a-col :span="12">
                <a-space style="width: 100%" direction="vertical" :size="0">
                  <div style="height: 138px"></div>
                  <a-space style="width: 100%" direction="vertical" :size="30">
                    <a-typography-title>{{ insurances[0].title }}</a-typography-title>
                    <a-typography-text style="font-size: 20px">
                      {{ insurances[0].description }}
                    </a-typography-text>
                  </a-space>
                  <div style="height: 94px"></div>
                </a-space>
              </a-col>
              <a-col :span="12">
                <a-row align="middle" style="width: 100%; height: 100%">
                  <a-space style="width: 100%" align="end" direction="vertical">
                    <div
                      style="
                        padding: 48px 34px;
                        background: white;
                        border-radius: 16px;
                        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
                      "
                    >
                      <img :src="insurances[0].image" style="width: 100%" />
                    </div>
                  </a-space>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="100px"></a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 80px"></div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px" style="background: #f8f7fe; border-radius: 32px">
        <a-row type="flex" style="flex-wrap: nowrap">
          <a-col flex="100px"></a-col>
          <a-col flex="0 1 auto">
            <a-row justify="space-between" :gutter="20">
              <a-col :span="12">
                <a-space style="width: 100%" direction="vertical" :size="0">
                  <div style="height: 138px"></div>
                  <a-space style="width: 100%" direction="vertical" :size="30">
                    <a-typography-title>{{ insurances[1].title }}</a-typography-title>
                    <a-typography-text style="font-size: 20px">
                      {{ insurances[1].description }}
                    </a-typography-text>
                  </a-space>
                  <div style="height: 94px"></div>
                </a-space>
              </a-col>
              <a-col :span="12">
                <a-row align="middle" style="width: 100%; height: 100%">
                  <a-space style="width: 100%" align="end" direction="vertical">
                    <div
                      style="
                        padding: 48px 34px;
                        background: white;
                        border-radius: 16px;
                        box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
                      "
                    >
                      <img :src="insurances[1].image" style="width: 100%" />
                    </div>
                  </a-space>
                </a-row>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="100px"></a-col>
        </a-row>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 156px"></div>
    <div class="gray-container">
      <a-space direction="vertical" style="width: 100%" :size="96">
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap" align="bottom">
          <a-col flex="130px"></a-col>
          <a-col flex="auto">
            <a-row justify="center">
              <a-typography-title>哪些资产需要被保护?</a-typography-title>
            </a-row>
          </a-col>
          <a-col flex="130px">
            <a-space direction="vertical">
              <div style="height: 36px"></div>
              <img
                src="@/views/index/front/images/xiushi_2.png"
                style="width: 130px; height: 73px"
              />
            </a-space>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
          <a-col flex="1 0 10px"></a-col>
          <a-col flex="0 1 1180px">
            <a-row justify="center" :gutter="40">
              <a-col :span="6" v-for="(item, index) in needProtecteds" :key="index">
                <a-space direction="vertical">
                  <div style="height: 40px" v-if="index % 2 !== 0"></div>
                  <img :src="item.image" style="width: 100%" />
                  <div style="height: 20px"></div>
                  <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                  <a-typography-text style="font-size: 20px">
                    {{ item.description }}
                  </a-typography-text>
                </a-space>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="1 0 10px"></a-col>
        </a-row>
      </a-space>
      <div style="height: 200px"></div>
    </div>
    <div style="height: 176px"></div>
    <a-space direction="vertical" style="width: 100%" :size="60">
      <a-row justify="center">
        <a-typography-title>解决方案</a-typography-title>
      </a-row>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="1 0 10px"></a-col>
        <a-col flex="0 1 1180px">
          <a-row justify="space-between" :gutter="24">
            <a-col
              v-for="(item, index) in solutions"
              :key="index"
              :xs="24"
              :sm="24"
              :md="6"
              :lg="6"
              :xl="6"
            >
              <a-card
                style="
                  border-radius: 24px;
                  border: 2px solid #315aff;
                  box-shadow: 0px 3px 0px #315aff;
                  height: 100%;
                  /*cursor: pointer;*/
                "
              >
                <a-space direction="vertical" style="background: #f5f5f5; border-radius: 22px">
                  <div style="height: 26px"></div>
                  <a-row type="flex" style="flex-wrap: nowrap">
                    <a-col flex="16px"></a-col>
                    <a-col flex="0 1 auto">
                      <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                      <a-row style="height: 54px">
                        <a-typography-text style="font-size: 16px">
                          {{ item.description }}
                        </a-typography-text>
                      </a-row>
                    </a-col>
                    <a-col flex="16px"></a-col>
                  </a-row>
                  <img :src="item.image" style="width: 100%" />
                </a-space>
              </a-card>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="1 0 10px"></a-col>
      </a-row>
    </a-space>
    <div style="height: 260px"></div>
    <a-row justify="center">
      <img src="./images/xiushi_1.png" style="width: 269px" />
    </a-row>
    <div style="height: 76px"></div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <div style="width: 100%">
          <img src="./images/foundation.png" style="width: 68%; object-fit: cover" />
          <div class="bottom-right-bg">
            <div style="position: relative; width: 100%; height: 100%">
              <a-row align="middle" style="height: 100%">
                <img src="./images/foundation_bg.png" style="width: 100%" />
              </a-row>
              <div class="sevice-text-container">
                <a-row align="middle">
                  <a-space direction="vertical" :size="12">
                    <img src="./images/yinhao.png" style="width: 68px; height: 42px" />
                    <div style="height: 12px"></div>
                    <a-typography-title style="color: white">
                      {{ foundationServices.title }}
                    </a-typography-title>
                    <a-typography-title :level="3" style="color: white">
                      {{ foundationServices.descriptions }}
                    </a-typography-title>
                    <div style="height: 24px"></div>
                    <a-space
                      :size="12"
                      v-for="(item, index) in foundationServices.items"
                      :key="index"
                      align="center"
                    >
                      <img :src="item.image" />
                      <a-typography-text style="font-size: 18px; color: white">
                        {{ item.description }}
                      </a-typography-text>
                    </a-space>
                  </a-space>
                </a-row>
              </div>
            </div>
            <div class="arrow-decration">
              <img src="./images/foundation_arrow.png" />
            </div>
          </div>
        </div>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 150px"></div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <div style="width: 100%">
          <a-row style="width: 100%" justify="end">
            <img src="./images/mask_group.png" style="width: 68%; object-fit: cover" />
          </a-row>
          <div class="bottom-left-bg">
            <div style="position: relative; width: 100%; height: 100%">
              <div class="color-bg-container">
                <a-row type="flex" class="left-color-bg">
                  <a-col flex="40px"></a-col>
                  <a-col flex="auto">
                    <a-space direction="vertical" :size="40" style="width: 100%">
                      <div style="height: 12px"></div>
                      <a-typography-title>
                        {{ bottomTexts[0].title }}
                      </a-typography-title>
                      <a-typography-text style="font-size: 20px">
                        {{ bottomTexts[0].text }}
                      </a-typography-text>
                      <div style="height: 10px"></div>
                    </a-space>
                  </a-col>
                  <a-col flex="40px"></a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 150px"></div>
    <a-row type="flex" style="flex-wrap: nowrap">
      <a-col flex="1 0 10px"></a-col>
      <a-col flex="0 1 1180px">
        <div style="width: 100%; position: relative">
          <img src="./images/mask_group_1.png" style="width: 68%; object-fit: cover" />
          <div class="bottom-right-bg">
            <div style="position: relative; width: 100%; height: 100%">
              <div class="sevice-text-container">
                <div class="right-color-bg-container">
                  <a-row type="flex" class="right-color-bg">
                    <a-col flex="40px"></a-col>
                    <a-col flex="auto">
                      <a-space direction="vertical" :size="40" style="width: 100%">
                        <div style="height: 12px"></div>
                        <a-typography-title style="color: white">
                          {{ bottomTexts[1].title }}
                        </a-typography-title>
                        <a-typography-text style="font-size: 20px; color: white">
                          {{ bottomTexts[1].text }}
                        </a-typography-text>
                        <div style="height: 10px"></div>
                      </a-space>
                    </a-col>
                    <a-col flex="40px"></a-col>
                  </a-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <a-col flex="1 0 10px"></a-col>
    </a-row>
    <div style="height: 150px"></div>
    <front-footer></front-footer>
  </div>
</template>

<script setup>
  import Navbar from '@/views/index/front-navbar/web';
  import frontFooter from '@/views/index/front-xian/front-footer/web';
  import { frontConfig } from './frontConfig';
  const {
    mainTitle,
    subTitle,
    advantages,
    solutions,
    insurances,
    assetTexts,
    needProtecteds,
    bottomTexts,
    foundationServices,
  } = frontConfig();
</script>

<style scoped>
  .front-page {
    position: relative;
    overflow: hidden;
  }
  .header-blue-bg {
    background: linear-gradient(180deg, #4e45f4 0%, #4b58f3 100%);
  }
  .image-container {
    position: relative;
  }
  .blue-bg {
    background: linear-gradient(180deg, #4b58f3 0%, #476ff1 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .gray-container {
    background: #fafafa;
  }
  .sevice-text-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .color-bg-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .right-color-bg-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .left-color-bg {
    flex-grow: 0;
    background: linear-gradient(180deg, #fccc0a 0%, #f2c349 100%);
    flex-wrap: nowrap;
    border-radius: 55px;
  }
  .right-color-bg {
    flex-grow: 0;
    background: linear-gradient(180deg, #4c4cf3 0%, #476df3 100%);
    flex-wrap: nowrap;
    border-radius: 55px;
  }
  .arrow-decration {
    position: absolute;
    top: 240px;
    bottom: 0;
    left: -70px;
    right: 0;
  }
  .bottom-right-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 580px;
  }
  .bottom-left-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 580px;
  }
  @media only screen and (min-width: 1200px) {
    .blue-bg {
      height: 520px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .blue-bg {
      height: calc(100vw * 520 / 1180);
    }
  }
</style>
