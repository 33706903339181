const SET_LANGUAGE = 'SET_LANGUAGE';
let locale = window.localStorage.getItem('locale');
let lang = locale ? locale : 'zh';
const state = {
  language: lang,
};

const mutations = {
  [SET_LANGUAGE](state, payload) {
    window.localStorage.setItem('locale', payload.key);
    state.language = payload.key;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
