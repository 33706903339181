const SET_USER = 'SET_USER';
const CLEAR_USER = 'CLEAR_USER';

const state = {
  name: '',
  certificte: '',
  phonenumber: '',
  real_name: '',
  card_no: '',
  real_name_bind_phone: '',
  allowEditProfile: true,
};

const mutations = {
  [SET_USER](state, payload) {
    Object.assign(state, payload);
    if (payload.tag === 2) {
      state.allowEditProfile = false;
    }
  },
  [CLEAR_USER]() {
    Object.assign(state, {
      phonenumber: null,
      name: null,
      certificte: '',
      real_name: '',
      card_no: '',
      real_name_bind_phone: '',
      allowEditProfile: true,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
