import axios from 'axios';
import { ApiPromise } from '@/api/api-promise';
import config from '/config';
import qs from 'qs';
import JSONbig from 'json-bigint';

let versionPrefix = 'v1';
let baseUrl = config.baseAPI + '/' + versionPrefix;

let jsonBig = JSONbig({ useNativeBigInt: true });

let httpClient = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  transformResponse: [
    function (data) {
      if (!data) {
        return {};
      }
      if (config.apiContainBigint) {
        return jsonBig.parse(data);
      } else {
        return JSON.parse(data);
      }
    },
  ],
  transformRequest: [
    function (data, headers) {
      headers['Content-Type'] = 'application/json';
      if (config.apiContainBigint) {
        return jsonBig.stringify(data);
      } else {
        return JSON.stringify(data);
      }
    },
  ],
});

httpClient.interceptors.request.use((request) => {
  if (request.method === 'get') {
    request.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    };
  }
  return request;
});

httpClient.interceptors.response.use(
  (response) => {
    if (response.config.responseType === 'blob') {
      return Promise.resolve(response.data);
    } else if (response.status === 200) {
      // Normal response
      const data = (response.data && response.data.data) || response.data;
      return Promise.resolve(data);
    } else if (response.status === 400) {
      return Promise.reject(response.data);
    } else if (response.status === 500) {
      return Promise.reject(response.data);
    } else {
      // Exceptions
      return Promise.reject('server exception');
    }
  },
  (error) => {
    return Promise.reject(error);
  },
);

function post(url, data, config) {
  return new ApiPromise(httpClient.post(url, data, config));
}

function get(url, config) {
  return new ApiPromise(httpClient.get(url, config));
}

function put(url, data, config) {
  return new ApiPromise(httpClient.put(url, data, config));
}

function del(url, config) {
  return new ApiPromise(httpClient.delete(url, config));
}

export default { httpClient, post, get, put, del };
