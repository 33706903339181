import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export function onScreenSizeChange() {
  const screenWidth = ref(document.body.clientWidth);
  const screenHeight = ref(document.body.clientHeight);

  const store = useStore();
  store.commit('screenSize/SET_SIZE', {
    width: screenWidth.value,
    heiht: screenHeight.value,
  });
  const watchSize = () => {
    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        window.screenHeight = window.innerHeight;
        screenWidth.value = window.screenWidth;
        screenHeight.value = window.screenHeight;
        store.commit('screenSize/SET_SIZE', {
          width: window.screenWidth,
          height: window.screenHeight,
        });
        console.log('onresize: ' + screenWidth.value);
      })();
    };
  };

  onMounted(() => {
    watchSize();
  });

  return {
    screenWidth,
    screenHeight,
  };
}
