import { getCrypto } from 'pkijs/src/common';
import { arrayBufferToString, bufferToHexCodes, toBase64 } from 'pvutils';
import base64url from 'base64url';
import CryptoJS from 'crypto-js';

export function formatPEM(pemString) {
  const PEM_STRING_LENGTH = pemString.length,
    LINE_LENGTH = 64;
  const wrapNeeded = PEM_STRING_LENGTH > LINE_LENGTH;
  if (wrapNeeded) {
    let formattedString = '',
      wrapIndex = 0;

    for (let i = LINE_LENGTH; i < PEM_STRING_LENGTH; i += LINE_LENGTH) {
      formattedString += pemString.substring(wrapIndex, i) + '\r\n';
      wrapIndex = i;
    }

    formattedString += pemString.substring(wrapIndex, PEM_STRING_LENGTH);
    return formattedString;
  } else {
    return pemString;
  }
}
export function i2hex(i) {
  return ('0' + i.toString(16)).slice(-2);
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function binaryKeyToPEMString(binaryKeyData) {
  return `${formatPEM(toBase64(arrayBufferToString(binaryKeyData)))}`;
}

export function hexToArray(hexString) {
  return new Uint8Array(
    hexString.match(/[\da-f]{2}/gi).map(function (h) {
      return parseInt(h, 16);
    }),
  );
}

async function getMasterKey(salt, password) {
  const enc = new TextEncoder();

  const passwordMaterial = await window.crypto.subtle.importKey(
    'raw',
    enc.encode(password),
    {
      name: 'PBKDF2',
    },
    false,
    ['deriveBits', 'deriveKey'],
  );

  return await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: 100000,
      hash: 'SHA-256',
    },
    passwordMaterial,
    {
      name: 'AES-CBC',
      length: 256,
    },
    true,
    ['wrapKey', 'unwrapKey', 'decrypt'],
  );
}
async function readSlice(file, start, size) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    const slice = file.slice(start, start + size);

    fileReader.onload = () => resolve(new Uint8Array(fileReader.result));
    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(slice);
  });
}
export const keyPairUtil = {
  createKeypair: async () => {
    return await window.crypto.subtle.generateKey(
      {
        name: 'ECDSA',
        namedCurve: 'P-256',
      },
      true,
      ['sign', 'verify'],
    );
  },
  sign: async (privateKey, text) => {
    let crypto = getCrypto(),
      sText = str2ab(text.replace(/\r/g, '').replace(/\s/g, '')),
      signedArr = await crypto.sign(
        { name: 'ECDSA', hash: { name: 'SHA-256' } },
        privateKey,
        sText,
      );
    return new Buffer(signedArr).toString('hex');
  },
  verify: async (pubKey, signature, text) => {
    let crypto = getCrypto(),
      signBuffer = hexToArray(signature),
      sText = str2ab(text.replace(/\r/g, '').replace(/\s/g, ''));
    return await crypto.verify(
      { name: 'ECDSA', hash: { name: 'SHA-256' } },
      pubKey,
      signBuffer,
      sText,
    );
  },
  importPrivateKeyFromPEM: async (privateKeyPEM, password) => {
    privateKeyPEM = privateKeyPEM.replace('-----BEGIN PRIVATE KEY-----', '');
    privateKeyPEM = privateKeyPEM.replace('-----END PRIVATE KEY-----', '');

    let iv = privateKeyPEM.match(/DEK-Info:.*,([A-F\d]{32})/);
    iv = iv[1];

    privateKeyPEM = privateKeyPEM.replace(/DEK-Info:.*,([A-F\d]{32})/, '');
    privateKeyPEM = privateKeyPEM.replace('Proc-Type: 4,ENCRYPTED', '');
    privateKeyPEM = privateKeyPEM.replace(/[\r\n]/g, '');

    const binaryDerString = window.atob(privateKeyPEM);
    const binaryDer = str2ab(binaryDerString);

    // Unwrap the key before importing
    let ivArray = hexToArray(iv);
    let salt = ivArray.slice(0, 7);
    const derivedEncryptionKey = await getMasterKey(salt, password);

    return await window.crypto.subtle.unwrapKey(
      'pkcs8',
      binaryDer,
      derivedEncryptionKey,
      {
        name: 'AES-CBC',
        iv: ivArray,
      },
      {
        name: 'ECDSA',
        namedCurve: 'P-256',
      },
      true,
      ['sign'],
    );
  },
  exportPublicKey: async (publicKey) => {
    let rawPublicKey = await window.crypto.subtle.exportKey('spki', publicKey);

    let publicKeyString = '-----BEGIN PUBLIC KEY-----\r\n';
    publicKeyString += binaryKeyToPEMString(rawPublicKey) + '\r\n';
    publicKeyString += `-----END PUBLIC KEY-----\r\n`;

    return publicKeyString;
  },
  exportRawHexPrivateKey: async (privateKey) => {
    const exported = await window.crypto.subtle.exportKey('jwk', privateKey);
    let buffer = base64url.toBuffer(exported.d);
    return buffer.toString('hex');
  },
  exportPrivateKey: async (privateKey, password) => {
    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const salt = iv.slice(0, 7);
    const derivedEncryptionKey = await getMasterKey(salt, password);
    const wrappedPrivateKey = await window.crypto.subtle.wrapKey(
      'pkcs8',
      privateKey,
      derivedEncryptionKey,
      {
        name: 'AES-CBC',
        iv: iv,
      },
    );
    let privateKeyString = '-----BEGIN PRIVATE KEY-----\r\n';
    privateKeyString += 'Proc-Type: 4,ENCRYPTED\r\n';
    privateKeyString += 'DEK-Info: AES-256-CBC,' + bufferToHexCodes(iv.buffer) + '\r\n\r\n';
    privateKeyString += binaryKeyToPEMString(wrappedPrivateKey) + '\r\n';
    privateKeyString += '-----END PRIVATE KEY-----\r\n';
    return privateKeyString;
  },
  async getRawPrivateKeyPEM(privateKey) {
    let raw = await window.crypto.subtle.exportKey('pkcs8', privateKey);

    return binaryKeyToPEMString(raw);
  },
  async importPubKey(pubKeyPEM) {
    let pubKey = pubKeyPEM.replace('-----BEGIN PUBLIC KEY-----', '');
    pubKey = pubKey.replace('-----BEGIN PUBLIC KEY-----', '');
    pubKey = pubKey.replace('-----END PUBLIC KEY-----', '');
    pubKey = pubKey.replace(/[\r\n]/g, '');
    let binaryDerString = window.atob(pubKey),
      binaryDer = str2ab(binaryDerString);
    return await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      { name: 'ECDSA', namedCurve: 'P-256' },
      true,
      ['verify'],
    );
  },
  async largesha256(file) {
    let sha256 = CryptoJS.algo.SHA256.create();
    const sliceSize = 10485760; // 10 MiB
    let start = 0;
    while (start < file.size) {
      const slice = await readSlice(file, start, sliceSize);
      const wordArray = CryptoJS.lib.WordArray.create(slice);
      sha256 = sha256.update(wordArray);
      start += sliceSize;
    }

    sha256.finalize();
    return sha256._hash.toString();
  },

  async digestMessage(file) {
    const hashBuffer = await crypto.subtle.digest('SHA-256', file);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    // convert bytes to hex string
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  },
};
