import { i18nText } from '@/lang';

const PROOF_STATUS = [
  {
    value: 1,
    label: i18nText('生成中'),
  },
  {
    value: 2,
    label: i18nText('生成中'),
  },
  {
    value: 3,
    label: i18nText('生成中'),
  },
  {
    value: 4,
    label: i18nText('生成中'),
  },
  {
    value: 5,
    label: i18nText('已生成'),
  },
  {
    value: 6,
    label: i18nText('已生成'),
  },
];

export default {
  fileProofStatus: (status) => {
    let lst = PROOF_STATUS.filter((itm) => itm.value == status);
    return lst && lst.length > 0 ? lst[0].label : '';
  },
};
