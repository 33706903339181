<template>
  <div class="front-page">
    <div class="header-blue-bg">
      <mobile-navbar></mobile-navbar>
      <div style="height: 44px"></div>
      <a-row justify="space-around">
        <a-col :span="20">
          <a-typography-title style="color: white; text-align: center">
            提供数字知识财产从创造、使用、保护到管理的全链条服务
          </a-typography-title>
        </a-col>
      </a-row>
      <div style="height: 22px"></div>
    </div>
    <div class="image-container">
      <div class="blue-bg"></div>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="15px"></a-col>
        <a-col flex="0 1 auto">
          <img src="./images/shouping.png" style="width: 100%; object-fit: cover" />
        </a-col>
        <a-col flex="15px"></a-col>
      </a-row>
    </div>
    <div style="height: 40px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="38px"></a-col>
      <a-col flex="1 1 auto">
        <a-row justify="space-around">
          <a-col
            v-for="(item, index) in advantages"
            :key="index"
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
          >
            <a-space direction="vertical" align="center" style="width: 100%" :size="4">
              <img :src="item.image" style="width: 66px; height: 66px" />
              <div style="height: 8px"></div>
              <a-typography-title :level="4">{{ item.title }}</a-typography-title>
              <div
                style="font-size: 18px; text-align: center"
                :style="{ width: index == 2 ? '300px' : '240px' }"
              >
                {{ item.description }}
              </div>
              <div style="height: 40px"></div>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="38px"></a-col>
    </a-row>
    <div style="height: 150px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="0 1 auto">
        <a-row :gutter="120">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <a-space direction="vertical" :size="12">
              <img src="./images/shuzizhishicaichan_1.png" style="width: 41px; height: 38px" />
              <div style="height: 12px"></div>
              <a-typography-title :level="3">数字知识财产</a-typography-title>
              <a-typography-text style="font-size: 18px">
                知识财产是人类在社会实践中创造的智力劳动成果。而在数字化时代,
                海量知识财产（数据）的创造、管理和使用面临着前所未有的挑战。
              </a-typography-text>
              <a-typography-text style="font-size: 18px">
                数字知识产权服务平台以“数字化确权”作为知识财产保护的起点,
                快速为企业及个人建立知识财产保险箱, 在保护知识产权不被侵犯的前提下,
                通过隐私计算、人工智能和区块链技术促进知识财产价值的全面释放。
              </a-typography-text>
              <a-typography-text style="font-size: 18px">
                数字知识产权服务平台降低了知识财产生产、使用和保护的成本，在更大范围内促进知识财产的创造和流通，从而提升整个社会的创新能力。
              </a-typography-text>
              <div style="height: 24px"></div>
            </a-space>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <img src="./images/shuzizhishicaichan.png" style="width: 100%; object-fit: cover" />
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 76px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="0 1 auto">
        <a-row :gutter="120">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <a-space direction="vertical" :size="12">
              <img src="./images/shuzihuaquequan_1.png" style="width: 40px; height: 40px" />
              <div style="height: 12px"></div>
              <a-typography-title :level="3">数字化确权</a-typography-title>
              <a-typography-text style="font-size: 18px">
                数字化确权通过技术手段为数据生成不可篡改的证据，证明数据在某一个时间点存在。用于防止数据的滥用和盗用，解决相关法律纠纷。
              </a-typography-text>
              <a-typography-text style="font-size: 18px">
                和传统的知识产权保护手段相比，数字化确权具有保护隐私、低成本和快捷的优势。可以方便且安全地嵌入数据生产的流程中，提供更细粒度的产权证明和制造过程存证。
              </a-typography-text>
              <a-typography-text style="font-size: 18px">
                数字化确权是传统知识产权保护手段的有力补充，在知识产权的前置确权阶段发挥作用，为传统知识产权的确权和维权提供更有力的保障。
              </a-typography-text>
              <div style="height: 24px"></div>
            </a-space>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <img src="./images/shuzihuaquequan.png" style="width: 100%; object-fit: cover" />
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 60px"></div>
    <div class="gray-container">
      <a-space direction="vertical" style="width: 100%" :size="48">
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap" align="bottom">
          <a-col flex="15px"></a-col>
          <a-col flex="1 1">
            <a-row justify="center">
              <a-typography-title :level="2">哪些财产需要被保护?</a-typography-title>
            </a-row>
          </a-col>
          <a-col flex="15px">
            <a-space direction="vertical">
              <div style="height: 36px"></div>
              <img src="./images/xiushi_2.png" style="width: 15px; height: 73px" />
            </a-space>
          </a-col>
        </a-row>
        <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
          <a-col flex="54px"></a-col>
          <a-col flex="1 1 auto">
            <a-row justify="center" :gutter="40">
              <a-col
                v-for="(item, index) in needProtecteds"
                :key="index"
                :xs="24"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
              >
                <a-space direction="vertical" align="center">
                  <img :src="item.image" style="width: 100%" />
                  <div style="height: 20px"></div>
                  <a-typography-title :level="4">{{ item.title }}</a-typography-title>
                  <div style="font-size: 18px; text-align: center; width: 258px">
                    {{ item.description }}
                  </div>
                  <div style="height: 48px"></div>
                </a-space>
              </a-col>
            </a-row>
          </a-col>
          <a-col flex="54px"></a-col>
        </a-row>
      </a-space>
      <div style="height: 10px"></div>
    </div>
    <div style="height: 38px"></div>
    <a-space direction="vertical" style="width: 100%" :size="60">
      <a-row justify="center">
        <a-typography-title :level="3">解决方案</a-typography-title>
      </a-row>
      <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
        <a-col flex="48px"></a-col>
        <a-col flex="1 1 auto">
          <a-row justify="center" :gutter="24">
            <a-col
              v-for="(item, index) in solutions"
              :key="index"
              :xs="24"
              :sm="24"
              :md="6"
              :lg="6"
              :xl="6"
            >
              <a-card
                style="
                  border-radius: 24px;
                  border: 2px solid #315aff;
                  box-shadow: 0px 3px 0px #315aff;
                "
              >
                <a-space direction="vertical" style="background: #f5f5f5; border-radius: 22px">
                  <div style="height: 26px"></div>
                  <a-row type="flex" style="flex-wrap: nowrap">
                    <a-col flex="16px"></a-col>
                    <a-col flex="0 1 auto">
                      <a-typography-title :level="3">{{ item.title }}</a-typography-title>
                      <a-row style="height: 54px">
                        <a-typography-text style="font-size: 16px">
                          {{ item.description }}
                        </a-typography-text>
                      </a-row>
                    </a-col>
                    <a-col flex="16px"></a-col>
                  </a-row>
                  <img :src="item.image" style="width: 100%" />
                </a-space>
              </a-card>
              <div style="height: 40px"></div>
            </a-col>
          </a-row>
        </a-col>
        <a-col flex="48px"></a-col>
      </a-row>
    </a-space>
    <div style="height: 48px"></div>
    <a-row justify="center">
      <a-typography-title :level="2">“数字钱包”</a-typography-title>
    </a-row>
    <div style="height: 76px"></div>
    <a-row type="flex" justify="space-between" style="flex-wrap: nowrap">
      <a-col flex="15px"></a-col>
      <a-col flex="0 1 auto">
        <a-row>
          <a-col :span="24">
            <img src="./images/shuziqianbao.png" style="width: 100%; object-fit: cover" />
            <div style="height: 40px"></div>
          </a-col>
          <a-col :span="24">
            <a-space direction="vertical" :size="18">
              <img src="./images/yinhao.png" style="width: 68px; height: 42px" />
              <a-typography-text style="font-size: 20px">
                使用密码学技术为企业生成唯一的数字身份。使用数字身份对企业内数字财产进行签名，可证明企业对数字财产的所有权。
                企业数字身份保管于基座内置的专用硬件加密芯片，没有密钥丢失风险。全套签名方案使用国密算法，符合国家安全标准要求。
                高性能软硬件一体架构设计，支持海量高并发的企业数字财产快速签名认证，有效避免延迟认证带来的侵权风险。
              </a-typography-text>
            </a-space>
          </a-col>
        </a-row>
      </a-col>
      <a-col flex="15px"></a-col>
    </a-row>
    <div style="height: 68px"></div>
    <front-footer></front-footer>
  </div>
</template>

<script setup>
  import MobileNavbar from '../front-navbar/mobile';
  import frontFooter from '../front-footer/mobile';
  import { frontConfig } from './frontConfig';
  const { advantages, solutions, needProtecteds } = frontConfig();
</script>

<style scoped>
  .front-page {
    position: relative;
    overflow: hidden;
  }
  .header-blue-bg {
    background: #456ff4;
  }
  .image-container {
    position: relative;
  }
  .blue-bg {
    background: #456ff4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100vw * 0.45);
    z-index: -1;
  }
  .gray-container {
    background: #fafafa;
  }
</style>
