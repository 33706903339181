<template>
  <div style="width: 100%; height: 100%; padding: 50px; font-size: 14px">
    <div class="dark-bg-text" style="width: 100%">
      <h1>{{ webTitle }}使用条款</h1>
      <p><strong>用户身份保证</strong></p>
      <p>
        用户承诺并保证其具有完全民事行为能力和完全民事权利能力的自然人，或依法设立并有效存续的单位（包括企业法人和其他组织）。
        用户保证其所填写的用户信息真实、准确、完整，且没有任何引人误解或者虚假的陈述，同时不得侵犯他人的知识产权及合法权益。
        {{ webTitle }}将依据用户所填写的联系方式与其取得联系，若有变动应及时通知平台；
      </p>
      <p>
        用户应根据
        {{ webTitle }}
        的要求及时提供其身份证明或身份信息等资料，否则有权拒绝该用户注册申请，并拒绝提供相关服务。
      </p>
      <p>用户承诺将及时更新其用户信息以维持其信息有效性。</p>
      <p>
        用户以代理人身份代理其他自然人或者企业法人进行注册的，须向{{
          webTitle
        }}提供代理人和被代理人的详细资料、信息及书面授权委托书。
        未向平台提供上述资料信息及文件的，{{ webTitle }}将视该注册者为会员。
      </p>
      <br />
      <p><strong>会员身份确认</strong></p>
      <br />
      <p>用户注册成功后将得到一个用户名和密码，用户凭用户名和密码享受公司向其提供各项服务。</p>
      <p>
        用户应妥善保管其用户名和密码，并对用户名和密码安全负全部责任，任何使用其用户名和密码进行的活动和事件都视为该用户的操作。
      </p>
      <p>
        用户若发现任何非法使用其帐号的行为或可能存在安全漏洞的情况，请立即通知公司，紧急修改该密码。因黑客行为或用户的保管不善导致账号、密码被他人非法使用，公司不承担责任。
      </p>
      <br />
      <p><strong>服务条款的修改和服务体系修订</strong></p>
      <br />
      <p>
        {{
          webTitle
        }}有权在必要时修改本服务协议条款，若服务协议条款发生变动，平台将会在重要页面向用户提示修改内容。
        用户若不同意可主动取消其获得的网络服务。若用户继续使用平台服务，则视为接受服务协议条款的变动内容。
      </p>
      <p>使用条款适用范围</p>
      <p>
        本条款适用于{{ webTitle }}向您提供的各项产品和服务，具体包括{{ webTitle }}业务系统、
        数字知识产权登记插件(如：Word插件、Excel插件、AutoCAD插件)等。除本条款说明的相关信息收集使用活动外，
        本条款不适用于数字知识产权保护用户及其他第三方向您提供的其他服务。
      </p>
      <br />
      <p><strong>用户的权利和义务</strong></p>
      <br />
      <p>
        用户有权拥有其在公司注册时取得的用户名和密码，并有权使用该用户名和密码在任一时间、任一地点登录公司网站。
      </p>
      <p>
        用户有权享受{{ webTitle }}依据本服务协议条款提供的互联网技术和信息服务，
        并有权利在接受服务时获得{{ webTitle }}技术支持、咨询等服务。
      </p>
      <p>
        用户在履行本服务协议条款中不侵犯公司及任何第三方的知识产权或其他合法权利。若有侵权行为，其给其他第三方造成的经济损失，由用户自己承担。
        若因用户的作品侵权导致公司对外承担赔偿责任的，用户赔偿公司因此产生的全部经济损失。
      </p>
      <p>
        用户应保证遵守中国法律、法规、行业惯例和社会公共道德，不得利用公司提供的服务存储、发布、传播下述信息或内容：
      </p>
      <p>违反国家法律、法规的任何内容或信息；</p>
      <p>违反国家有关政治或新闻宣传规定的信息；</p>
      <p>涉及国家秘密或或危害国家安全的信息；</p>
      <p>涉及封建迷信或淫秽、色情、教唆犯罪等信息；</p>
      <p>涉及博彩有奖、赌博游戏等信息；</p>
      <p>违反国家民族和宗教政策的信息；</p>
      <p>防碍互联网运行安全的信息；</p>
      <p>侵害他人合法权益的信息；</p>
    </div>
    <br />
  </div>
</template>

<script setup>
  import config from '/config';
  import { computed } from 'vue';
  const webTitle = computed(() => config.webTitle);
</script>

<style scoped>
  p {
    table-layout: fixed;
    word-wrap: break-word;
    word-break: normal;
    overflow: hidden;
  }
</style>
