<template>
  <a-empty style="margin-top: 200px">
    <template #description>
      <span>{{ $t('未找到搜索结果!') }}</span>
    </template>
  </a-empty>
</template>

<script setup></script>

<style scoped></style>
