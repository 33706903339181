<template>
  <a-layout>
    <a-layout-header v-if="false">
      <navbar :show-login="false" :settingVisible="false" :showSearch="false" />
    </a-layout-header>
    <a-layout-content>
      <a-row align="center">
        <div class="auth-container">
          <div class="step-header">
            <a-steps v-model:current="authStep" type="navigation" :style="stepStyle">
              <a-step :status="stepStatus(0)" :title="i18nText('实名认证')" disabled />
              <a-step :status="stepStatus(1)" :title="i18nText('加入/创建企业')" disabled />
            </a-steps>
          </div>
          <div v-if="authStep == 0">
            <id-card @finishRealAuth="finishRealAuth"></id-card>
          </div>
          <div class="second-step company-auth" v-else>
            <div class="company-select" v-if="showCompanyOption">
              <a-space direction="vertical" align="center" :size="30">
                <a-typography-text strong>{{ $t('您还需选择以下操作') }}</a-typography-text>
                <div class="operation-container">
                  <a-space
                    class="create-company company-operation"
                    direction="vertical"
                    @click="goCreate"
                  >
                    <home-outlined style="font-size: 34px; color: #2b55bb"></home-outlined>
                    <a-button class="button-bg">{{ $t('创建企业') }}</a-button>
                  </a-space>
                  <a-space
                    class="company-operation join-company"
                    direction="vertical"
                    @click="goJoin"
                  >
                    <block-outlined style="font-size: 34px; color: #2b55bb"></block-outlined>
                    <a-button class="button-bg">{{ $t('加入企业') }}</a-button>
                  </a-space>
                </div>
              </a-space>
            </div>
            <license v-if="showCreate"></license>
            <join-company
              v-if="showJoin"
              @backToCompanyOperation="backToCompanyOperation"
            ></join-company>
          </div>
        </div>
      </a-row>
    </a-layout-content>
  </a-layout>
</template>

<script>
  import UserModel from '@/model/user';

  export default {
    beforeRouteEnter(to, from, next) {
      UserModel.getMyUserInfo();
      next();
    },
  };
</script>

<script setup>
  import navbar from '@/views/navbar';
  import IdCard from '@/views/index/real-auth/ocr-auth/components/id-card';
  import license from '@/views/index/real-auth/ocr-auth/components/license';
  import JoinCompany from '@/views/index/real-auth/ocr-auth/components/join-company';
  import { computed, ref } from 'vue';
  import { HomeOutlined, BlockOutlined } from '@ant-design/icons-vue';
  import { useStore } from 'vuex';
  import { i18nText } from '@/lang';

  const authStep = ref(0);
  const showCompanyOption = ref(true);
  const showCreate = ref(false);
  const showJoin = ref(false);
  const store = useStore();
  const userCardNum = computed(() => store.state.user.card_no);
  if (userCardNum.value) {
    authStep.value = 1;
  }
  const finishRealAuth = () => {
    authStep.value = 1;
  };
  const stepStyle = {
    marginBottom: '60px',
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
  };
  const goCreate = () => {
    showCompanyOption.value = false;
    showCreate.value = true;
  };
  const goJoin = () => {
    showCompanyOption.value = false;
    showJoin.value = true;
  };
  const backToCompanyOperation = () => {
    showCompanyOption.value = true;
    showJoin.value = false;
    showCreate.value = false;
  };
  const stepStatus = (index) => {
    if (index > authStep.value) {
      return 'wait';
    } else if (index == authStep.value) {
      return 'process';
    } else {
      return 'finish';
    }
  };
</script>

<style scoped>
  .auth-container {
    width: 550px;
    height: auto;
    margin: 0 auto;
    background: white;
    margin-top: 50px;
  }

  .step-header {
    height: 120px;
    background: theme-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .company-select {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .operation-container {
    display: flex;
  }

  .company-operation {
    display: flex;
    flex-direction: column;
    width: 246px;
    height: 153px;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    color: @primary-color;
    margin: 0px 30px;
    background: #fafafa;
  }

  .company-operation:hover {
    border: 1px solid #2b55bb;
  }

  .button-bg {
    border: none;
    margin: 0px 30px;
    background: transparent;
    box-shadow: none;
  }
</style>
