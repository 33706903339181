<template>
  <div class="front-page">
    <a-space direction="vertical" style="width: 100%; background: white" align="center" :size="30">
      <div style="height: 140px"></div>
      <img src="./images/yinhao.png" style="width: 68px; height: 42px" />
      <a-typography-title :level="2">成为合作伙伴，探索全新领域，拓展服务边界</a-typography-title>
      <div style="position: relative">
        <a-row type="flex" justify="center">
          <a-col>
            <img src="./images/bg.png" style="width: 1920px" />
          </a-col>
        </a-row>
        <div style="position: absolute; width: 100%; height: 100%; top: 0; left: 0">
          <a-space direction="vertical" align="center" style="width: 100%">
            <a-typography-title style="font-size: 54px">
              共同打造数字资产全新生态
            </a-typography-title>
            <div style="height: 80px"></div>
            <img class="join-button" src="./images/join.png" @click="joinModalVisible = true" />
          </a-space>
        </div>
        <join-modal v-if="joinModalVisible" @reset="joinModalVisible = false"></join-modal>
      </div>
      <div style="height: 100px"></div>
    </a-space>
  </div>
</template>

<script setup>
  import JoinModal from './join-modal';
  import { ref } from 'vue';

  const joinModalVisible = ref(false);
</script>

<style scoped>
  .image-container {
    position: absolute;
    width: 344px;
    height: 344px;
  }
  .first-image-container {
    bottom: 40px;
    left: 15%;
  }
  .second-image-container {
    bottom: -120px;
    left: 36%;
  }
  .third-image-container {
    bottom: 60px;
    left: 66%;
  }
  .join-button {
    background: #4e4af4;
    border-radius: 10px;
    width: 220px;
    height: 64px;
    font-size: 26px;
    z-index: 99;
    cursor: pointer;
  }
  .join-button:hover {
    opacity: 0.95;
  }
</style>
