<template>
  <div class="auth-content">
    <div class="id-image-content">
      <a-space direction="vertical" size="large">
        <div class="id-required">
          <a-typography-text strong>{{ $t('要求:') }}</a-typography-text>
          <br />
          <a-typography-text>
            {{ $t('请上传清晰的身份证原件正、反面照片，系统将自动识别证件信息。') }}
          </a-typography-text>
          <br />
          <a-typography-text>{{ $t('请使用与手机号一致的身份实名。') }}</a-typography-text>
          <br />
          <a-typography-text>{{ $t('图片的具体需求:') }}</a-typography-text>
          <br />
          <a-typography-text>{{ $t('1、图片需要小于3M') }}</a-typography-text>
          <br />
          <a-typography-text>{{ $t('2、图像格式:JPEG、JPG、PNG、BMP、GIF') }}</a-typography-text>
          <br />
        </div>
        <div v-if="formError">
          <a-alert :message="formError" type="error" show-icon />
        </div>
        <a-row :gutter="58">
          <a-col :span="12">
            <div class="id-image-item">
              <a-space direction="vertical" size="middle">
                <a-typography-text strong>{{ $t('身份证人像面') }}</a-typography-text>
                <a-space direction="vertical" align="center" size="middle">
                  <div class="image-sample-item">
                    <div class="image-corner">
                      <a-image :preview="false" :src="idCornerImage"></a-image>
                    </div>
                    <div class="id-sample id-avatar">
                      <img
                        style="width: 120px; height: 74px; object-fit: cover"
                        :src="avatarImage"
                      />
                    </div>
                  </div>
                  <a-upload
                    class="upload-avatar"
                    :file-list="avatarFiles"
                    :max-count="1"
                    :show-upload-list="false"
                    accept=".jpeg, .jpg, .png, .bmp, .gif"
                    @change="avatarSelected"
                  >
                    <a-button :loading="selectedAvatar.loading">{{ $t('点击上传') }}</a-button>
                  </a-upload>
                </a-space>
              </a-space>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="id-image-item">
              <a-space direction="vertical" size="middle">
                <a-typography-text strong>{{ $t('身份证国徽面') }}</a-typography-text>
                <a-space direction="vertical" align="center" size="middle">
                  <div class="image-sample-item">
                    <div class="image-corner">
                      <a-image :preview="false" :src="insigniaCornerImage"></a-image>
                    </div>
                    <div class="id-sample id-insignia">
                      <img
                        style="width: 120px; height: 74px; object-fit: cover"
                        :src="insigniaImage"
                      />
                    </div>
                  </div>
                  <a-upload
                    class="upload-insignia"
                    :file-list="insigniaFiles"
                    :max-count="1"
                    :show-upload-list="false"
                    accept=".jpeg, .jpg, .png, .bmp, .gif"
                    @change="insigniaSelected"
                  >
                    <a-button :loading="selectedInsignia.loading">{{ $t('点击上传') }}</a-button>
                  </a-upload>
                </a-space>
              </a-space>
            </div>
          </a-col>
        </a-row>
        <div class="button-box">
          <a-button
            class="commit-button"
            :loading="loading"
            type="primary"
            :disabled="nextDisabled"
            size="large"
            @click="validateID"
          >
            {{ $t('认证') }}
          </a-button>
        </div>
      </a-space>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { keyPairUtil } from '@/library/crypto/keypair';
  import { useStore } from 'vuex';
  import UserAPI from '@/api/v1/users';
  import ErrorMessage from '@/model/errorMessage';
  import UploadFileAPI from '@/api/v1/upload-file';
  import UserModel from '@/model/user';
  import { i18nText } from '@/lang';

  const emit = defineEmits(['finishRealAuth']);
  const store = useStore();
  const user = computed(() => store.state.user);

  const loading = ref(false);

  const formIdError = ref('');
  const formInsigniaError = ref('');
  const formError = computed(() => {
    if (formInsigniaError.value) {
      return formInsigniaError.value;
    }
    if (formIdError.value) {
      return formIdError.value;
    }
    return null;
  });

  const avatarFiles = ref([]);
  const selectedAvatar = reactive({
    loading: false,
    avatarPath: '',
    avatarName: '',
    avatarImage: null,
    avatarHash: '',
  });

  //选中的头像
  const idAvatar = reactive({ avatarPath: null });

  const avatarSelected = async (file) => {
    formIdError.value = null;
    const flieArr = file.file.name.split('.');
    let suffix = flieArr[flieArr.length - 1];
    suffix = suffix.toLocaleLowerCase();
    let sha256 = await keyPairUtil.largesha256(file.file.originFileObj);
    let fileHash = `0x${sha256}`;
    fileHash = fileHash + '.' + suffix;

    uploadImagePrepare('avatar', fileHash, file.file);
    uploadImage('avatar');
  };

  const insigniaFiles = ref([]);
  const insigniaSelected = async (file) => {
    formInsigniaError.value = null;

    const flieArr = file.file.name.split('.');
    let suffix = flieArr[flieArr.length - 1];
    suffix = suffix.toLocaleLowerCase();
    let sha256 = await keyPairUtil.largesha256(file.file.originFileObj);
    let fileHash = `0x${sha256}`;
    fileHash = fileHash + '.' + suffix;

    uploadImagePrepare('insignia', fileHash, file.file);
    uploadImage('insignia');
  };

  const selectedInsignia = reactive({
    loading: false,
    insigniaPath: '',
    insigniaName: '',
    insigniaImage: null,
    insigniaHash: '',
  }); // 选中的国徽
  const idInsignia = reactive({ insigniaPath: null });

  const idResult = ref(null);
  const nextDisabled = computed(() => {
    return !(idResult.value && idInsignia.insigniaPath);
  });
  const authForm = reactive({ name: '', idNum: '', phonenumber: '' });

  const avatarImage = computed(() =>
    idAvatar.avatarPath ? idAvatar.avatarPath : require('../images/avatar.png'),
  );
  const insigniaImage = computed(() =>
    idInsignia.insigniaPath ? idInsignia.insigniaPath : require('../images/insignia.png'),
  );

  const idCornerImage = computed(() => {
    if (idResult.value) {
      return require('../images/corner_success.png');
    } else if (formIdError.value) {
      return require('../images/corner_error.png');
    }
    return require('../images/corner_wait.png');
  });

  const insigniaCornerImage = computed(() => {
    if (idInsignia.insigniaPath) {
      return require('../images/corner_success.png');
    } else if (formInsigniaError.value) {
      return require('../images/corner_error.png');
    }
    return require('../images/corner_wait.png');
  });

  const userIdCardNum = computed(() => store.state.user.card_no);
  const validateID = async () => {
    await UserModel.getMyUserInfo();
    if (userIdCardNum.value) {
      emit('finishRealAuth');
    }
  };

  const uploadImagePrepare = (path, fileHash, file) => {
    formIdError.value = null;
    formInsigniaError.value = null;
    if (path === 'avatar') {
      selectedAvatar.avatarPath = URL.createObjectURL(file.originFileObj);
      selectedAvatar.avatarImage = file.originFileObj;
      selectedAvatar.avatarName = file.name;
      selectedAvatar.avatarHash = fileHash;
    } else if (path === 'insignia') {
      selectedInsignia.insigniaPath = URL.createObjectURL(file.originFileObj);
      selectedInsignia.insigniaImage = file.originFileObj;
      selectedInsignia.insigniaName = file.name;
      selectedInsignia.insigniaHash = fileHash;
    }
  };
  const uploadImage = (path) => {
    let imageObject = getImageHash(path);
    let imageHash = imageObject.hash;
    let ocrParams = imageObject.ocrParams;
    return UploadFileAPI.getUploadInfo(imageHash)
      .then((response) => {
        return response;
      })
      .then((res) => {
        let url = res.url;
        let params = res.body_params;
        return UploadFileAPI.uploadFile(ocrParams.name, ocrParams.image, url, params)
          .then(() => {
            if (path === 'avatar') {
              formIdError.value = null;
            } else if (path === 'insignia') {
              formInsigniaError.value = null;
            }
            return true;
          })
          .catch(() => {
            if (path === 'avatar') {
              formIdError.value = i18nText('上传失败');
            } else if (path === 'insignia') {
              formInsigniaError.value = i18nText('上传失败');
            }
          });
      })
      .then(() => {
        if (path === 'avatar') {
          return UserAPI.ocrIDAvatar(imageHash);
        } else if (path === 'insignia') {
          return UserAPI.ocrIDInsignia(imageHash);
        }
      })
      .then((response) => {
        if (path === 'avatar') {
          formIdError.value = null;
        } else if (path === 'insignia') {
          formInsigniaError.value = null;
        }
        handleUploadSuccess(path, response);
      })
      .catch((error) => {
        try {
          let errorMessage =
            error.response.data.data.message ||
            (error.response.data.message && error.response.data.data);
          let showMessage = ErrorMessage.getLocalMessage(errorMessage);
          if (showMessage) {
            if (path === 'avatar') {
              formIdError.value = showMessage;
            } else if (path === 'insignia') {
              formInsigniaError.value = showMessage;
            }
          } else {
            if (path === 'avatar') {
              formIdError.value = error.toString();
            } else if (path === 'insignia') {
              formInsigniaError.value = error.toString();
            }
          }
        } catch (e) {
          if (path === 'avatar') {
            formIdError.value = error.toString();
          } else if (path === 'insignia') {
            formInsigniaError.value = error.toString();
          }
        }
      })
      .finally(() => {
        handleUploadFinish(path);
      });
  };
  const getImageHash = (path) => {
    let image = {};
    if (path === 'avatar') {
      selectedAvatar.loading = true;
      image.hash = selectedAvatar.avatarHash;
      image.ocrParams = {
        name: selectedAvatar.avatarName,
        image: selectedAvatar.avatarImage,
      };
    } else if (path === 'insignia') {
      selectedInsignia.loading = true;
      image.hash = selectedInsignia.insigniaHash;
      image.ocrParams = {
        name: selectedInsignia.insigniaName,
        image: selectedInsignia.insigniaImage,
      };
    }
    return image;
  };
  const handleUploadSuccess = (path, response) => {
    if (path === 'avatar') {
      idResult.value = response;
      idAvatar.avatarPath = selectedAvatar.avatarPath;
      authForm.idNum = response.card_no;
      authForm.name = response.name;
    } else if (path === 'insignia') {
      idInsignia.insigniaPath = selectedInsignia.insigniaPath;
    }
  };
  const handleUploadFinish = (path) => {
    if (path === 'avatar') {
      selectedAvatar.loading = false;
    } else if (path === 'insignia') {
      selectedInsignia.loading = false;
    }
  };

  onMounted(() => {
    authForm.phonenumber = user.value.phonenumber;
  });
</script>

<style scoped>
  .id-image-content {
    padding: 20px 0px 32px 0px;
  }

  .id-required {
    line-height: 26px;
  }

  .id-image-item {
    position: relative;
  }

  .image-sample-item {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 246px;
    height: 153px;
  }

  .image-corner {
    width: 142px;
    height: 90px;
    z-index: 9;
  }

  .id-sample {
    position: absolute;
    top: 24%;
    bottom: 24%;
    left: 20%;
    right: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    /*box-shadow: 0px 0px 8px 1px #e3e3fa;*/
  }

  .button-box {
    text-align: center;
  }

  .commit-button {
    margin-top: 46px;
  }
</style>
