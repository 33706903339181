module.exports = {
  components: {
    '@/views/dashboard/content/asset-upload':
      '@/views/dashboard/content/upload-files/upload-with-desc',
    '@/views/dashboard/content/project': '@/views/dashboard/content/upload-files/upload-with-desc',
    '@/views/dashboard/content/update-file':
      '@/views/dashboard/content/upload-files/update-with-desc',
  },
  navbarLogoImageName: 'hn_navbar_logo.png',
  logoImageName: 'hn_login_logo.png',
  blockBrowserText: '区块链浏览器',
  fileVerifyCenterText: '文件验证中心',
  corporationCompany: '',
  serviceSystem: '',
  usernameAvailableWhenSignup: false,
  projectIdIsStringType: true,
  needGetUserNameFromAPIInHome: false,
  needEnterpriseAuth: true,
  modifyUserNameAvaliable: false,
  enterpriseChainCommitmentVisible: true,
  signContentVisible: false,
  fileSaveInCourtVisible: true,
  frontPageAvailable: false,
  frontPageVersion: 'default',
  assetConsumeCredit: 200,
  vaultConsumeCredit: 1000,
  signCertificateVisible: false,
  needRealPersonAndCompanyAuth: true,
  proofKeyNewVersion: false,
  asyncGenerateProof: true,
  uploadFileDescriptionAvailable: false,
  apiContainBigint: true,
  defaultPage: 'home',
  tableSize: 'middle',
  dashboard: {
    sidebar: {
      chartVisible: false,
      disclosureAvailable: false,
      applicationVisible: true,
      copyrightVisible: true,
      creditVisible: true,
    },
  },
  bgImageInReviewVisible: false,
  baseAPI: 'https://staging.api.ip.hndip.cn',
  verifyBaseAPI: 'https://api.auth.hndip.cn',
  enterpriseApplyFileURL:
    'https://ip-proof.oss-cn-shanghai.aliyuncs.com/apply-books/%E4%BC%81%E4%B8%9A%E6%9C%BA%E6%9E%84%E8%B4%A6%E5%8F%B7%E7%94%B3%E8%AF%B7%E8%A1%A8.pdf',
  chainBrowserURL: 'https://block-explorer.hndip.cn',
  chainBrowserHome: '/',
  webTitle: '中国（湖南）自由贸易试验区数字知识产权保护平台',
  statisticsBaseAPI: 'https://api.stat.hndip.cn',
  supportEmail: 'support@mail.hndip.cn',
  allowPhoneLogin: true,
  allowUserNameLogin: false,
  allowRegister: true,
  allowResetPassword: true,
  requirePhoneValidation: true,
  requireIntegral: true,
};
