import v2 from './v2';

export default {
  getDisclosureList(params) {
    return v2.get('/disclosures/records', { params });
  },
  acceptProtcol(fileId) {
    return v2.put('/disclosures/receive/' + fileId);
  },
  validateReceiver(validateCode, fileId) {
    let params = {
      validation_code: validateCode,
      disclosure_id: fileId,
    };
    return v2.put('/disclosures/check-identify', params);
  },
  getDisclosureDetail(fileId) {
    return v2.get('/disclosures/records/' + fileId);
  },
  getDownloadURL(fileId) {
    return v2.get(`/disclosures/records/${fileId}/file-link`);
  },
  submitFile(hash, fileName, phoneNumber, storeName, type, validationCode, storeTime) {
    let params = {
      hash: hash,
      name: fileName,
      received_phone: phoneNumber,
      store_name: storeName,
      type: type,
      validation_code: validationCode,
      store_forever: storeTime,
    };
    return v2.post('/disclosures', params);
  },
};
