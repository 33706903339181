import { ref } from 'vue';

export function frontConfig() {
  const advantages = ref([
    {
      title: '使用成本低',
      description: '无需企业自行搭建系统,注册账号即可直接开',
      image: require('./images/chengbendi.png'),
    },
    {
      title: '灵活访问',
      description: '可在企业内网访问,企业员工也可以在家访问',
      image: require('./images/linghuofangwen.png'),
    },
    {
      title: '安全保障',
      description:
        'Saas服务后台采用严格的数据安全架构,数据、密钥离线.可托管企业数据及企业密钥,防止企业数据意外丢失',
      image: require('./images/anquanbaozhang.png'),
    },
  ]);

  const solutions = ref([
    {
      title: '政府机构',
      description: '技术创新赋能商业秘密保护',
      image: require('./images/pic_3.png'),
    },
    {
      title: '开发园区',
      description: '打造高新技术赋能知识产权保护先进园区',
      image: require('./images/pic_2.png'),
    },
    {
      title: '核心企业',
      description: '商业秘密保护一站式解决方案',
      image: require('./images/pic_1.png'),
    },
    {
      title: '中小企业',
      description: '低成本快速建立商业秘密保护体系',
      image: require('./images/pic_4.png'),
    },
  ]);

  const needProtecteds = ref([
    {
      title: '创意作品设计',
      description: '视听作品、纺织品设计、软件代码、工业设计草图等',
      image: require('./images/chuangyizuopinsheji.png'),
    },
    {
      title: '商业秘密和专门知识',
      description: '公式、工艺、流程、汇编/列表、业务计划、配方等。',
      image: require('./images/shangyemimi.png'),
    },
    {
      title: '研究与数据',
      description: '数据集、研究结果、实验室记录等。',
      image: require('./images/yanjiuyushuju.png'),
    },
    {
      title: '其他数字财产',
      description: '协议、合同、技术或数据参考文件的版本历史。',
      image: require('./images/qita.png'),
    },
  ]);

  return {
    advantages,
    solutions,
    needProtecteds,
  };
}
